import * as actionTypes from './actionTypes';
import axios from "axios";
import {selectProfile} from "./profile";

const apiURL = process.env.REACT_APP_API_URL;

const fetchFavoriteStart = () => {
    return {
        type: actionTypes.FETCH_FAVORITE_START
    };
};

const setFavorites = favorites => {
    return {
        type: actionTypes.FETCH_FAVORITE_SUCCESS,
        favorites: favorites
    }
};

export const fetchFavorite = (token, userId, pinId) => {
    return dispatch => {
        dispatch(fetchFavoriteStart());
        const config = {
            params: {userId},
            headers: {Authorization: `Bearer ${token}`}
        }
        axios.get(`${apiURL}/v1/favorites`, config)
            .then(response => {
                if (pinId) {
                    let isExist = false;
                    response.data.favorites.map(favorite => {
                        if (favorite.pin_id === pinId) {
                            isExist = true;
                        }
                        return favorite;
                    });
                    if (!isExist) {
                        dispatch(addFavorite(token, pinId, userId));
                    }
                    dispatch(setFavorites(response.data.favorites));
                } else {
                    dispatch(setFavorites(response.data.favorites));
                }
            });
    }
};

const addFavoriteStart = () => {
    return {
        type: actionTypes.ADD_TO_FAVORITE_START
    };
};

const addFavoritesSuccess = favorite => {
    return {
        type: actionTypes.ADD_TO_FAVORITE_SUCCESS,
        favorite: favorite
    }
};

export const addFavorite = (token, pinId, userId) => {
    return dispatch => {
        dispatch(addFavoriteStart());
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        const data = {pinId, userId}
        axios.post(`${apiURL}/v1/favorite`, data, config)
            .then(response => {
                dispatch(addFavoritesSuccess(response.data.favorite));
            }).catch(error => {});
    }
};

const deleteFavoriteStart = () => {
    return {
        type: actionTypes.DELTE_FAVORITE_START
    };
};

const deleteFavoritesSuccess = pinId => {
    return {
        type: actionTypes.DELETE_FAVORITE_SUCCESS,
        pinId: pinId
    }
};

// ne koristimo userId
export const deleteFavorite = (userId, token, pinId) => {
    return (dispatch, getState)  => {
        const state = getState();
        const favorites = state.filter.favorites;
        let id = null;
        favorites.map(favorite => {
            if(favorite.pin_id === pinId) {
                id = favorite.id;
            }
            return favorite;
        });
        dispatch(deleteFavoriteStart());
        const config = {
            params: {id},
            headers: {Authorization: `Bearer ${token}`}
        };
        axios.delete(`${apiURL}/v1/favorite`, config)
            .then(response => {
                dispatch(deleteFavoritesSuccess(pinId));
            }).catch(error => {});
    }
};

export const confirmFavorite = pinId => {
    return {
        type: actionTypes.OPEN_CONFIRM_FAVORITE_POP_UP,
        favoriteForDelete: pinId
    }
};