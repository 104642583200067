import React, {useContext, useState, useEffect} from "react";
import {connect} from "react-redux";
import {__RouterContext} from "react-router";
import * as actions from "../../../store/actions";
import translate from "../../../i18n/translate";
import messages from "../../../i18n/messages";
import {AppContext} from "../../../context";

const PopUpClick = props => {
    const {
        popUpPin,
        pinCoordinate,
        token,
        addFavorite,
        user,
        deleteFavorite,
        openLogInPopUp,
        favorites,
        addPinToRouter,
        deleteMapPinFromRouter,
        isRouterOpen,
        addRouteToHeader,
        route
    } = props;
    const [isPinAdded, setIsPinAdded] = useState(false);
    const [isPinFavorites, setIsPinFavorites] = useState(false);
    const {history} = useContext(__RouterContext);
    const {state} = useContext(AppContext);
    const img = popUpPin.properties.img_url;
    const name = popUpPin.properties.title;
    const pin_id = popUpPin.properties.pin_id;
    const category_id = popUpPin.properties.category_id;
    const category_name = popUpPin.properties.category_name;
    const lng = popUpPin.geometry.coordinates[0];
    const lat = popUpPin.geometry.coordinates[1];
    const onPinClickedHandler = () => {
        if (popUpPin.properties.is_popup === 1) {
            history.push(`/pin/${pin_id}`);
        }
    };
    useEffect(() => {
        setIsPinAdded(false);
        route.map(pin => {
            if(pin.pinId === pin_id) {
                setIsPinAdded(true);
            }
            return pin;
        });
    },[route, pin_id]);
    useEffect(() => {
        setIsPinFavorites(false);
        favorites.map(favorite => {
            if(favorite.pin_id === pin_id) {
                setIsPinFavorites(true);
            }
            return favorite;
        });
    },[favorites, pin_id]);
    const onAddToRouteHandler = () => {
        addPinToRouter(name, pin_id, lng, lat, img, category_id, category_name);
        if (!isRouterOpen) {
            addRouteToHeader(messages[state.siteLang]['route']);
        }
    };
    const onMapPinDeletedHandler = (pinId) => {
        deleteMapPinFromRouter(pinId);
    };
    const addRemovePinHandler = () => {
        if(!isPinAdded) {
            onAddToRouteHandler();
            setIsPinAdded(!isPinAdded);
        } else {
            onMapPinDeletedHandler(pin_id);
            setIsPinAdded(!isPinAdded);
        }
    };
    const addRemoveFavoriteHandler = () => {
        if(token !== null) {
            if(!isPinFavorites) {
                addFavorite(token, pin_id, user.userId);
                setIsPinFavorites(!isPinFavorites);
            } else {
                deleteFavorite(user.userId, token, pin_id);
                setIsPinFavorites(!isPinFavorites);
            }
        } else {
            openLogInPopUp({
                tmpPinFavoritesId: pin_id,
                isRouteForAdd: false
            });
        }
    };

    return <div
        className="marker-info-wrapper"
        style={{
            left: `${pinCoordinate.x - 110}px`,
            top: `${pinCoordinate.y + 16}px`,
        }}
    >
        <div
            className="marker-info"
            onClick={onPinClickedHandler}
        >
            {img !== 'null' ? <img src={`https://cdn.meetserbia.rs/images/pins/thumb/${img}`} alt={name}/> : <img src={require('../../../assets/img/002.png')} alt='meetserbia'/>}
            <div>
                <h5>{name}</h5>
                <span className={`cat-${category_id}`}>{category_name}</span>
            </div>
        </div>
        <div className='pop-up-options'>
            <div
                className={`favorite-wrapper ${isPinFavorites ? "added" : ""}`}
                onClick={addRemoveFavoriteHandler}
            >
                <div className='sprite-bg favorite-icon-small'/>
            </div>
            <div
                className={`add-route-wraper cat-${category_id} ${isPinAdded ? "added" : ""}`}
                onClick={addRemovePinHandler}
            >
                <div className='sprite-bg route-icon'/>
                <span className='add-route-text'>{translate('add_to_route')}</span>
            </div>
        </div>
    </div>;
};

const mapStateToProps = state => {
    return {
        popUpPin: state.map.popUpPin,
        token: state.auth.token,
        user: state.auth.user,
        favorites: state.filter.favorites,
        isRouterOpen: state.filter.isRouterOpen,
        route: state.router.route
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addFavorite: (token, pinId, userId) => dispatch(actions.addFavorite(token, pinId, userId)),
        deleteFavorite: (userId, token, pinId) => dispatch(actions.deleteFavorite(userId, token, pinId)),
        openLogInPopUp: forAddAfterLogin => dispatch(actions.openLogInPopUp(forAddAfterLogin)),
        addPinToRouter: (
            pinName,
            pinId,
            lng,
            lat,
            img_url,
            category_id,
            category_name
        ) => dispatch(actions.addPinToRouter(
            pinName,
            pinId,
            lng,
            lat,
            img_url,
            category_id,
            category_name
        )),
        deleteMapPinFromRouter: pinId => dispatch(actions.deleteMapPinFromRouter(pinId)),
        addRouteToHeader: routeName => dispatch(actions.addRouteToHeader(routeName))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpClick);