import React from 'react';
import TagList from "./TagList/TagList";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import translate from "../../../../i18n/translate";
import * as actionTypes from "../../../../store/actions/actionTypes";

const TagsFilter = props => {
    const {
        tags,
        onAllTagClicked,
        tagsList,
        id,
        isAllTags,
        activeCategories,
        initialPins,
        favorites,
        categoriesListTop,
        categoriesListBottom,
        loaderStart
    } = props;
    const divStyle = {
        top: 52,
        left: 0
    };
    const stopPropagationHandler = (e) => {
        e.stopPropagation();
    }
    const setAllTagsHandler = (categoryId) => {
        if(!isAllTags) {
            loaderStart();
            onAllTagClicked(categoryId, tags, activeCategories, initialPins, favorites, categoriesListTop, categoriesListBottom);
        }
    }
    const tagsElements = tagsList.map(tag => {
        return <TagList
            key={tag.tag_id}
            tag={tag}
        />
    });

    return <div
        className="category-dd-wrapper"
        style={divStyle}
        onClick={stopPropagationHandler}
    >
        <div className="triangle-wrapper">
            <div className="triangle-with-shadow"/>
        </div>
        <ul>
            <li
                className={`cat-dd-${id}-wrapper ${isAllTags && "active"}`}
                onClick={() => setAllTagsHandler(id)}
            >
                {translate('all_tags')}
            </li>
            {tagsElements}
        </ul>
    </div>;
};

const mapsStateToProps = state => {
    return {
        activeCategories: state.filter.activeCategories,
        tags: state.filter.tags,
        initialPins: state.filter.initialPins,
        favorites: state.filter.favorites,
        categoriesListTop: state.filter.categoriesListTop,
        categoriesListBottom: state.filter.categoriesListBottom
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAllTagClicked: (
            categoryId,
            tags,
            activeCategories,
            initialPins,
            favorites,
            categoriesListTop,
            categoriesListBottom
        ) => dispatch(actions.setAllTags(
            categoryId,
            tags,
            activeCategories,
            initialPins,
            favorites,
            categoriesListTop,
            categoriesListBottom
        )),
        loaderStart: () => dispatch({type: actionTypes.START_POPUP_PIN})
    }
};

export default connect(mapsStateToProps, mapDispatchToProps)(TagsFilter);