import React, {useState, useContext} from "react";
import {LOCALES} from "../../../../i18n";
import translate from "../../../../i18n/translate";
import {AppContext} from "../../../../context";
import {saveToStorage} from "../../../../utils/sessionStorage";

export default () => {
    const {dispatch} = useContext(AppContext);
    const [isShown, setIsShown] = useState(false);

    const setLanguage = (siteLang, id) => {
        dispatch({type: 'setLang', siteLang});
        saveToStorage('siteLang', siteLang);
        saveToStorage('siteLangId', id);
        window.location = '/';
    };

    const ddLanguages = <div className="header-dd-wrapper">
        <div className="triangle-wrapper">
            <div className="triangle-with-shadow"/>
        </div>
        <ul>
            <li onClick={() => setLanguage(LOCALES.CYRILIC, 2)} >
                <div className='sprite-bg rs-icon'/>
                <span>{translate('CYRILIC')}</span>
            </li>
            <li onClick={() => setLanguage(LOCALES.SERBIAN, 1)} >
                <div className='sprite-bg rs-icon'/>
                <span>{translate('SERBIAN')}</span>
            </li>
            <li onClick={() => setLanguage(LOCALES.ENGLISH, 3)} >
                <div className='sprite-bg en-icon'/>
                <span>{translate('ENGLISH')}</span>
            </li>
        </ul>
    </div>

    return <div
            className="lang-wrapper"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
        <div className="sprite-bg rs-icon"/>
        <span className="lang-text">
            {translate('lang_short')}
        </span>
        {isShown && ddLanguages}
    </div>
}