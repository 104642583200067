import * as actionTypes from "./actionTypes";

export const openTutorial = () => {
    return {
        type: actionTypes.OPEN_TUTORIAL
    };
};

export const setCloudTutorial = cloudTutorial => {
    return {
        type: actionTypes.SET_CLOUD_TUTORIAL,
        cloudTutorial: cloudTutorial
    };
};