import * as actionTypes from "./actionTypes";
import axios from "axios";
import {getFromStorage} from "../../utils/sessionStorage";

const apiURL = process.env.REACT_APP_API_URL;
const locale = getFromStorage('siteLangId');

const getRecommendationsStart = () => {
    return {
        type: actionTypes.START_RECOMMENDATIONS
    }
}

const setRecommendations = (recommendations) => {
    return {
        type: actionTypes.SUCCES_RECOMMENDATIONS,
        recommendations: recommendations
    }
}

export const fetchRecommendations = () => {
    return dispatch => {
        dispatch(getRecommendationsStart());
        axios.get(`${apiURL}/v1/recommendations/${locale}`)
            .then(response => {
                dispatch(setRecommendations(response.data));
            });
    }
}