import React from "react";
import Helmet from "react-helmet";

const HelmetMetaData = props => {
    const {title, meta, link} = props;
    return <Helmet>
        <title>{title}</title>
        <meta name="description" content={meta} />
        <link rel="canonical" href={link} />
    </Helmet>
}

export default HelmetMetaData;