import React from "react";
import {connect} from "react-redux";
import * as actionTypes from "../../../../store/actions/actionTypes";

const BurgerButton = props => (
    <div
        className="burger-logo"
        onClick={props.onBurgerButtonClicked}
    >
        <div></div>
        <div></div>
        <div></div>
    </div>
);

const mapDipatchToProps = dispatch => {
    return {
        onBurgerButtonClicked: () => dispatch({type: actionTypes.OPEN_BURGER_MENU})
    }
}

export default connect(null, mapDipatchToProps)(BurgerButton);