import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import PopUpHover from "./Map/PopUpHover";
import PopUpClick from "./Map/PopUpClick";
import * as turf from '@turf/turf';
import axios from "axios";
import {getFromStorage} from "../../utils/sessionStorage";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapboxGLMap = props => {
    const mapContainerRef = useRef(null);
    const {
        initialPins,
        onGetPinsFromMap,
        onZoomEnd,
        route,
        geoJsonForMap,
        routeRadius,
        onLoadMap,
        pulsingDot2,
        isZoomPriority,
        searchedLocation,
        setPopUpPin,
        popUpPin,
        setPopUpHoverPin,
        activeNavigationMode,
        routeInfo,
        setChosenRouteIndex,
        setAllPossibleRoutes,
        chosenRouteIndex,
        avoidTolls
    } = props;
    const [theMap, setTheMap] = useState(null);
    const [pinCoordinate, setPinCoordinate] = useState({
        x: 0,
        y: 0
    });
    const [pinHoverCoordinate, setPinHoverCoordinate] = useState({
        x: 0,
        y: 0
    });
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isPopUpHoverVisible, setIsPopUpHoverVisible] = useState(false);
    let mapStyle = 'mapbox://styles/deicidemilan/ckgi2ausg0xdp1aocx13t5mx3';
    const langudeId = getFromStorage('siteLangId');
    if (langudeId === 2) {
        mapStyle = 'mapbox://styles/deicidemilan/ckgqc0tw500eo19pocjinnmoc'
    }

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapStyle,
            center: [20.1, 44.15],
            zoom: 6.7
        });
        map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
        const images = [
            {imageUrl: require('../../assets/img/pinovi_png/1.png'), id: 'image_1'},
            {imageUrl: require('../../assets/img/pinovi_png/2.png'), id: 'image_2'},
            {imageUrl: require('../../assets/img/pinovi_png/3.png'), id: 'image_3'},
            {imageUrl: require('../../assets/img/pinovi_png/4.png'), id: 'image_4'},
            {imageUrl: require('../../assets/img/pinovi_png/5.png'), id: 'image_5'},
            {imageUrl: require('../../assets/img/pinovi_png/6.png'), id: 'image_6'},
            {imageUrl: require('../../assets/img/pinovi_png/7.png'), id: 'image_7'},
            {imageUrl: require('../../assets/img/pinovi_png/8.png'), id: 'image_8'},
            {imageUrl: require('../../assets/img/pinovi_png/9.png'), id: 'image_9'},
            {imageUrl: require('../../assets/img/pinovi_png/10.png'), id: 'image_10'},
            {imageUrl: require('../../assets/img/pinovi_png/11.png'), id: 'image_11'},
            {imageUrl: require('../../assets/img/pinovi_png/12.png'), id: 'image_12'},
            {imageUrl: require('../../assets/img/pinovi_png/13.png'), id: 'image_13'},
            {imageUrl: require('../../assets/img/pinovi_png/14.png'), id: 'image_14'},
            {imageUrl: require('../../assets/img/pinovi_png/19.png'), id: 'image_19'},
            {imageUrl: require('../../assets/img/pinovi_png/20.png'), id: 'image_20'}
        ];
        images.forEach(img => {
            map.loadImage(img.imageUrl, function (error, res) {
                map.addImage(img.id, res);
            })
        })
        map.on('load', function () {
            setTheMap(map);
            // onLoadMap();
            map.on('click', () => {
                setIsPopUpVisible(false);
            });
            for (let i = 0; i < 3; i++) {
                map.on('mouseenter', `mapPins${i}`, function (e) {
                    map.getCanvas().style.cursor = 'pointer';
                    let pinFeatures = e.features[0];
                    const coordinate = [pinFeatures.geometry.coordinates[0], pinFeatures.geometry.coordinates[1]];
                    const coordinatePoint = map.project(coordinate);
                    setPinHoverCoordinate(coordinatePoint);
                    setPopUpHoverPin(pinFeatures);
                    setIsPopUpHoverVisible(true);
                });
                map.on('mouseleave', `mapPins${i}`, function () {
                    map.getCanvas().style.cursor = '';
                    setIsPopUpHoverVisible(false);
                });
                map.on('click', `mapPins${i}`, function (e) {
                    let pinFeatures = e.features[0];
                    const coordinate = [pinFeatures.geometry.coordinates[0], pinFeatures.geometry.coordinates[1]];
                    const coordinatePoint = map.project(coordinate);
                    setPinCoordinate(coordinatePoint);
                    setPopUpPin(pinFeatures);
                    setIsPopUpVisible(true);
                });
            }
            map.on('zoomend', () => {
                const currentZoom = map.getZoom();
                onZoomEnd(currentZoom);
            });
            map.on('idle', () => {
                const mapLayers = map.getStyle().layers;
                for (let i = 0; i < 3; i++) {
                    const mapPinsLayer = map.getLayer(`mapPins${i}`);
                    if (typeof mapPinsLayer !== 'undefined') {
                        const isLargeNumber = (element) => element.id === `mapPins${i}`;
                        const layerIndex =  mapLayers.findIndex(isLargeNumber);
                        if (map.getStyle().layers[layerIndex].layout.visibility === 'visible') {
                            const pinsOnMap = map.queryRenderedFeatures({layers: [`mapPins${i}`]});
                            onGetPinsFromMap(pinsOnMap);
                        }
                    }
                }
            });
        });
        return () => {
            map.remove();
        }
    },[onZoomEnd, onLoadMap, onGetPinsFromMap]);

    useEffect(() => {
        if(theMap) {
            theMap.on('move', () => {
                const coordinate = [popUpPin.geometry.coordinates[0], popUpPin.geometry.coordinates[1]];
                const coordinatePoint = theMap.project(coordinate);
                setPinCoordinate(coordinatePoint);
            });
        }
    }, [popUpPin]);

    useEffect(() => {
        if(theMap) {
            // // obrisati posle slanja maticu
            // axios.get(`https://api.meetserbia.rs/v1/pins/getcustom`)
            //     .then(response => {
            //         const geoJsonForMap2 = [];
            //         response.data.map(pin => {
            //             const lng = Number(pin.lng);
            //             const lat = Number(pin.lat);
            //             const name = pin.name;
            //             const image = pin.primary_icon_id;
            //             const img_url = pin.img_url;
            //             const pin_id = pin.pin_id;
            //             const description = pin.description;
            //             geoJsonForMap2.push({
            //                     'type': 'Feature',
            //                     'geometry': {
            //                         'type': 'Point',
            //                         'coordinates': [lng, lat]
            //                     },
            //                     'properties': {
            //                         'title': name,
            //                         'icon': 'image_' + image,
            //                         'img_url': img_url,
            //                         'pin_id': pin_id,
            //                         'category_id': image,
            //                         'description': description
            //                     }
            //                 }
            //             );
            //             return pin;
            //         });
            //         console.log('geoJsonForMap2', geoJsonForMap2);
            //
            //
            //
            //         // 111111111111111111111111111
            //         // console.log('simplifiedRoutesLine', simplifiedRoutesLine);
            //         const point = turf.point([21.90045, 43.32049]);
            //         const buffered = turf.buffer(point, 40, {units: 'kilometers'});
            //         console.log('buffered', buffered);
            //         // console.log('buffered', buffered);
            //         // 111111111111111111111111111
            //         //333333333333
            //         console.log('geoJsonForMap', geoJsonForMap);
            //         const points = {
            //             'features': geoJsonForMap,
            //             'type': 'FeatureCollection'
            //         };
            //         const niz = turf.pointsWithinPolygon(points, buffered);
            //         console.log('niz', niz);
            //         theMap.addSource(`www`, {
            //             'type': 'geojson',
            //             'data': {
            //                 'type': 'Feature',
            //                 'geometry': {
            //                     'type': 'Polygon',
            //                     'coordinates': [buffered.geometry.coordinates[0]]
            //                 }
            //             }
            //         });
            //         theMap.addLayer({
            //             'id': `www`,
            //             'type': 'fill',
            //             'source': `www`,
            //             'layout': {
            //                 'visibility': 'visible'
            //             },
            //             'paint': {
            //                 'fill-color': '#009B7D',
            //                 'fill-opacity': 0.1
            //             }
            //         });
            //         theMap.addSource(`qqq`, {
            //             'type': 'geojson',
            //             'data': {
            //                 'type': 'FeatureCollection',
            //                 'features': niz.features
            //             }
            //         });
            //         theMap.addLayer({
            //             'id': 'qqq',
            //             'type': 'symbol',
            //             'source': `qqq`,
            //             'layout': {
            //                 'icon-image': ['get', 'icon'],
            //                 'icon-allow-overlap': true,
            //                 "symbol-sort-key": ["to-number", ["get", "priority"]],
            //                 'visibility': 'visible'
            //             },
            //             'filter': ['all', ['<=', 'zoom', 30]]
            //         });
            //         //33333333333333333
            //     });
            // // obrisati posle slanja maticu

            let numberOfPossibleRoutes2 = 0;
            const createMapSourceHandler = numberOfPossibleRoutes => {
                for (let i = 0; i < numberOfPossibleRoutes; i++) {
                    theMap.addSource(`mapPins${i}`, {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': geoJsonForMap
                        }
                    });
                }
            };
            const createMapPinsLayerHandler = (numberOfPossibleRoutes, currentZoom) => {
                for (let i = 0; i < numberOfPossibleRoutes; i++) {
                    if (isZoomPriority) {
                        theMap.addLayer({
                            'id': `mapPins${i}`,
                            'type': 'symbol',
                            'source': `mapPins${i}`,
                            'layout': {
                                'icon-image': ['get', 'icon'],
                                'icon-allow-overlap': true,
                                "symbol-sort-key": ["to-number", ["get", "priority"]],
                                'visibility': 'none'
                            },
                            'filter': ['all', ['<=', 'zoom', currentZoom]]
                        });
                    }
                    else if (!isZoomPriority) {
                        theMap.addLayer({
                            'id': `mapPins${i}`,
                            'type': 'symbol',
                            'source': `mapPins${i}`,
                            'layout': {
                                'icon-image': ['get', 'icon'],
                                'icon-allow-overlap': true,
                                "symbol-sort-key": ["to-number", ["get", "priority"]],
                                'visibility': 'none'
                            }
                        });
                    }
                }
            };
            const zoomEnd = (numberOfPossibleRoutes) => {
                theMap.on('zoomend', () => {
                    const currentZoom = theMap.getZoom();
                    for (let i = 0; i < numberOfPossibleRoutes; i++) {
                        if (isZoomPriority) {
                            theMap.setFilter(`mapPins${i}`, ['all', ['<=', 'zoom', currentZoom]]);
                        } else {
                            theMap.setFilter(`mapPins${i}`, ['all', ['<=', 'zoom', 20]]);
                        }
                    }
                });
            };
            const routeClickHandler = e => {
                const routeIndex = e.features[0].properties.routeIndex;
                setChosenRouteIndex(e.features[0].properties.routeIndex);
                const polygonRadiusLayer = theMap.getLayer(`polygonRadius0`);
                if (typeof polygonRadiusLayer !== 'undefined') {
                    theMap.moveLayer(`route${routeIndex}`, 'polygonRadius0');
                } else {
                    theMap.moveLayer(`route${routeIndex}`, 'mapPins0');
                }
                for (let i = 0; i < numberOfPossibleRoutes2; i++) {
                    theMap.setPaintProperty(`route${i}`, 'line-color', '#999999');
                    if (typeof polygonRadiusLayer !== 'undefined') {
                        theMap.setLayoutProperty(`polygonRadius${i}`, 'visibility', 'none');
                        theMap.setLayoutProperty(`mapPins${i}`, 'visibility', 'none');
                    } else {

                    }
                }
                theMap.setPaintProperty(`route${routeIndex}`, 'line-color', '#4882c5');
                if (typeof polygonRadiusLayer !== 'undefined') {
                    theMap.setLayoutProperty(`polygonRadius${routeIndex}`, 'visibility', 'visible');
                    theMap.setLayoutProperty(`mapPins${routeIndex}`, 'visibility', 'visible');
                } else {
                    theMap.setLayoutProperty(`mapPins0`, 'visibility', 'visible');
                }
            };
            const routeLength = route.length;
            let currentMarkers = [];
            if(routeLength <= 1) {
                const currentZoom = theMap.getZoom();
                createMapSourceHandler(1);
                createMapPinsLayerHandler(1, currentZoom);
                zoomEnd(1);
                theMap.setLayoutProperty(`mapPins0`, 'visibility', 'visible');

            }
            if(routeLength > 1) {
                let el = null;
                let routeQuery = null;
                route.map((pin, index) => {
                    if (index === 0) {
                        routeQuery = `${pin.lng},${pin.lat};`
                        el = document.createElement('div');
                        el.className = 'marker';
                        el.innerHTML = '<div class="sprite-bg start-icon-big"></div>';
                        const oneMarker = new mapboxgl.Marker(el)
                            .setLngLat([pin.lng, pin.lat])
                            .addTo(theMap);
                        currentMarkers.push(oneMarker);
                    } else if (index + 1 < routeLength) {
                        routeQuery = `${routeQuery}${pin.lng},${pin.lat};`
                        el = document.createElement('div');
                        el.className = 'marker';
                        el.innerHTML = `<span class="router-position-info">${index}</span>`;
                        const oneMarker = new mapboxgl.Marker(el)
                            .setLngLat([pin.lng, pin.lat])
                            .addTo(theMap);
                        currentMarkers.push(oneMarker);
                    } else {
                        routeQuery = `${routeQuery}${pin.lng},${pin.lat}`
                        el = document.createElement('div');
                        el.className = 'marker';
                        el.innerHTML = '<div class="sprite-bg finish-icon-big"></div>';
                        const oneMarker = new mapboxgl.Marker(el)
                            .setLngLat([pin.lng, pin.lat])
                            .addTo(theMap);
                        currentMarkers.push(oneMarker);
                    }
                    return pin;
                });
                let exclude = '';
                if (avoidTolls && (activeNavigationMode === 'driving')) {
                    exclude = '&exclude=toll';
                }
                axios.get(`https://api.mapbox.com/directions/v5/mapbox/${activeNavigationMode}/${routeQuery}?geometries=geojson&annotations=maxspeed${exclude}&alternatives=true&overview=full&access_token=pk.eyJ1IjoiZGVpY2lkZW1pbGFuIiwiYSI6ImNrN3Bhc3Q3MzBnbDgzZnMza2ttYWN4ZXIifQ.8MvxSaEZJLxwjD4Bx4hTUQ`)
                    .then(response => {
                        const allPossibleRoutes = [];
                        response.data.routes.map((route, index) => {
                            const routeForAdd = {
                                routeIndex: index,
                                distance: route.distance,
                                duration: route.duration
                            }
                            allPossibleRoutes.push(routeForAdd);
                            return route;
                        });
                        setAllPossibleRoutes(allPossibleRoutes);
                        let numberOfPossibleRoutes = response.data.routes.length;
                        for (let i = numberOfPossibleRoutes-1; i >= 0; i--) {
                            const routesCoordinates = response.data.routes[i].geometry.coordinates;
                            theMap.addSource(`route${i}`, {
                                'type': 'geojson',
                                'data': {
                                    'type': 'Feature',
                                    'properties': {
                                        'routeIndex': i
                                    },
                                    'geometry': {
                                        'type': 'LineString',
                                        'coordinates': routesCoordinates
                                    }
                                }
                            });
                            let lineColor = '#999999';
                            if (i === 0) {
                                lineColor = '#4882c5';
                            }
                            theMap.addLayer({
                                id: `route${i}`,
                                type: 'line',
                                source: `route${i}`,
                                layout: {
                                    'line-join': 'round',
                                    'line-cap': 'round',
                                    'visibility': 'none'
                                },
                                paint: {
                                    'line-color': lineColor,
                                    'line-width': 6
                                }
                            });
                        }
                        const newCoordinate = response.data.routes[0].geometry.coordinates;
                        let bounds = newCoordinate.reduce(function (bounds, coord) {
                            return bounds.extend(coord);
                        }, new mapboxgl.LngLatBounds(newCoordinate[0], newCoordinate[0]));
                        theMap.fitBounds(bounds, {
                            padding: 100
                        });
                        let unit = 'kilometers';
                        if(routeRadius === 35) {
                            const currentZoom = theMap.getZoom();
                            theMap.addSource('mapPins0', {
                                'type': 'geojson',
                                'data': {
                                    'type': 'FeatureCollection',
                                    'features': geoJsonForMap
                                }
                            });
                            createMapPinsLayerHandler(1, currentZoom);
                            theMap.setLayoutProperty(`mapPins0`, 'visibility', 'visible');
                        } else {
                            let routeLines = [];
                            let simplifiedRoutesLine = [];
                            let bufferedPolygons = [];
                            let searchMarkersWithin = [];
                            let pointsWithin = [];
                            for (let i = 0; i < numberOfPossibleRoutes; i++) {
                                routeLines.push({
                                    type: 'LineString',
                                    coordinates: response.data.routes[i].geometry.coordinates
                                });
                                theMap.on('mouseenter', `route${i}`, () => {
                                    theMap.getCanvas().style.cursor = 'pointer';
                                    theMap.setPaintProperty(`route${i}`, 'line-width', 10);
                                });
                                theMap.on('mouseleave', `route${i}`, () => {
                                    theMap.getCanvas().style.cursor = '';
                                    theMap.setPaintProperty(`route${i}`, 'line-width', 6);
                                });
                            }
                            for (let i = 0; i < numberOfPossibleRoutes; i++) {
                                simplifiedRoutesLine.push(turf.simplify(routeLines[i], {tolerance: 0.01, highQuality: false}));
                            }
                            for (let i = 0; i < numberOfPossibleRoutes; i++) {
                                bufferedPolygons.push(turf.buffer(simplifiedRoutesLine[i], routeRadius, {unit}));
                            }
                            for (let i = 0; i < numberOfPossibleRoutes; i++) {
                                searchMarkersWithin.push(turf.polygon([bufferedPolygons[i].geometry.coordinates[0]]));
                            }
                            const points = {
                                'features': geoJsonForMap,
                                'type': 'FeatureCollection'
                            };
                            for (let i = 0; i < numberOfPossibleRoutes; i++) {
                                pointsWithin.push(turf.pointsWithinPolygon(points, searchMarkersWithin[i]));
                            }
                            for (let i = 0; i < numberOfPossibleRoutes; i++) {
                                theMap.addSource(`polygonRadius${i}`, {
                                    'type': 'geojson',
                                    'data': {
                                        'type': 'Feature',
                                        'geometry': {
                                            'type': 'Polygon',
                                            'coordinates': [bufferedPolygons[i].geometry.coordinates[0]]
                                        }
                                    }
                                });
                                theMap.addLayer({
                                    'id': `polygonRadius${i}`,
                                    'type': 'fill',
                                    'source': `polygonRadius${i}`,
                                    'layout': {
                                        'visibility': 'none'
                                    },
                                    'paint': {
                                        'fill-color': '#009B7D',
                                        'fill-opacity': 0.1
                                    }
                                });
                                theMap.addSource(`mapPins${i}`, {
                                    'type': 'geojson',
                                    'data': {
                                        'type': 'FeatureCollection',
                                        'features': pointsWithin[i].features
                                    }
                                });
                            }
                            const currentZoom = theMap.getZoom();
                            createMapPinsLayerHandler(numberOfPossibleRoutes, currentZoom);
                        }
                        if (routeInfo === null) {
                            for (let i = 0; i < numberOfPossibleRoutes; i++) {
                                theMap.setLayoutProperty(`route${i}`, 'visibility', 'visible');
                                theMap.on('click', `route${i}`, routeClickHandler);
                            }
                            const polygonRadiusLayer = theMap.getLayer(`polygonRadius0`);
                            if (typeof polygonRadiusLayer !== 'undefined') {
                                theMap.setLayoutProperty(`polygonRadius0`, 'visibility', 'visible');
                            }
                            theMap.setLayoutProperty(`mapPins0`, 'visibility', 'visible');
                        } else {
                            const chosenRouteIndex = routeInfo[0].chosen_route_index;
                            theMap.setPaintProperty(`route${chosenRouteIndex}`, 'line-color', '#4882c5');
                            theMap.setLayoutProperty(`route${chosenRouteIndex}`, 'visibility', 'visible');
                            theMap.setLayoutProperty(`polygonRadius${chosenRouteIndex}`, 'visibility', 'visible');
                            theMap.setLayoutProperty(`mapPins${chosenRouteIndex}`, 'visibility', 'visible');
                        }
                        zoomEnd(1);
                        // console.log('theMap3', theMap.getStyle());
                    });
            }

            return () => {
                if (currentMarkers !== null) {
                    for (let i = currentMarkers.length - 1; i >= 0; i--) {
                        currentMarkers[i].remove();
                    }
                }
                for (let i = 0; i < 3; i++) {
                    const polygonRadiusLayer = theMap.getLayer(`polygonRadius${i}`);
                    const polygonRadiusSource = theMap.getSource(`polygonRadius${i}`);
                    if (typeof polygonRadiusLayer !== 'undefined' && typeof polygonRadiusSource !== 'undefined') {
                        theMap.removeLayer(`polygonRadius${i}`);
                        theMap.removeSource(`polygonRadius${i}`);
                    }
                }
                for (let i = 0; i < 3; i++) {
                    const routeLayer = theMap.getLayer(`route${i}`);
                    const routeSource = theMap.getSource(`route${i}`);
                    if (typeof routeLayer !== 'undefined' && typeof routeSource !== 'undefined') {
                        theMap.removeLayer(`route${i}`);
                        theMap.removeSource(`route${i}`);
                    }
                }
                for (let i = 0; i < 3; i++) {
                    const mapPinsLayer = theMap.getLayer(`mapPins${i}`);
                    const mapPinsSource = theMap.getSource(`mapPins${i}`);
                    if (typeof mapPinsLayer !== 'undefined' && typeof mapPinsSource !== 'undefined') {
                        theMap.removeLayer(`mapPins${i}`);
                        theMap.removeSource(`mapPins${i}`);
                    }
                }
                for (let i = 0; i < 3; i++) {
                    theMap.off('click', `route${i}`, routeClickHandler);
                }
            };
        }
    }, [theMap, route, routeRadius, geoJsonForMap, initialPins, isZoomPriority, activeNavigationMode, routeInfo, avoidTolls]);

    useEffect(() => {
        if (theMap) {
            for (let i = 0; i < 3; i++) {
                const routeLayer = theMap.getLayer(`route${i}`);
                const routeSource = theMap.getSource(`route${i}`);
                if (typeof routeLayer !== 'undefined' && typeof routeSource !== 'undefined') {
                    theMap.setPaintProperty(`route${i}`, 'line-color', '#999999');
                }
            }
            for (let i = 0; i < 3; i++) {
                const polygonRadiusLayer = theMap.getLayer(`polygonRadius${i}`);
                const polygonRadiusSource = theMap.getSource(`polygonRadius${i}`);
                if (typeof polygonRadiusLayer !== 'undefined' && typeof polygonRadiusSource !== 'undefined') {
                    theMap.setLayoutProperty(`polygonRadius${i}`, 'visibility', 'none');
                }
            }
            for (let i = 0; i < 3; i++) {
                const mapPinsLayer = theMap.getLayer(`mapPins${i}`);
                const mapPinsSource = theMap.getSource(`mapPins${i}`);
                if (typeof mapPinsLayer !== 'undefined' && typeof mapPinsSource !== 'undefined') {
                    theMap.setLayoutProperty(`mapPins${i}`, 'visibility', 'none');
                }
            }
            const routeSource = theMap.getSource(`route0`);
            if (typeof routeSource !== 'undefined') {
                theMap.setPaintProperty(`route${chosenRouteIndex}`, 'line-color', '#4882c5');
                theMap.setLayoutProperty(`route${chosenRouteIndex}`, 'visibility', 'visible');
                const polygonRadiusLayer = theMap.getLayer(`polygonRadius0`);
                if (typeof polygonRadiusLayer !== 'undefined') {
                    theMap.moveLayer(`route${chosenRouteIndex}`, 'polygonRadius0');
                } else {
                    theMap.moveLayer(`route${chosenRouteIndex}`, 'mapPins0');
                }
            }
            const polygonRadiusSource = theMap.getSource(`polygonRadius0`);
            if (typeof polygonRadiusSource !== 'undefined') {
                theMap.setLayoutProperty(`polygonRadius${chosenRouteIndex}`, 'visibility', 'visible');
            }
            const mapPinsSource = theMap.getSource(`mapPins0`);
            if (typeof mapPinsSource !== 'undefined') {
                if (routeRadius === 35) {
                    theMap.setLayoutProperty(`mapPins0`, 'visibility', 'visible');
                } else {
                    theMap.setLayoutProperty(`mapPins${chosenRouteIndex}`, 'visibility', 'visible');
                }
            }
        }
    }, [chosenRouteIndex]);

    useEffect(() => {
        if (theMap) {
            if(pulsingDot2.length === 3) {
                let color;
                switch (pulsingDot2[2]) {
                    case 1:
                        color = 'rgb(24,135,164, 0.4)';
                        break;
                    case 2:
                        color = 'rgb(181,31,90, 0.4)';
                        break;
                    case 3:
                        color = 'rgb(94,62,173, 0.4)';
                        break;
                    case 4:
                        color = 'rgb(255,193,7, 0.4)';
                        break;
                    case 5:
                        color = 'rgb(165,198,59, 0.4)';
                        break;
                    case 6:
                        color = 'rgb(233,79,103, 0.4)';
                        break;
                    case 7:
                        color = 'rgb(242,154,35, 0.4)';
                        break;
                    case 8:
                        color = 'rgb(61,183,111, 0.4)';
                        break;
                    case 9:
                        color = 'rgb(0,137,123, 0.4)';
                        break;
                    case 10:
                        color = 'rgb(244,37,122, 0.4)';
                        break;
                    case 11:
                        color = 'rgb(23,179,191, 0.4)';
                        break;
                    case 12:
                        color = 'rgb(249,145,100, 0.4)';
                        break;
                    case 13:
                        color = 'rgb(184,115,51, 0.4)';
                        break;
                    case 14:
                        color = 'rgb(63,82,178, 0.4)';
                        break;
                    default:
                        color = 'rgb(24,135,164, 0.4)';
                }
                let size = 70;
                let pulsingDot = {
                    width: size,
                    height: size,
                    data: new Uint8Array(size * size * 4),
                    onAdd: function() {
                        let canvas = document.createElement('canvas');
                        canvas.width = this.width;
                        canvas.height = this.height;
                        this.context = canvas.getContext('2d');
                    },
                    render: function() {
                        let duration = 1200;
                        let t = (performance.now() % duration) / duration;

                        let radius = (size / 2) * 0.3;
                        let outerRadius = (size / 2) * 0.7 * t + radius;
                        let context = this.context;

                        context.clearRect(0, 0, this.width, this.height);
                        context.beginPath();
                        context.arc(
                            this.width / 2,
                            this.height / 2,
                            outerRadius,
                            0,
                            Math.PI * 2
                        );
                        context.fillStyle = color;
                        context.fill();
                        this.data = context.getImageData(
                            0,
                            0,
                            this.width,
                            this.height
                        ).data;
                        theMap.triggerRepaint();
                        return true;
                    }
                };
                theMap.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
                theMap.addSource('points', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': pulsingDot2
                                }
                            }
                        ]
                    }
                });
                theMap.addLayer({
                    'id': 'points',
                    'type': 'symbol',
                    'source': 'points',
                    'layout': {
                        'icon-image': 'pulsing-dot',
                        'icon-allow-overlap': true,
                        "symbol-sort-key": ["to-number", ["get", "priority"]]
                    }
                });
                const mapLayers = theMap.getStyle().layers;
                for (let i = 0; i < 3; i++) {
                    const mapPinsLayer = theMap.getLayer(`mapPins${i}`);
                    if (typeof mapPinsLayer !== 'undefined') {
                        const isLargeNumber = (element) => element.id === `mapPins${i}`;
                        const layerIndex =  mapLayers.findIndex(isLargeNumber);
                        if (theMap.getStyle().layers[layerIndex].layout.visibility === 'visible') {
                            theMap.moveLayer('points', `mapPins${i}`);
                        }
                    }
                }
                return () => {
                    if (theMap.getLayer('points')) theMap.removeLayer('points');
                    if (theMap.hasImage('pulsing-dot')) theMap.removeImage('pulsing-dot');
                    theMap.removeSource('points');
                }
            }
        }
    }, [pulsingDot2]);

    useEffect(() => {
        if (theMap) {
            if(searchedLocation !== null) {
                theMap.flyTo({
                    center: [searchedLocation.lng, searchedLocation.lat],
                    zoom: 16,
                    speed: 1,
                    essential: true
                });
            }
        }
    }, [theMap, searchedLocation]);

    return <>
        <div ref={mapContainerRef} />
        {isPopUpVisible && <PopUpClick pinCoordinate={pinCoordinate}/>}
        {isPopUpHoverVisible && <PopUpHover pinHoverCoordinate={pinHoverCoordinate}/>}
    </>;
};

const mapStateToProps = state => {
    return {
        initialPins: state.filter.initialPins,
        route: state.router.route,
        geoJsonForMap: state.filter.geoJsonForMap,
        routeRadius: state.filter.routeRadius,
        pulsingDot2: state.map.pulsingDot,
        isZoomPriority: state.filter.isZoomPriority,
        searchedLocation: state.map.searchedLocation,
        popUpPin: state.map.popUpPin,
        activeNavigationMode: state.filter.activeNavigationMode,
        routeInfo: state.router.routeInfo,
        chosenRouteIndex: state.filter.chosenRouteIndex,
        avoidTolls: state.filter.avoidTolls
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onZoomEnd: currentZoom => dispatch(actions.getZoomLevel(currentZoom)),
        onGetPinsFromMap: pins => dispatch(actions.getPinsOnMap(pins)),
        onLoadMap: () => dispatch(actions.mapIsLoaded()),
        setPopUpPin: pinFeatures => dispatch(actions.setPopUpPin(pinFeatures)),
        setPopUpHoverPin: pinFeatures => dispatch(actions.setPopUpHoverPin(pinFeatures)),
        setChosenRouteIndex: routeIndex => dispatch(actions.setChosenRouteIndex(routeIndex)),
        setAllPossibleRoutes:  allPossibleRoutes => dispatch(actions.setAllPossibleRoutes(allPossibleRoutes))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MapboxGLMap);