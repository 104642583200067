import React from 'react';
import PopupContainer from "../PopupContainer/PopupContainer";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actionTypes from "../../../store/actions/actionTypes";
import translate from "../../../i18n/translate";

const FavoriteConfirmPopUp = props => {
    const deleteRouteHandler = () => {
        props.onCancelClicked();
        props.onFavoriteDelete(props.user.userId, props.token, props.favoriteForDelete);
    }
    const cancelDeleteHandler = () => {
        props.onCancelClicked();
    }

    return <PopupContainer class='delete-modal'>
        <div
            className="sprite-bg close-icon-big"
            onClick={cancelDeleteHandler}
        ></div>
        <h3>{translate('delete_favorite_text')}</h3>
        <div className='pop-up-divider' />
        <div className='button-wrapper'>
            <button
                className='btn accept'
                onClick={deleteRouteHandler}
            >
                <div className='sprite-bg accept-icon'></div>
                <span>{translate('yes')}</span>
            </button>
            <button
                className='btn dalete'
                onClick={cancelDeleteHandler}
            >
                <div className='sprite-bg cancel-icon'></div>
                <span>{translate('no')}</span>
            </button>
        </div>
    </PopupContainer>;
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        token: state.auth.token,
        favoriteForDelete: state.reducer.favoriteForDelete
    }
}

const mapDipatchToProps = dispatch => {
    return {
        onCancelClicked: () => dispatch({type: actionTypes.CLOSE_POPUPS_TWO}),
        onFavoriteDelete:  (userId, token, pinId) => dispatch(actions.deleteFavorite(userId, token, pinId))
    }
}

export default connect(mapStateToProps, mapDipatchToProps)(withRouter(FavoriteConfirmPopUp));