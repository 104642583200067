import {LOCALES} from "../locales";
import React from "react";

export default {
    [LOCALES.ENGLISH]: {
        'yes': 'Yes',
        'no': 'No',
        'new_route': 'NEW ROUTE',
        'search': 'Search',
        'lang_short': 'ENG',
        'SERBIAN': 'Serbian Latin',
        'CYRILIC': 'Serbian Cyrilic',
        'ENGLISH': 'English',
        'profile': 'Profile',
        'favorite_locations': 'Favorite',
        'saving_routes': 'Saved routes',
        'settings': 'Settings',
        'log_out': 'Log out',
        'log_in': 'Log in',
        'sign_up': 'Sign up',
        'recommendation': 'Recomendation',
        'guides': 'Guides',
        'about_us': 'About us',
        'tutorial': 'Tutorial',
        'login': 'Log in / Register',
        'route_radius': 'Radius around route',
        'infinitely': 'No radius',
        'route': 'Route',
        'read_more': 'Read more',
        'add_to_route': 'Add to route',
        'remove_from_route': 'Remove from route',
        'favorites': 'Favorites',
        'username': 'Username',
        'email': 'Email',
        'username_or_email': 'Username or email',
        'password': 'Password',
        'password_confirmation': 'Password confirmation',
        'connect_with_facebook': 'Connect with facebook',
        'back': 'Back',
        'share': 'Share',
        'edit_profile': 'Edit profile',
        'personal_info': 'Personal info',
        'change_username': 'Change username',
        'change_password': 'Change password',
        'old_password': 'Old password',
        'new_password': 'New password',
        'new_passowrd_confirm': 'Confirm new password',
        'avatar_image': 'Avatar image',
        'upload_imge': 'Upload image',
        'save_changes': 'Save changes',
        'sign_up_text': 'Sign up to save trips, places, and more.',
        'or': 'Or',
        'delete_route_text': 'Are you sure you want to delete the route?',
        'delete_favorite_text': 'Are you sure you want to remove from favorite?',
        'meet_serbia_blog': 'Meet Serbia blog',
        'all_tags': 'All tags',
        'tutorial_header': 'ADVENTURE IS A CLICK AWAY',
        'tutorial_body_top': 'Zoom, explore. Find new destination.',
        'tutorial_body_bottom': 'Make a route. Share it on your facebook.',
        'nacionalna_revija': 'Serbian national review',
        'princip_pres': 'Princip Press',
        'add_new_route_1': "Add",
        'add_new_route_2': "starting",
        'add_new_route_3': "destination",
        'add_last_destination_1': 'Add',
        'add_last_destination_2': 'final',
        'add_last_destination_3': 'destination',
        'add_additional_route_1': 'Add',
        'add_additional_route_2': 'stopovers',
        'add_additional_route_3': ' ',
        'save_route': 'Save route',
        'contact': 'Contact',
        'beograd': 'Belgrade',
        'phone': 'Phone',
        'delete_route': 'Delete route',
        'route_name': 'Rote name',
        'description': 'Description',
        'not_found': 'Not Found',
        'copyright': 'Copyright © 2022 Princip Press and Mišo Vujović',
        'move_on': 'Move, explore!',
        'about_us_body_one': 'The idea of MeetSerbia project is conceived as interactive online data base which unites all touristic potencials of Serbia.',
        'about_us_body_two': 'The purpose of meetserbia.rs portal is to represent total tourist offer in order to develop and popularize domestic tourism and tourist product.',
        'about_us_body_three': 'In 20 years from now you’ll be more dissapointed by the things you didn’t do than the things you did.',
        'about_us_body_four': 'So sail out from your safe harbour, discover, dream and explore.',
        'zoom_explore': 'We\'re sorry!\n' +
            'There are no mapped sites\n' +
            'in this part of the map...',
        'password_mismatch': 'Password mismatch',
        'user_not_exist' :  'User does not exist',
        'username_exist' : 'The username has already been taken.',
        'email_exist': 'The email has already been taken.',
        'password_must_match': 'The password must match.',
        'contact_us': 'Put your business on the map',
        'become_part_of_us': 'Take part in our presentation',
        'send': 'Send',
        'location': 'Location',
        'feedback_contact': 'Thank you for your time. We will reply in a short notice.',
        'email_is_required': 'The email field is required',
        'location_is_required': 'The location field is required',
        'description_is_required': 'The description field is required',
        'accessible': 'Accessible',
        'password_minimum': 'Password must be at least 8 characters',
        'email_validation': 'Enter vaild email',
        'reset_password':  'Reset password',
        'email_not_exist': 'The email not exist',
        'user_not_verified': 'User not verified',
        'user_required': 'Username or email is required',
        'forgot_password': 'Forgot password?',
        'username_required': 'Username is required',
        'verified_email': 'Check your email',
        'resend_verification': 'Resend verification',
        'lost_password_email': 'Enter email for lost password',
        'please_check': 'Please check',
        'please_check_email': 'email for restarting password.',
        'new_password_confirm': 'New password confirmation',
        'enter_new_password': 'Enter new password',
        'password_change_success': 'Password changed successfully',
        'not_robot': 'I\'m not robot',
        'logout_success': 'Logout success',
        'ministarstvo_rad': 'Ministry of Labor, Employment, Veterans and Social Affairs',
        'ministarstvo_trgovine': 'Ministry of Trade, Tourism and Telecommunications',
        'opstina_ruma': 'Municipality of Ruma',
        'opstina_vrnjacka_banja': 'Municipality of Vrnjacka Banja',
        'route_distance': 'Route distance',
        'old_password_did_not_matched': 'Old password did not matched.',
        'minimum_username_character': 'Minimum username character is 3',
        'password_march': 'Password must be same',
        'avatar_upload': 'Avatar upload success',
        "maximum_number_filter": 'Maximum number of added filter is 10',
        'ministarstvo_kulture': 'Ministry of culture and information',
        'successfully_changed_pass': 'You have successfully changed the password. Enjoy the app meetserbia.rs',
        'enter_valid_email': 'Enter valid email',
        'portfolio_header': '„Princip Pres” – portfolio',
        'portfolio_body_1_bold': 'Кoncept: ',
        'portfolio_body_1': 'Beogradska izdavačka kuća „Princip Pres” je jedan od nesumnjivih\n' +
            'lidera srpskog izdavaštva u oblasti negovanja nacionalne kulturne i duhovne\n' +
            'baštine, predstavljanja turističke ponude, popravljanja imidža i rebrendiranja\n' +
            'Srbije. Svojom ukupnom izdavačkom produkcijom, na srpskom i više svetskih\n' +
            'jezika, vrhunski opremljeno i dizajnirano, „Princip Pres” dosledno predstavlja lepo\n' +
            'lice Srbije. Afirmiše ono što Srbe čini narodom među narodima u evropskoj\n' +
            'porodici. To su izdanja kojima se na kompetentan i upečatljiv način pomaže\n' +
            'dobronamernim strancima da na pravi način otkriju verodostojnu Srbiju, da je bolje\n' +
            'razumeju i prisnije dožive, da kroz nju putuju i iskoriste njene velike mogućnosti.\n' +
            'Istovremeno, to su naslovi koji srpske čitaoce podstiču da neprestano iznova\n' +
            'otkrivaju privlačnost sopstvene zemlje, njene možda zaboravljene čari i prednosti,\n' +
            'da obnove dar radoznalosti i sposobnost pozitivnih prepoznavanja, da se s\n' +
            'ponosom učvrste u sopstvenom identitetu.',
        'portfolio_body_2_bold': 'Izdanja: ',
        'portfolio_body_2': 'Takvu svoju bazičnu ideju i koncept „Princip Pres” realizuje u širokom\n' +
            'spektru oblika, od reprezentativnog časopisa „SRBIJA – Nacionalna revija” koji\n' +
            'izlazi u odvojenim izdanjima na srpskom i engleskom jeziku, zatim polučasovne\n' +
            'emisije „Od zlata jabuka”, aplikacije „Meet Serbia”, preko svojih veb-portala,\n' +
            'kompakt diskova, spotova i filmova, do izuzetnih knjiga kao što su „Srbija, od zlata\n' +
            'jabuka”, „Srbija pod kapom nebeskom”, „Srbija – drumovima, prugama, rekama”,\n' +
            '„Sto dobrih razloga da posetite Srbiju”, „Nečujna zvona – hrišćansko nasleđe\n' +
            'Кosova i Metohije”, „Boj iznad vekova-Кumanovska bitka“, „Čuvar svetih humki“,\n' +
            '„Guča – narodni karneval srpskog džeza”, „Beli anđeo – Turistička biblija Srbije”,\n' +
            '„Tlo Srbije – zavičaj rimskih careva”, „Evropski putevi kulture – Кoridor 10”...',
        'portfolio_body_3_bold': 'Citat: ',
        'portfolio_body_3': '„Srbija i njena kultura se u izdanjima ,Princip Presa’ otvaraju pred nama u\n' +
            'svoj svojoj veličini, lepoti i tananosti... Preporučujem da pogledate sva divotna lica,\n' +
            'svetlosti i bljeskove Srbije u monografiji ,Srbija, od zlata jabuka’ (na srpskom,\n' +
            'engleskom, ruskom i italijanskom jeziku). Zatim, veliki ilustrovani temat o istoriji i\n' +
            'kulturi Srbije u monografskom vodiču ,Srbija – drumovima, prugama, rekama’, toj\n' +
            'pravoj maloj enciklopediji srpske kulture datoj kroz ideju putovanja Srbijom.\n' +
            'Monografija ,Beli anđeo – turistička biblija Srbije’, zapravo jedna inteligentna\n' +
            'panorama srpske kulture i baštine, sva je prožeta Srbijom i njenom kulturom, a\n' +
            'nadahnuti tekst ,Breg za razmišljanje’ spada među najbolje ikada napisane o\n' +
            'srpskoj prestonici...” (Prof. dr Dragan Nedeljković, redovni član Evropske\n' +
            '\n' +
            'akademije nauka, umetnosti i književnosti, Pariz, Francuska)',
        'portfolio_body_4_bold': 'Nagrade: ',
        'portfolio_body_4': 'Pored velikog broja probirljivih čitalaca u zemlji i inostranstvu, o\n' +
            'vrednosti izdanja i projekata „Princip Presa” svedoče i brojne ugledne nagrade\n' +
            'kojima su ovenčana, od „Zlatnog Beočuga” za 2008. godinu koja se odnosi na\n' +
            'trajni doprinos kulturi Beograda, Nagrade „Moštanica” za Izdavački poduhvat\n' +
            'godine na Međunarodnom sajmu knjiga u Banjaluci 2010, „Turističkog cveta” za\n' +
            '2006, „Najbolje turističke publikacije” za 2007, nagrade „Stuplje” na\n' +
            'Međunarodnom sajmu knjiga u Banjaluci 2007. i 2008, do „Srebrnog pečata 2006”\n' +
            'na Sajmu grafike u Beogradu (za dizajn i originalno grafičko rešenje) i višestruke\n' +
            'nagrade „Gutemberg”.',
        'cloud_tutorial_1': 'Create a route',
        'cloud_tutorial_2': 'Search the route by categories',
        'cloud_tutorial_3': 'Zoom in and explore',
        'driving': 'Driving',
        'walking': 'Walking',
        'cycling': 'Cycling',
        'route_length': 'Route length',
        'avoid_tolls': 'Avoid Tolls',
        'impresum': 'Impresum',
        'impresum_header': 'INTERNET PORTAL “MEETSERBIA”',
        'impresum_header_1': 'Publisher',
        'impresum_info_1': '„Princip Press”',
        'impresum_info_2': 'Cetinjska 6, 11103 Belgrade',
        'impresum_info_3': 'Phone: +381 (11) 322 70 34',
        'impresum_info_4': 'princip.press@gmail.com',
        'impresum_header_2': 'On Behalf of the Publisher',
        'impresum_info_5': 'Mišo Vujović,',
        'impresum_info_6': 'Director and Editor-in-Chief',
        'impresum_info_7': 'miso@nacionalnarevija.com',
        'impresum_header_3': 'Data entry',
        'impresum_info_8': 'Aleksandar Ćosić',
        'impresum_info_9': 'princip.dtp@gmail.com',
        'impresum_header_4': 'Photography Editor',
        'impresum_info_10': 'Dragan Bosnić',
        'impresum_header_5': 'Marketing',
        'impresum_info_11': 'Mirko Vujović',
        'impresum_info_12': 'mirko@nacionalnarevija.com',
        'impresum_header_6': 'Office Secretary',
        'impresum_info_13': 'Milenko Vasilić',
        'impresum_info_14': 'Dragana Dimitrijević',
        'impresum_info_15': 'dragana.dimitrijevic@nacionalnarevija.com',
        'impresum_header_7': 'Media Register of the Republic of Serbia',
        'impresum_info_16': 'Registration number: IN001148',
        'register_agreement_1': 'By pressing sign up you agree with MeetSerbia ',
        'register_agreement_2': 'Terms ',
        'register_agreement_3': 'and ',
        'register_agreement_4': 'Privacy Policy ',
        'register_agreement_5': 'and confirm you are 18 years of age or older.',
        'terms_of_use': 'Terms of Use',
        'privacy_policy': 'Privacy Policy',
        'opstina_irig': 'Municipality of Irig'
    }
};