import * as actionTypes from './actionTypes';
import axios from "axios";
import {saveToStorage, deleteFromStorage, getFromStorage} from "../../utils/sessionStorage";
import {saveRoute} from "./router";
import {selectProfile} from "./profile";

const apiURL = process.env.REACT_APP_API_URL;

export const selectAuth = (authIndex) => {
    return {
        type: actionTypes.OPEN_AUTH_POP_UP,
        authIndex: authIndex
    }
};

const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

const authSuccess = (token, user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user: user
    };
};

const registerSuccess = email => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        email
    };
};

const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

const refreshFavorites = isLogout => {
    return {
        type: actionTypes.REFRESH_FAVORITES,
        isLogout: isLogout
    }
};

const logoutSuccess = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const logout = () => {
    deleteFromStorage("token");
    deleteFromStorage("user");
    return dispatch => {
        dispatch(logoutSuccess());
        setTimeout(() => {
            dispatch(refreshFavorites(true));
        }, 500);
    }
};

export const register = (username, email, password, password_confirmation) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: username,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        }
        let url = `${apiURL}/v1/register`;
        axios.post(url, authData)
            .then(response => {
                dispatch(registerSuccess(response.data.email));
            }).catch(error => {
            dispatch(authFail(error.response.data));
        });
    };
};

export const login = (login, password, activeCategories, tmpForAdd) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            login: login,
            password: password
        };
        let url = `${apiURL}/v1/login`;
        axios.post(url, authData)
            .then(response => {
                const user = {
                    userId: response.data.user.id,
                    username: response.data.user.username,
                    avatar_url: response.data.user.avatar_url,
                    email: response.data.user.email
                };
                saveToStorage('token', response.data.token);
                saveToStorage('user', user);
                dispatch(authSuccess(response.data.token, user));
                activeCategories.map(category => {
                    if (category.category_name === "Favorites" || category.category_name === "Омиљене локације" || category.category_name === "Omiljene lokacije") {
                        setTimeout(() => {
                            dispatch(refreshFavorites(false));
                        }, 1000);
                    }
                    return category;
                });
                if (tmpForAdd.isAddFavorite) {
                    dispatch(selectProfile(1));
                    tmpForAdd.history.push('/profile');
                }
                if (tmpForAdd.isAddRoute) {
                    dispatch(saveRoute(response.data.token, tmpForAdd.route, user, tmpForAdd.history));
                }
            }).catch(error => {
            dispatch(authFail(error.response.data));
        });
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = getFromStorage('token');
        const user = getFromStorage('user');
        dispatch(authSuccess(token, user));
    };
};

export const openLogInPopUp = forAddAfterLogin => {
    return {
        type: actionTypes.OPEN_LOG_IN_POP_UP,
        forAddAfterLogin: forAddAfterLogin
    };
};

const changeUsernameStart = () => {
    return {
        type: actionTypes.CHANGE_USERNAME_START
    };
};

const changeUsernameSuccess = user => {
    return {
        type: actionTypes.CHANGE_USERNAME_SUCCESS,
        user: user
    }
};

export const changeUsername = (token, userId, username) => {
    return dispatch => {
        dispatch(changeUsernameStart());
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        const data = {userId, username};
        axios.put(`${apiURL}/v1/update`, data, config)
            .then(response => {
                const user = {
                    userId: response.data.id,
                    username: response.data.username,
                    avatar_url: response.data.avatar_url
                }
                saveToStorage('user', user);
                dispatch(changeUsernameSuccess(user));
            }).catch(error => {
        });
    }
};

const changePassowrdStart = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_START
    };
};

const changePassowrdSuccess = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS
    }
};

export const setAlertMessage = (alertMessage, alertType) => {
    return {
        type: actionTypes.SET_ALERT_MESSAGE,
        alertMessage: alertMessage,
        alertType: alertType
    }
};

export const deleteAlertMassage = () => {
    return {
        type: actionTypes.DELETE_ALERT_MASSAGE
    };
};

export const changePassword = (token, userId, password, new_password, new_confirm_password) => {
    return dispatch => {
        dispatch(changePassowrdStart());
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        const data = {userId, password, new_password, new_confirm_password};
        axios.post(`${apiURL}/v1/change-password`, data, config)
            .then(response => {
                dispatch(changePassowrdSuccess(response));
                dispatch(setAlertMessage('password_change_success', 'alert_success'));
            }).catch(error => {
            dispatch(setAlertMessage('old_password_did_not_matched', 'alert_error'));
        });
    }
};

const changeAvatarStart = () => {
    return {
        type: actionTypes.CHANGE_AVATAR_START
    };
};

const changeAvatarSuccess = avatar_url => {
    return {
        type: actionTypes.CHANGE_AVATAR_SUCCESS,
        avatar_url: avatar_url
    }
};

export const changeAvatar = (token, userId, image) => {
    return dispatch => {
        dispatch(changeAvatarStart());
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        };
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('image', image);
        axios.post(`${apiURL}/v1/avatar`, formData, config)
            .then(response => {
                const user = {
                    userId: response.data.id,
                    username: response.data.username,
                    avatar_url: response.data.avatar_url
                }
                saveToStorage('user', user);
                dispatch(changeAvatarSuccess(response.data.avatar_url));
                dispatch(setAlertMessage('avatar_upload', 'alert_success'));
            }).catch(error => {
        });
    }
};

const facebookCheckStart = () => {
    return {
        type: actionTypes.FACEBOOK_CHECK_START
    };
};

const facebookCheckSuccess = (user, token) => {
    return {
        type: actionTypes.FACEBOOK_CHECK_SUCCESS,
        user: user,
        token: token
    }
};

export const facebookCheck = facebookInfo => {
    return dispatch => {
        dispatch(facebookCheckStart());
        axios.post(`${apiURL}/v1/facebook`, facebookInfo)
            .then(response => {
                const user = {
                    userId: response.data.user.id,
                    username: response.data.user.username,
                    avatar_url: response.data.user.avatar_url
                }
                saveToStorage('user', user);
                saveToStorage('token', response.data.token);
                dispatch(facebookCheckSuccess(user, response.data.token));
            }).catch(error => {});
    }
};

const resetEmailStart = () => {
    return {
        type: actionTypes.RESET_EMAIL_START
    }
};

const resetEmailSuccess = () => {
    return {
        type: actionTypes.RESET_EMAIL_SUCCESS
    }
};

export const resetEmail = email => {
    return dispatch => {
        dispatch(resetEmailStart());
        const authData = {
            email: email
        }
        let url = `${apiURL}/v1/forgot-password`;
        axios.post(url, authData)
            .then(response => {
                dispatch(resetEmailSuccess());
            }).catch(error => {
            dispatch(authFail(error.response.data.errors));
        });
    };
};

const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START
    }
};

const resetPasswordSuccess = () => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS
    }
};

export const resetPassword = (email, token, password) => {
    return dispatch => {
        dispatch(resetPasswordStart());
        const authData = {
            email: email,
            token: token,
            password: password
        }
        let url = `${apiURL}/v1/reset-password`;
        axios.post(url, authData)
            .then(response => {
                dispatch(login(email, password));
                dispatch(setAlertMessage('password_change_success', 'alert_success'));
                setTimeout(() => {
                    dispatch(resetPasswordSuccess());
                }, 300);
            }).catch(error => {
            dispatch(authFail(error.response.data));
        });
    };
};

const resendEmailStart = () => {
    return {
        type: actionTypes.RESEND_EMAIL_START
    }
};

const resendEmailSuccess = () => {
    return {
        type: actionTypes.RESEND_EMAIL_SUCCESS
    }
};

const resendEmailFail = error => {
    return {
        type: actionTypes.RESEND_EMAIL_FAIL,
        error
    }
};

export const resendVerificationEmail = email => {
    return dispatch => {
        dispatch(resendEmailStart());
        let url = `${apiURL}/v1/resend/${email}`;
        axios.post(url)
            .then(response => {
                dispatch(resendEmailSuccess());
            }).catch(error => {
            dispatch(resendEmailFail(error.response.data));
        });
    };
};

const verifyEmailStart = () => {
    return {
        type: actionTypes.VERIFY_EMAIL_START
    }
};

const verifyEmailFail = error => {
    return {
        type: actionTypes.VERIFY_EMAIL_FAIL,
        error
    }
};

export const verifyEmail = token => {
    return dispatch => {
        dispatch(verifyEmailStart());
        let url = `${apiURL}/v1/verify/${token}`;
        axios.post(url)
            .then(response => {
                const user = {
                    userId: response.data.user.id,
                    username: response.data.user.username,
                    avatar_url: response.data.user.avatar_url,
                    email: response.data.user.email
                }
                saveToStorage('token', response.data.token);
                saveToStorage('user', user);
                dispatch(authSuccess(response.data.token, user));
            }).catch(error => {
            dispatch(verifyEmailFail(error.response.data));
        });
    };
};

export const clearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR
    }
};