import React from 'react';
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import * as actionTypes from "../../../store/actions/actionTypes";
import translate from "../../../i18n/translate";

const LocationPrewievHeader = props => {
    const {
        lastActiveCategory,
        activeCategories,
        onCategoryDeleted,
        onCategoryHeaderSetActive,
        categoriesListTop,
        categoriesListBottom,
        initialPins,
        loaderStart,
        favorites
    } = props;
    const categoryHeaderHandler = (categoryId) => {
        loaderStart();
        onCategoryDeleted(categoryId, categoriesListTop, categoriesListBottom, activeCategories, lastActiveCategory, initialPins);
    };
    const setActiveHeaderHandler = (categoryId) => {
        loaderStart();
        onCategoryHeaderSetActive(categoryId, activeCategories, favorites);
    };
    let zIndex = 50;
    let i = 1;
    let width = 448;
    const rowLen = activeCategories.length;
    const activeCategoryHeader = activeCategories.map(categoryList => {
        const divStyleActive = {
            zIndex: 50+(1*(rowLen+2)),
            width: 416-(32*rowLen)
        };
        if (lastActiveCategory === categoryList.category_id) {
            let name = categoryList.category_name;
            if (categoryList.category_id === 99) {
                name = translate('route');
            }
            return <div
                key={categoryList.category_id}
                className={`category-info cat-${categoryList.category_id}`}
                style={divStyleActive}
            >
                <span className='category-text'>{name}</span>
                <div
                    className={`sprite-bg close-icon-small`}
                    onClick={() => categoryHeaderHandler(categoryList.category_id)}
                />
            </div>
        } else {
            i += 1;
            zIndex = zIndex + 1;
            width = 448 - (32*(i));
            const divStyle = {
                zIndex: zIndex,
                width: width
            };
            return <div
                key={categoryList.category_id}
                className={`category-info cat-${categoryList.category_id}`}
                style={divStyle}
                onClick={() => setActiveHeaderHandler(categoryList.category_id)}
            >
                <div className={`sprite-bg cat-${categoryList.category_id}-icon-small`}/>
            </div>
        }
    });

    return <div className='prewiev-header-wrapper'>
            {activeCategoryHeader}
        </div>;
};

const mapsStateToProps = state => {
    return {
        activeCategories: state.filter.activeCategories,
        lastActiveCategory: state.filter.lastActiveCategory,
        categoriesListTop: state.filter.categoriesListTop,
        categoriesListBottom: state.filter.categoriesListBottom,
        initialPins: state.filter.initialPins,
        favorites: state.filter.favorites
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCategoryDeleted: (
            categoryId,
            categoriesListTop,
            categoriesListBottom,
            activeCategories,
            lastActiveCategory,
            initialPins
        ) => dispatch(actions.deleteCategory(
            categoryId,
            categoriesListTop,
            categoriesListBottom,
            activeCategories,
            lastActiveCategory,
            initialPins
        )),
        onCategoryHeaderSetActive: (categoryId, activeCategories, favorites) => dispatch(actions.setActiveHeader(categoryId, activeCategories, favorites)),
        loaderStart: () => dispatch({type: actionTypes.START_POPUP_PIN})
    }
};

export default connect(mapsStateToProps, mapDispatchToProps)(LocationPrewievHeader);