export const saveToStorage = (name, data) => {
    if (!window || !window.localStorage) {
        return;
    }

    window.localStorage.setItem(name, JSON.stringify(data));
};

export const getFromStorage = name => {
    const items = window.localStorage.getItem('siteLangId');

    if (items === null || items.length === 0) {
        window.localStorage.setItem('siteLangId', '2');
    }

    if (!window || !window.localStorage) {
        return null;
    }

    try {
        return JSON.parse(window.localStorage.getItem(name));
    } catch (e) {
        return null;
    }
};

export const deleteFromStorage = name => {
    window.localStorage.removeItem(name);
};