export const OPEN_BURGER_MENU = 'OPEN_BURGER_MENU';
export const CLOSE_BURGER_MENU = 'CLOSE_BURGER_MENU';
export const START_POPUP_PIN = 'START_POPUP_PIN';
export const SUCCESS_POPUP_PIN = 'SUCCESS_POPUP_PIN';
export const CLOSE_POPUPS = 'CLOSE_POPUPS';
export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const GET_ALL_PINS_START = 'GET_FILTER_PINS_START';
export const GET_ALL_PINS_SUCCESS = 'GET_FILTER_PINS_SUCCESS';
export const GET_PINS_ON_MAP = 'GET_PINS_ON_MAP';
export const GET_MAP_ZOOM_LEVEL = 'GET_MAP_ZOOM_LEVEL';
export const ADD_CATEGORY_LIST = 'ADD_CATEGORY_LIST';
export const DELETE_CATEGORY_LIST = 'DELETE_CATEGORY_LIST';
export const ADD_TAG_LIST = 'ADD_TAG_LIST';
export const DELETE_TAG_LIST = 'DELETE_TAG_LIST';
export const SET_ALL_TAGS = 'SET_ALL_TAGS';
export const SET_ACTIVE_HEADER = 'SET_ACTIVE_HEADER';
export const ADD_ROUTER_TO_HEADER = 'ADD_ROUTER_TO_HEADER';
export const ADD_PIN_TO_ROUTER = 'ADD_PIN_TO_ROUTER';
export const DELETE_PIN_FROM_ROUTER = 'DELETE_PIN_FROM_ROUTER';
export const CHANGE_PIN_ORDER_IN_ROUTE = 'CHANGE_PIN_ORDER_IN_ROUTE';
export const DELETE_MAP_PIN_FROM_ROUTER = 'DELETE_MAP_PIN_FROM_ROUTER';
export const SET_ROUTER_RADIUS = 'SET_ROUTER_RADIUS';
export const START_RECOMMENDATIONS = 'START_RECOMMENDATIONS';
export const SUCCES_RECOMMENDATIONS = 'SUCCES_RECOMMENDATIONS';
export const OPEN_RECOMMENDATIONS = 'OPEN_RECOMMENDATIONS';
export const START_GUIDES = 'START_GUIDES';
export const SUCCES_GUIDES = 'SUCCES_GUIDES';
export const OPEN_GUIDES = 'OPEN_GUIDES';
export const OPEN_AUTH_POP_UP = 'OPEN_AUTH_POP_UP';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const RESEND_EMAIL_START = 'RESEND_EMAIL_START';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAIL = 'RESEND_EMAIL_FAIL';
export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const OPEN_PROFILE = 'OPEN_PROFILE';
export const SAVE_ROUTE_START = 'SAVE_ROUTE_START';
export const GET_ROUTES_START = 'GET_ROUTES_START';
export const GET_ROUTES_SUCCESS = 'GET_ROUTES_SUCCESS';
export const UPDATE_ROUTE_INFO_START = 'UPDATE_ROUTE_INFO_START';
export const UPDATE_ROUTE_INFO_SUCCESS = 'UPDATE_ROUTE_INFO_SUCCESS';
export const DELETE_ROUTE_START = 'DELETE_ROUTE_START';
export const DELETE_ROUTE_SUCCESS = 'DELETE_ROUTE_SUCCESS';
export const GET_ROUTE_START = 'GET_ROUTE_START';
export const GET_ROUTE_SUCCESS = 'GET_ROUTE_SUCCESS';
export const CLOSE_POPUPS_TWO = 'CLOSE_POPUPS_TWO';
export const OPEN_CONFIRM_POP_UP = 'OPEN_CONFIRM_POP_UP';
export const OPEN_LOG_IN_POP_UP = 'OPEN_LOG_IN_POP_UP';
export const ADD_TO_FAVORITE_START = 'ADD_TO_FAVORITE_START';
export const ADD_TO_FAVORITE_SUCCESS = 'ADD_TO_FAVORITE_SUCCESS';
export const FETCH_FAVORITE_START = 'FETCH_FAVORITE_START';
export const FETCH_FAVORITE_SUCCESS = 'FETCH_FAVORITE_SUCCESS';
export const DELTE_FAVORITE_START = 'DELTE_FAVORITE_START';
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';
export const OPEN_CONFIRM_FAVORITE_POP_UP = 'OPEN_CONFIRM_FAVORITE_POP_UP';
export const CHANGE_USERNAME_START = 'CHANGE_USERNAME_START';
export const CHANGE_USERNAME_SUCCESS = 'CHANGE_USERNAME_SUCCESS';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_AVATAR_START = 'CHANGE_AVATAR_START';
export const CHANGE_AVATAR_SUCCESS = 'CHANGE_AVATAR_SUCCESS';
export const OPEN_TUTORIAL = 'OPEN_TUTORIAL';
export const FACEBOOK_CHECK_START = 'FACEBOOK_CHECK_START';
export const FACEBOOK_CHECK_SUCCESS = 'FACEBOOK_CHECK_SUCCESS';
export const MAP_IS_LOADED = 'MAP_IS_LOADED';
export const OPEN_ABOUT_US = 'OPEN_ABOUT_US';
export const SET_PULSING_DOT = 'SET_PULSING_DOT';
export const REMOVE_PULSING_DOT = 'REMOVE_PULSING_DOT';
export const CONTACT_US_OPEN = 'CONTACT_US_OPEN';
export const CONTACT_US_START = 'CONTACT_US_START';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const LOADER_STOP = 'LOADER_STOP';
export const RESET_EMAIL_START = 'RESET_EMAIL_START';
export const RESET_EMAIL_SUCCESS = 'RESET_EMAIL_SUCCESS';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const DELETE_ALERT_MASSAGE = 'DELETE_ALERT_MASSAGE';
export const SET_ROUTE_FOR_SHARE = 'SET_ROUTE_FOR_SHARE';
export const REFRESH_FAVORITES = 'REFRESH_FAVORITES';
export const SET_POP_UP_PIN = 'SET_POP_UP_PIN';
export const SET_POP_UP_HOVER_PIN = 'SET_POP_UP_HOVER_PIN';
export const SET_CLOUD_TUTORIAL = 'SET_CLOUD_TUTORIAL';
export const CHANGE_ROUTE_MODE = 'CHANGE_ROUTE_MODE';
export const SET_CHOSEN_ROUTE_INDEX = 'SET_CHOSEN_ROUTE_INDEX';
export const SET_ALL_POSSIBLE_ROUTES = 'SET_ALL_POSSIBLE_ROUTES';
export const SAVE_ROUTE_SUCCESS = 'SAVE_ROUTE_SUCCESS';
export const IS_FULL_GALLERY_OPEN = 'IS_FULL_GALLERY_OPEN';
export const SET_FULL_GALLERY_PICTURES = 'SET_FULL_GALLERY_PICTURES';
export const SET_AVOID_TOLLS = 'SET_AVOID_TOLLS';
export const SET_INITIAL_LOADING = 'SET_INITIAL_LOADING';
export const SET_IS_INITIAL_MTS_VIDEO_LOADED = 'SET_IS_INITIAL_MTS_VIDEO_LOADED';