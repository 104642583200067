import * as actionsTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    route: [],
    routeInfo: null,
    routes: [],
    routeForShare: null
};

const reducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case actionsTypes.ADD_PIN_TO_ROUTER:
                let category_id = null;
                if(action.category_id !== undefined) {
                    category_id = action.category_id
                }
                let category_name = null;
                if(action.category_name !== undefined) {
                    category_name = action.category_name
                }
                const addPin = {
                    pinName: action.pinName,
                    pinId: action.pinId,
                    lng: action.lng,
                    lat: action.lat,
                    img_url: action.img_url,
                    category_id: category_id,
                    category_name: category_name
                }
                const routeLength = draft.route.length;
                if(routeLength < 2) {
                    draft.route.push(addPin);
                } else {
                    draft.route.splice(routeLength-1, 0, addPin);
                }
                return;
            case actionsTypes.DELETE_PIN_FROM_ROUTER:
                draft.route.splice(action.index, 1);
                return;
            case actionsTypes.DELETE_MAP_PIN_FROM_ROUTER:
                const newRoute = draft.route.filter(x => x.pinId !== action.pinId);
                draft.route = newRoute;
                return;
            case actionsTypes.CHANGE_PIN_ORDER_IN_ROUTE:
                const {destination, source} = action.result;
                if(!destination) {
                    return;
                }
                if(destination.droppableId === source.droppableId && destination.index === source.index) {
                    return;
                }
                const pinForMove = draft.route[source.index];
                draft.route.splice(source.index, 1);
                draft.route.splice(destination.index, 0, pinForMove);
                return;
            case actionsTypes.DELETE_CATEGORY_LIST:
                if(action.categoryId === 99) {
                    draft.route = [];
                    draft.routeInfo = null;
                }
                return;
            case actionsTypes.GET_ROUTES_SUCCESS:
                draft.routes = action.routes;
                return;
            case actionsTypes.GET_ROUTE_SUCCESS:
                let routeFromServer = [];
                action.waypoints.map(pin => {
                    const newPin = {
                        pinName: pin.pin_name,
                        pinId: pin.pin_id,
                        lng: pin.lng,
                        lat: pin.lat,
                        img_url: pin.img_url,
                        category_id: pin.category_id,
                        category_name: pin.category_name,
                    };
                    routeFromServer.push(newPin);
                    return pin;
                });
                draft.route = routeFromServer;
                draft.routeInfo = action.route;
                return;
            case actionsTypes.UPDATE_ROUTE_INFO_SUCCESS:
                draft.routes = draft.routes.filter(x => x.id !== action.id);
                return;
            case actionsTypes.SET_ROUTE_FOR_SHARE:
                draft.routeForShare = action.route;
                return;
            case actionsTypes.SAVE_ROUTE_SUCCESS:
                draft.routeInfo = [action.route.Route];
                return;
            default:
                return;
        }
    });
};

export default reducer;