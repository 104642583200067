import React from "react";
import {Link} from "react-router-dom";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import translate from "../../../../../i18n/translate";

const AuthDropDown = props => {
    const {onAuthSelected} = props;
    const authPopUpHandler = (authIndex) => {
        onAuthSelected(authIndex);
    }
    return <ul>
        <li>
            <Link
                className="settings-nav-text"
                to='/auth'
                onClick={() => authPopUpHandler(0)}
            >{translate('log_in')}</Link>
        </li>
        <li>
            <Link
                className="settings-nav-text"
                to='/auth'
                onClick={() => authPopUpHandler(1)}
            >{translate('sign_up')}</Link>
        </li>
    </ul>
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthSelected: (authIndex) => dispatch(actions.selectAuth(authIndex))
    }
}

export default connect(null, mapDispatchToProps)(AuthDropDown);