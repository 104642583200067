import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    token: null,
    error: null,
    user: null,
    isLogin: null,
    isEmailReset: false,
    isVerificationPage: false,
    verificationEmail: null,
    alertInfo: null,
    isPasswordChanged: false,
    tmpPinFavoritesId: null,
    isRouteForAdd: false,
    forAddAfterLogin: {
        tmpPinFavoritesId: null,
        isRouteForAdd: false,
    }
};

const reducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case actionTypes.AUTH_START:
                draft.error = null;
                return;
            case actionTypes.AUTH_SUCCESS:
                draft.token = action.token;
                draft.user = action.user;
                return;
            case actionTypes.AUTH_FAIL:
                draft.error = action.error;
                return;
            case actionTypes.AUTH_LOGOUT:
                draft.token = null;
                return;
            case actionTypes.OPEN_LOG_IN_POP_UP:
                draft.isLogin = true;
                draft.forAddAfterLogin = action.forAddAfterLogin;
                return;
            case actionTypes.CLOSE_POPUPS_TWO:
                draft.isLogin = false;
                return;
            case actionTypes.CHANGE_USERNAME_SUCCESS:
                draft.user = action.user;
                return;
            case actionTypes.CHANGE_AVATAR_SUCCESS:
                draft.user.avatar_url = action.avatar_url
                return;
            case actionTypes.FACEBOOK_CHECK_SUCCESS:
                draft.user = action.user;
                draft.token = action.token;
                return;
            case actionTypes.CONTACT_US_FAIL:
                draft.error = action.error;
                return;
            case actionTypes.CLOSE_POPUPS:
                draft.error = null;
                return;
            case actionTypes.RESET_EMAIL_SUCCESS:
                draft.isEmailReset = true;
                return;
            case actionTypes.REGISTER_SUCCESS:
                draft.isVerificationPage = true;
                draft.verificationEmail = action.email;
                return;
            case actionTypes.RESEND_EMAIL_FAIL:
                draft.error = action.error;
                return;
            case actionTypes.VERIFY_EMAIL_FAIL:
                draft.error = action.error;
                return;
            case actionTypes.CLEAR_ERROR:
                draft.error = null;
                return;
            case actionTypes.SET_ALERT_MESSAGE:
                draft.alertInfo = {
                    message: action.alertMessage,
                    type: action.alertType
                };
                return;
            case actionTypes.DELETE_ALERT_MASSAGE:
                draft.alertInfo = null;
                return;
            case actionTypes.RESET_PASSWORD_SUCCESS:
                draft.isPasswordChanged = true;
                return;
            case actionTypes.ADD_TO_FAVORITE_SUCCESS:
                draft.forAddAfterLogin.tmpPinFavoritesId = null;
                return;
            default:
                return;
        }
    });
};

export default reducer;