import {LOCALES} from "../locales";

export default {
    [LOCALES.CYRILIC]: {
        'yes': 'Да',
        'no': 'Не',
        'new_route': 'НОВА РУТА',
        'search': 'Претражи',
        'lang_short': 'СРБ',
        'SERBIAN': 'Латиница',
        'CYRILIC': 'Ћирилица',
        'ENGLISH': 'Енглески',
        'profile': 'Профил',
        'favorite_locations': 'Омиљене локације',
        'saving_routes': 'Сачуване руте',
        'settings': 'Подешавања',
        'log_out': 'Излогуј се',
        'log_in': 'Улогуј се',
        'sign_up': 'Региструј се',
        'recommendation': 'Препоруке',
        'guides': 'Водичи',
        'about_us': 'О нама',
        'tutorial': 'Упутство',
        'login': 'Пријави се / Региструј се',
        'route_radius': 'Радијус око руте',
        'infinitely': 'Без радијуса',
        'route': 'Рута',
        'read_more': 'Прочитај више',
        'add_to_route': 'Додај у руту',
        'remove_from_route': 'Обриши из руте',
        'favorites': 'Омиљене локације',
        'username': 'Корисничко име',
        'email': 'Е-маил',
        'username_or_email': 'Корисничко име или е-маил',
        'password': 'Лозинка',
        'password_confirmation': 'Потврди лозинку',
        'connect_with_facebook': 'Пријави се преко Фејсбук налога',
        'back': 'Назад',
        'share': 'Подели',
        'edit_profile': 'Измени профил',
        'personal_info': 'Личне информације',
        'change_username': 'Промени надимак',
        'change_password': 'Измени лозинку',
        'old_password': 'Стара лозинка',
        'new_password': 'Нова лозинка',
        'new_passowrd_confirm': 'Потврди нову лозинку',
        'avatar_image': 'Профилна слика',
        'upload_imge': 'Измени слику',
        'save_changes': 'Сачувај измене',
        'sign_up_text': 'Улогујте се да сачувате руте, локације и више.',
        'or': 'Или',
        'delete_route_text': 'Да ли сте сигурни да желите да обришете руту?',
        'delete_favorite_text': 'Да ли сте сигурни да желите да обришете омиљену локацију?',
        'meet_serbia_blog': 'Meet Serbia блог',
        'all_tags': 'Сви тагови',
        'tutorial_header': 'АВАНТУРА ЈЕ НА КЛИК ОД ТЕБЕ',
        'tutorial_body_top': 'Зумирај, истражи. Пронађи нову дестинацију.',
        'tutorial_body_bottom': 'Направи руту. Подели је на свом Фејсбук профилу.',
        'nacionalna_revija': 'Србија национална ревија',
        'princip_pres': 'Принцип Прес',
        'add_new_route_1': "Додај",
        'add_new_route_2': "почетну",
        'add_new_route_3': "дестинацију",
        'add_last_destination_1': 'Додај',
        'add_last_destination_2': 'крајњу',
        'add_last_destination_3': 'дестинацију',
        'add_additional_route_1': 'Додај',
        'add_additional_route_2': 'успутне',
        'add_additional_route_3': 'дестинације',
        'save_route': 'Сачувај руту',
        'contact': 'Контакт',
        'beograd': 'Београд',
        'phone': 'Тел',
        'delete_route': 'Обриши руту',
        'route_name': 'Име руте',
        'description': 'Опис',
        'not_found': 'Нема резултата',
        'copyright': 'Copyright © 2022 Принцип Прес и Мишо Вујовић',
        'move_on': 'Покрени се, истражи!',
        'about_us_body_one': 'Идеја пројекта MeetSerbia је замишљена као интерактивна онлајн база података која обједињује све туристичке потенцијале Србије.',
        'about_us_body_two': 'Циљ портала meetserbia.rs је представљање укупне туристичке понуде ради развитка и популаризације домаћег туризма и туристичког производа.',
        'about_us_body_three': 'За 20 година бићеш више разочаран стварима које ниси урадио него онима које јеси.',
        'about_us_body_four': 'Исплови зато из своје сигурне луке, откривај, сањај и истражуј.',
        'zoom_explore': 'Жао нам је!\n' +
            'На овом делу мапе\n' +
            'нема\n' +
            'мапираних локалитета...',
        'password_mismatch': 'Погрешна лозинка',
        'user_not_exist' :  'Корисник не постоји',
        'username_exist' : 'Корисничко име постоји.',
        'email_exist': 'Е-маил је већ регистрован.',
        'password_must_match': 'Лозинке се не подударају.',
        'contact_us': 'Мапирајте свој објекат',
        'become_part_of_us': 'Постаните део понуде',
        'send': 'Пошаљи',
        'location': 'Локација',
        'feedback_contact': 'Хвала Вам на поверењу. Одговорићемо Вам у најкраћем року.',
        'email_is_required': 'Е-маил је обавезан',
        'location_is_required': 'Локација је обавезна',
        'description_is_required': 'Опис је обавезан',
        'accessible': 'Приступачно',
        'password_minimum': 'Лозинка мора бити дужа од 8 карактера',
        'email_validation': 'Упиши исправан е-маил',
        'reset_password':  'Ресетуј лозинку',
        'email_not_exist': 'Е-маил не постоји.',
        'user_not_verified': 'Корисник није верификован',
        'user_required': 'Корисничко име или е-маил је обавезно поље',
        'forgot_password': 'Заборавили сте лозинку?',
        'username_required': 'Корисничко име је обавезно поље',
        'verified_email': 'Провери е-маил',
        'resend_verification': 'Поново пошаљи верификацију',
        'lost_password_email': 'Унеси е-маил изгубљене лозинке',
        'please_check': 'Провери е-маил',
        'please_check_email': 'како би ресетовао лозинку.',
        'new_password_confirm': 'Потврда нове лозинке',
        'enter_new_password': 'Упиши нову лозинку',
        'password_change_success': 'Лозинка је успешно промењена',
        'not_robot': 'Нисам робот',
        'logout_success': 'Успешно сте се излоговали',
        'ministarstvo_rad': 'Министарство за рад, запошљавање, борачка и социјална питања',
        'ministarstvo_trgovine': 'Министарство трговине, туризма и телекомуникација',
        'opstina_ruma': 'Општина Рума',
        'opstina_vrnjacka_banja': 'Општина Врњачка Бања',
        'route_distance': 'Дужина руте',
        'old_password_did_not_matched': 'Стара лозинка није исправна.',
        'minimum_username_character': 'Минималан број карактера је 3',
        'password_march': 'Лозинке морају да се подударају',
        'avatar_upload': 'Аватар је успешно сачуван',
        "maximum_number_filter": 'Максималан број додатих филтера је 10',
        'ministarstvo_kulture': 'Министарство културе и информација',
        'successfully_changed_pass': 'Успешно сте променили лозинку. уживајте у апликацији meetserbia.rs',
        'enter_valid_email': 'Унесите исправан е-маил',
        'portfolio_header': '„Принцип Прес” – портфолио',
        'portfolio_body_1_bold': 'Концепт: ',
        'portfolio_body_1': 'Београдска издавачка кућа „Принцип Прес” је један од несумњивих\n' +
            'лидера српског издаваштва у области неговања националне културне и\n' +
            'духовне баштине, представљања туристичке понуде, поправљања имиџа и\n' +
            'ребрендирања Србије. Својом укупном издавачком продукцијом, на српском\n' +
            'и више светских језика, врхунски опремљено и дизајнирано, „Принцип Прес”\n' +
            'доследно представља лепо лице Србије. Афирмише оно што Србе чини\n' +
            'народом међу народима у европској породици. То су издања којима се на\n' +
            'компетентан и упечатљив начин помаже добронамерним странцима да на\n' +
            'прави начин открију веродостојну Србију, да је боље разумеју и присније\n' +
            'доживе, да кроз њу путују и искористе њене велике могућности.\n' +
            'Истовремено, то су наслови који српске читаоце подстичу да непрестано\n' +
            'изнова откривају привлачност сопствене земље, њене можда заборављене\n' +
            'чари и предности, да обнове дар радозналости и способност позитивних\n' +
            'препознавања, да се с поносом учврсте у сопственом идентитету.',
        'portfolio_body_2_bold': 'Издања: ',
        'portfolio_body_2': 'Такву своју базичну идеју и концепт „Принцип Прес” реализује у\n' +
            'широком спектру облика, од репрезентативног часописа „СРБИЈА –\n' +
            'Национална ревија” који излази у одвојеним издањима на српском и\n' +
            'енглеском језику, затим получасовне емисије „Од злата јабука”, апликације\n' +
            '„Meet Serbia”, преко својих веб-портала, компакт дискова, спотова и\n' +
            'филмова, до изузетних књига као што су „Србија, од злата јабука”, „Србија\n' +
            'под капом небеском”, „Србија – друмовима, пругама, рекама”, „Сто добрих\n' +
            'разлога да посетите Србију”, „Нечујна звона – хришћанско наслеђе Косова и\n' +
            'Метохије”, „Бој изнад векова-Кумановска битка“, „Чувар светих хумки“, „Гуча\n' +
            '– народни карневал српског џеза”, „Бели анђео – Туристичка библија Србије”,\n' +
            '„Тло Србије – завичај римских царева”, „Европски путеви културе – Коридор\n' +
            '10”...',
        'portfolio_body_3_bold': 'Цитат: ',
        'portfolio_body_3': '„Србија и њена култура се у издањима ,Принцип Преса’ отварају\n' +
            'пред нама у свој својој величини, лепоти и тананости... Препоручујем да\n' +
            '\n' +
            'погледате сва дивотна лица, светлости и бљескове Србије у монографији\n' +
            ',Србија, од злата јабука’ (на српском, енглеском, руском и италијанском\n' +
            'језику). Затим, велики илустровани темат о историји и култури Србије у\n' +
            'монографском водичу ,Србија – друмовима, пругама, рекама’, тој правој\n' +
            'малој енциклопедији српске културе датој кроз идеју путовања Србијом.\n' +
            'Монографија ,Бели анђео – туристичка библија Србије’, заправо једна\n' +
            'интелигентна панорама српске културе и баштине, сва је прожета Србијом и\n' +
            'њеном културом, а надахнути текст ,Брег за размишљање’ спада међу\n' +
            'најбоље икада написане о српској престоници...” (Проф. др Драган\n' +
            'Недељковић, редовни члан Европске академије наука, уметности и\n' +
            'књижевности, Париз, Француска)',
        'portfolio_body_4_bold': 'Награде: ',
        'portfolio_body_4': 'Поред великог броја пробирљивих читалаца у земљи и\n' +
            'иностранству, о вредности издања и пројеката „Принцип Преса” сведоче и\n' +
            'бројне угледне награде којима су овенчана, од „Златног Беочуга” за 2008.\n' +
            'годину која се односи на трајни допринос култури Београда, Награде\n' +
            '„Моштаница” за Издавачки подухват године на Међународном сајму књига у\n' +
            'Бањалуци 2010, „Туристичког цвета” за 2006, „Најбоље туристичке\n' +
            'публикације” за 2007, награде „Ступље” на Међународном сајму књига у\n' +
            'Бањалуци 2007. и 2008, до „Сребрног печата 2006” на Сајму графике у\n' +
            'Београду (за дизајн и оригинално графичко решење) и вишеструке награде\n' +
            '„Гутемберг”.',
        'cloud_tutorial_1': 'Креирај руту',
        'cloud_tutorial_2': 'Претражи руту по категоријама',
        'cloud_tutorial_3': 'Зумирај истражи',
        'driving': 'Вожња',
        'walking': 'Ходање',
        'cycling': 'Вожња бициклом',
        'route_length': 'Дужина руте',
        'avoid_tolls': 'Избегните путарину',
        'impresum': 'Импресум',
        'impresum_header': 'INTERNET PORTAL “MEETSERBIA”',
        'impresum_header_1': 'Издавач',
        'impresum_info_1': '„Принцип Прес”',
        'impresum_info_2': 'Цетињска 6, 11103 Београд',
        'impresum_info_3': 'Тел. + 381 (11) 322 70 34',
        'impresum_info_4': 'princip.press@gmail.com',
        'impresum_header_2': 'За издавача',
        'impresum_info_5': 'Мишо Вујовић,',
        'impresum_info_6': 'директор и главни уредник',
        'impresum_info_7': 'miso@nacionalnarevija.com',
        'impresum_header_3': 'Унос података',
        'impresum_info_8': 'Александар Ћосић',
        'impresum_info_9': 'princip.dtp@gmail.com',
        'impresum_header_4': 'Уредник фотографије',
        'impresum_info_10': 'Драган Боснић',
        'impresum_header_5': 'Маркетинг',
        'impresum_info_11': 'Мирко Вујовић',
        'impresum_info_12': 'mirko@nacionalnarevija.com',
        'impresum_header_6': 'Секретаријат',
        'impresum_info_13': 'Миленко Василић',
        'impresum_info_14': 'Драгана Димитријевић',
        'impresum_info_15': 'dragana.dimitrijevic@nacionalnarevija.com',
        'impresum_header_7': 'Регистар мeдија Републике Србије',
        'impresum_info_16': 'Регистарски број: IN001148',
        'register_agreement_1': 'Притиском на дугме региструј се слажем се са ',
        'register_agreement_2': 'Услови коришћења ',
        'register_agreement_3': 'и ',
        'register_agreement_4': 'Политика приватности ',
        'register_agreement_5': 'и да имам најмање 18 година.',
        'terms_of_use': 'Услови коришћења',
        'privacy_policy': 'Политика приватности',
        'opstina_irig': 'Општина Ириг'
    }
};