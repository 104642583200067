import React, {useContext} from "react";
import BurgerButton from "./BurgerButton/BurgerButton";
import Logo from "./Logo/Logo";
import Button from "../../UI/Button/Button";
import Search from "../../../containers/Search/Search";
import LanguageMenu from "./LanguageMenu/LanguageMenu";
import AccountNavigation from "./AccountNavigation/AccountNavigation";
import translate from "../../../i18n/translate";
import Spinner from "../../UI/Spinner/Spinner";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {AppContext} from "../../../context/index";
import messages from "../../../i18n/messages";

const Header = props => {
    const {
        isLoading,
        isRouterOpen,
        addRouteToHeader
    } = props;
    const {state} = useContext(AppContext);
    const routeShowHandler = () => {
        if(!isRouterOpen) {
            addRouteToHeader(messages[state.siteLang]['route']);
        }
    };

    return <header className="header-wrapper">
        <div className="header-group left">
            <BurgerButton/>
            <Logo/>
            <Button
                clicked={routeShowHandler}
                isRouterOpen={isRouterOpen}
            >
                {translate('new_route')}
            </Button>
            <Search/>
            {isLoading && <Spinner/>}
        </div>
        <div className="header-group right">
            <LanguageMenu/>
            <AccountNavigation/>
        </div>
    </header>;
};

const mapStateToProps = state => {
    return {
        isLoading: state.reducer.isLoading,
        isRouterOpen: state.filter.isRouterOpen
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addRouteToHeader: routeName => dispatch(actions.addRouteToHeader(routeName))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);