import React from "react";

const PopupContainer = props => {
    return <div className='popup-container'>
        <div className={`popup-content ${props.class}`}>
            {props.children}
        </div>
    </div>
}

export default PopupContainer;