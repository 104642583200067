import React from "react";
import translate from "../../../i18n/translate";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import * as actionTypes from "../../../store/actions/actionTypes";
import * as actions from "../../../store/actions";
import SimpleBarReact from 'simplebar-react';

const BurgerMenu = props => {
    const {
        isAuthenticated,
        isBurgerMenuOpen,
        onBurgerClose,
        onTutorialOpen,
        onLogOut,
        closed,
        setAlertMessage
    } = props;
    let attachedClasses = ['side-menu', 'close'];
    if(isBurgerMenuOpen) {
        attachedClasses = ['side-menu', 'open'];
    }
    const burgerCloseHandler = () => {
        onBurgerClose();
    };
    const tutorialHandler = () => {
        onTutorialOpen();
    };
    const logOutHandler = () => {
        setAlertMessage('logout_success', 'alert_success');
        onLogOut();
    };
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    return <div className={attachedClasses.join(' ')} onClose={closed}>
        <SimpleBarReact
            style={{
                height: vh
            }}
        >
            <img src={require('../../../assets/img/001.png')} alt='meetserbia'/>
            <nav>
                <ul>
                    <li>
                        <Link
                            to="/impresum"
                            onClick={burgerCloseHandler}
                        >
                            <div className="sprite-bg web-icon"/>
                            <span>{translate('impresum')}</span>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link*/}
                    {/*        to="/recommendations"*/}
                    {/*        onClick={burgerCloseHandler}*/}
                    {/*    >*/}
                    {/*        <div className="sprite-bg like-icon"/>*/}
                    {/*        <span>{translate('recommendation')}</span>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link*/}
                    {/*        to="/guides"*/}
                    {/*        onClick={burgerCloseHandler}*/}
                    {/*    >*/}
                    {/*        <div className="sprite-bg guide-icon"/>*/}
                    {/*        <span>{translate('guides')}</span>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="https://blog.meetserbia.rs" target="_blank" rel="noopener noreferrer">*/}
                    {/*        <div className="sprite-bg web-icon"/>*/}
                    {/*        <span>{translate('meet_serbia_blog')}</span>*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    <li>
                        <a href="http://nacionalnarevija.com/" target="_blank" rel="noopener noreferrer">
                            <div className="sprite-bg web-icon"/>
                            <span>{translate('nacionalna_revija')}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://turistinfosrbija.com/" target="_blank" rel="noopener noreferrer">
                            <div className="sprite-bg web-icon"/>
                            <span>{translate('princip_pres')}</span>
                        </a>
                    </li>
                    <li>
                        <Link
                            to="/about-us"
                            onClick={burgerCloseHandler}
                        >
                            <div className="sprite-bg ms-icon"/>
                            <span>{translate('about_us')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/"
                            onClick={tutorialHandler}
                        >
                            <div className="sprite-bg tutorial-icon"/>
                            <span>{translate('tutorial')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/contact-us"
                            onClick={burgerCloseHandler}
                        >
                            <div className="sprite-bg email-icon"/>
                            <span>{translate('contact_us')}</span>
                        </Link>
                    </li>
                    <li>
                        {isAuthenticated ?
                            <Link
                                to='/'
                                onClick={logOutHandler}
                            >
                                <div className="sprite-bg login-icon"/>
                                <span>{translate('log_out')}</span>
                            </Link> :
                            <Link
                                to='/auth'
                            >
                                <div className="sprite-bg login-icon"/>
                                <span>{translate('login')}</span>
                            </Link>}
                    </li>
                </ul>
            </nav>
        </SimpleBarReact>
    </div>;
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        isBurgerMenuOpen: state.reducer.isBurgerMenuOpen
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onBurgerClose: () => dispatch({type: actionTypes.CLOSE_BURGER_MENU}),
        onTutorialOpen: () => dispatch(actions.openTutorial()),
        onLogOut: () => dispatch(actions.logout()),
        setAlertMessage: (alertMessage, alertType) => dispatch(actions.setAlertMessage(alertMessage, alertType))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu);