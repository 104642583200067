import React from 'react';
import PopupContainer from "../PopupContainer/PopupContainer";
import LogInRequired from "../../Auth/LogInRequired/LogInRequired";
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";

const LogInPopUp = props => {
    const {onModalClicked} = props;
    const closeHandler = () => {
        onModalClicked();
    };

    return <PopupContainer class='login-modal'>
        <div
            className="sprite-bg close-icon-big"
            onClick={closeHandler}
        />
        <LogInRequired/>
    </PopupContainer>;
};

const mapDispatchToProps = dispatch => {
    return {
        onModalClicked: () => dispatch({type: actionTypes.CLOSE_POPUPS_TWO})
    }
};

export default connect(null, mapDispatchToProps)(LogInPopUp);