import * as actionTypes from './actionTypes';
import axios from "axios";
import {selectProfile} from "./profile";
import {setAlertMessage} from "./auth";
const apiURL = process.env.REACT_APP_API_URL;

export const addRouteToHeader = routeName => {
    return  {
        type: actionTypes.ADD_ROUTER_TO_HEADER,
        routeName: routeName
    }
};

export const addPinToRouter = (pinName, pinId, lng, lat, img_url, category_id, category_name) => {
    return {
        type: actionTypes.ADD_PIN_TO_ROUTER,
        pinName: pinName,
        pinId: pinId,
        lng: lng,
        lat: lat,
        img_url: img_url,
        category_id: category_id,
        category_name: category_name
    }
};

export const removePinFromRouter = index => {
    return {
        type: actionTypes.DELETE_PIN_FROM_ROUTER,
        index: index
    }
};

export const deleteMapPinFromRouter = pinId => {
    return {
        type: actionTypes.DELETE_MAP_PIN_FROM_ROUTER,
        pinId: pinId
    }
};

export const changePinOrderInRoute = result => {
    return {
        type: actionTypes.CHANGE_PIN_ORDER_IN_ROUTE,
        result: result
    }
};

const saveRouteStart = () => {
    return {
        type: actionTypes.SAVE_ROUTE_START
    }
};

export const setRouteForShare = route => {
    return {
        type: actionTypes.SET_ROUTE_FOR_SHARE,
        route: route
    };
};

const saveRouteSuccess = route => {
    return {
        type: actionTypes.SAVE_ROUTE_SUCCESS,
        route: route
    };
};

export const saveRoute = (token, route, user, history, activeNavigationMode, chosenRouteIndex, tolls) => {
    return dispatch => {
        dispatch(saveRouteStart());
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        const data = {route, user, activeNavigationMode, chosenRouteIndex, tolls}
        axios.post(`${apiURL}/v1/route/create`, data, config)
            .then(response => {
                dispatch(saveRouteSuccess(response.data));
                if (history === false) {
                    dispatch(setRouteForShare(response.data.Route));
                } else {
                    history.push(`/profile`);
                    dispatch(selectProfile(0));
                }
                dispatch(setAlertMessage('Ruta je dostupna i na aplikaciji', 'alert_success'));
            }).catch(error => {});
    };
};

const getRoutesStart = () => {
    return {
        type: actionTypes.GET_ROUTES_START
    }
};

const routesSuccess = routes => {
    return {
        type: actionTypes.GET_ROUTES_SUCCESS,
        routes: routes
    }
};

export const fetchRoutes = (token, userId) => {
    return dispatch => {
        dispatch(getRoutesStart());
        const config = {
            params: {userId},
            headers: {Authorization: `Bearer ${token}`}
        }
        axios.get(`${apiURL}/v1/routes`, config )
            .then(response => {
                dispatch(routesSuccess(response.data.Routes));
            }).catch(error => {
        });
    }
};

const updateRouteInfoStart = () => {
    return {
        type: actionTypes.UPDATE_ROUTE_INFO_START
    }
};

const updateRouteInfoSuccess= () => {
    return {
        type: actionTypes.UPDATE_ROUTE_INFO_SUCCESS
    }
};

export const updateRouteInfo = (token, id, name, description, history) => {
    return dispatch => {
        dispatch(updateRouteInfoStart());
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        const data = {id, name, description};
        axios.put(`${apiURL}/v1/route`, data, config)
            .then(response => {
                dispatch(updateRouteInfoSuccess());
                history.push('/profile');
            }).catch(error => {});
    };
};

const deleteRouteStart = () => {
    return {
        type: actionTypes.DELETE_ROUTE_START
    }
};

const deleteRouteSuccess= id => {
    return {
        type: actionTypes.UPDATE_ROUTE_INFO_SUCCESS,
        id: id
    }
};

export const deleteRoute = (token, id, history) => {
    return dispatch => {
        dispatch(deleteRouteStart());
        const config = {
            params: {id},
            headers: {Authorization: `Bearer ${token}`}
        };
        axios.delete(`${apiURL}/v1/route`, config)
            .then(response => {
                history.push('/profile');
                dispatch(deleteRouteSuccess(id));
            }).catch(error => {

        });
    }
};

const getRouteStart = () => {
    return {
        type: actionTypes.GET_ROUTE_START
    }
};

const routeSuccess = (route, waypoints) => {
    return {
        type: actionTypes.GET_ROUTE_SUCCESS,
        route: route,
        waypoints: waypoints
    };
};

export const fetchRoute = (routeId, history) => {
    return dispatch => {
        dispatch(getRouteStart());
        axios.get(`${apiURL}/v1/route/${routeId}`)
            .then(response => {
                dispatch(routeSuccess(response.data[0], response.data[1]));
                history.push('/');
            }).catch(error => {});
    };
};

export const confirmRoute = id => {
    return {
        type: actionTypes.OPEN_CONFIRM_POP_UP,
        routeForDelete: id
    }
};

export const isFullGalleryOpen = isOpen => {
    return {
        type: actionTypes.IS_FULL_GALLERY_OPEN,
        isOpen: isOpen
    };
};

export const setFullGalleryPictures = fullGalleryPictures => {
    return {
        type: actionTypes.SET_FULL_GALLERY_PICTURES,
        fullGalleryPictures: fullGalleryPictures
    };
};

export const setAvoidTolls = isAvoidTolls => {
    return {
        type: actionTypes.SET_AVOID_TOLLS,
        isAvoidTolls: isAvoidTolls
    };
};