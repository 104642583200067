import React, {Fragment} from "react";
import {IntlProvider} from "react-intl";
import messages from './messages';
import {I18nProvider} from "./index";

const Provider = ({children, locale}) => {
    const onIntlError = (e) => {
        if (e.code === I18nProvider.MISSING_DATA) {}
    };

    return <IntlProvider
        locale={locale}
        textComponent={Fragment}
        messages={messages[locale]}
        onError={onIntlError}
    >
        {children}
    </IntlProvider>;
}

export default Provider;