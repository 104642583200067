import React, {useReducer, createContext, useState} from 'react';
import defaultContext from "./defaultContext";

const AppContext = createContext();

let reducer = (state, action) => {
    return {...state, siteLang: action.siteLang};
};

const AppContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, defaultContext);
    const [pinIsVisable, setPinIsVisible] = useState(false);

    const value = { state, dispatch, pinIsVisable, setPinIsVisible};

    return (
        <AppContext.Provider value={value}>{children}</AppContext.Provider>
    );
};

export {AppContext, AppContextProvider};