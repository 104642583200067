import React, {useEffect} from 'react';
import Header from '../Navigation/Header/Header';
import MapboxGLMap from '../../containers/Map/MapboxGLMap';
import '../../assets/scss/index.scss';
import BurgerMenu from '../Navigation/BurgerMenu/BurgerMenu';
import Filter from '../Filter/Filter';
import LocationPreview from "../Filter/LocationPreview/LocationPreview";
import Backdrop from "../UI/Backdrop/Backdrop";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import InitialLoading from "../UI/InitialLoading/InitialLoading";
import BackdropTwo from "../UI/Backdrop/BackdropTwo";
import RouteConfirmPopUp from "../UI/RouteConfirmPopUp/RouteConfirmPopUp";
import LogInPopUp from "../UI/LogInPopUp/LogInPopUp";
import FavoriteConfirmPopUp from "../UI/FavoriteConfirmPopUp/FavoriteConfirmPopUp";
import Tutorial from "../Tutorial/Tutorial";
import Alert from "../UI/Alert/Alert";
import {getFromStorage, saveToStorage} from "../../utils/sessionStorage";
import CloudTutorial from "../UI/CloudTutorial/CloudTutorial";
import FullGallery from "../Pin/FullGallery/FullGallery";
import InitialMtsVideo from "../UI/InitialMtsVideo/InitialMtsVideo";

const Layout = props => {
    const {
        onInitialLoadCategories,
        onInitialLoadTags,
        onFetchPins,
        initialLoading,
        isModalOpen,
        isModalTwoOpen,
        isRouteConfirm,
        isFavoriteConfirm,
        isLogin,
        isTutorial,
        alertInfo,
        cloudTutorial,
        setCloudTutorial,
        route,
        isGalleryOpen,
        mtsVideoWatched
    } = props;
    useEffect(() => {
        onInitialLoadCategories();
        onInitialLoadTags();
        onFetchPins();
    }, [onInitialLoadCategories, onInitialLoadTags, onFetchPins]);
    useEffect(() => {
        const localCloudTutorial = getFromStorage('cloudTutorial');
        if (localCloudTutorial === null) {
            saveToStorage('cloudTutorial', cloudTutorial);
        } else {
            Object.values(localCloudTutorial).map(tutorial => {
                tutorial.isTriggered = false;
                return tutorial;
            });
            if (localCloudTutorial[1].count <= 6) {
                if (localCloudTutorial[1].isTriggered === false) {
                    localCloudTutorial[1].isTriggered = true;
                    localCloudTutorial[1].visible = true;
                    localCloudTutorial[1].count = localCloudTutorial[1].count + 1;
                }
            }
            saveToStorage('cloudTutorial', localCloudTutorial);
            setCloudTutorial(localCloudTutorial);
        }
    }, []);

    return <>
        {!mtsVideoWatched && <InitialMtsVideo/>}
        {((initialLoading === true) && (mtsVideoWatched === true)) && <InitialLoading/>}
        {isModalOpen && <Backdrop/>}
        {isModalTwoOpen && <BackdropTwo/>}
        {isRouteConfirm && <RouteConfirmPopUp/>}
        {isFavoriteConfirm && <FavoriteConfirmPopUp/>}
        {isLogin && <LogInPopUp/>}
        {isTutorial && <Tutorial/>}
        {alertInfo !== null && <Alert/>}
        {isGalleryOpen && <FullGallery/>}
        <Header/>
        <BurgerMenu/>
        <Filter/>
        <LocationPreview/>
        <MapboxGLMap/>
        {props.children}
        {((initialLoading === false) && (cloudTutorial[1].count < 6) && (cloudTutorial[1].visible === true)) && <CloudTutorial
            setCloudTutorial={setCloudTutorial}
            id={1}
        />}
        {((cloudTutorial[2].count < 6) && (cloudTutorial[2].visible === true) && (route.length > 1)) && <CloudTutorial
            setCloudTutorial={setCloudTutorial}
            id={2}
        />}
        {((cloudTutorial[3].count < 6) && (cloudTutorial[3].visible === true) && (route.length > 1)) && <CloudTutorial
            setCloudTutorial={setCloudTutorial}
            id={3}
        />}
    </>;
};

const mapStateToProps = state => {
    return {
        isModalOpen: state.reducer.isModalOpen,
        initialLoading: state.filter.initialLoading,
        isModalTwoOpen: state.reducer.isModalTwoOpen,
        isRouteConfirm: state.reducer.isRouteConfirm,
        isLogin: state.auth.isLogin,
        isFavoriteConfirm: state.reducer.isFavoriteConfirm,
        isTutorial: state.reducer.isTutorial,
        alertInfo: state.auth.alertInfo,
        popUpPin: state.map.popUpPin,
        cloudTutorial: state.reducer.cloudTutorial,
        route: state.router.route,
        isGalleryOpen: state.reducer.fullGallery.open,
        mtsVideoWatched: state.filter.mtsVideoWatched
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onInitialLoadCategories: () => dispatch(actions.fetchCategory()),
        onInitialLoadTags: () => dispatch(actions.fetchTags()),
        onFetchPins: () => dispatch(actions.fetchPins()),
        setCloudTutorial: cloudTutorial => dispatch(actions.setCloudTutorial(cloudTutorial))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);