import {LOCALES} from "../locales";

export default {
    [LOCALES.SERBIAN]: {
        'yes': 'Da',
        'no': 'Ne',
        'new_route': 'NOVA RUTA',
        'search': 'Pretraži',
        'lang_short': 'SRB',
        'SERBIAN': 'Latinica',
        'CYRILIC': 'Ćirilica',
        'ENGLISH': 'Engleski',
        'profile': 'Profil',
        'favorite_locations': 'Omiljene lokacije',
        'saving_routes': 'Sačuvane rute',
        'settings': 'Podešavanja',
        'log_out': 'Izloguj se',
        'log_in': 'Uloguj se',
        'sign_up': 'Registruj se',
        'recommendation': 'Preporuke',
        'guides': 'Vodiči',
        'about_us': 'O nama',
        'tutorial': 'Uputstvo',
        'login': 'Prijavi se / Registruj se',
        'route_radius': 'Radijus oko rute',
        'infinitely': 'Bez radijusa',
        'route': 'Ruta',
        'read_more': 'Pročitaj više',
        'add_to_route': 'Dodaj u rutu',
        'remove_from_route': 'Obriši iz rute',
        'favorites': 'Omiljene lokacije',
        'username': 'Korisničko ime',
        'email': 'Email',
        'username_or_email': 'Korisničko ime ili email',
        'password': 'Lozinka',
        'password_confirmation': 'Potvrdi lozinku',
        'connect_with_facebook': 'Prijavi se preko Fejsbuk naloga',
        'back': 'Nazad',
        'share': 'Podeli',
        'edit_profile': 'Izmeni profil',
        'personal_info': 'Lične informacije',
        'change_username': 'Promeni nadimak',
        'change_password': 'Izmeni lozinku',
        'old_password': 'Stara lozinka',
        'new_password': 'Nova lozinka',
        'avatar_image': 'Profilna slika',
        'new_passowrd_confirm': 'Potvrdi novu lozinku',
        'upload_imge': 'Izmeni sliku',
        'save_changes': 'Sačuvaj izmene',
        'sign_up_text': 'Ulogujte se da sačuvate rute, lokacije i više.',
        'or': 'Ili',
        'delete_route_text': 'Da li ste sigurni da želite da obrišete rutu?',
        'delete_favorite_text': 'Da li ste sigurni da želite da obrišete omiljenu lokaciju?',
        'meet_serbia_blog': 'Meet Serbia blog',
        'all_tags': 'Svi tagovi',
        'tutorial_header': 'AVANTURA JE NA KLIK OD TEBE',
        'tutorial_body_top': 'Zumiraj, istraži. Pronađi novu destinaciju.',
        'tutorial_body_bottom': 'Napravi rutu. Podeli je na svom fejsbuk profilu.',
        'nacionalna_revija': 'Srbija nacionalna revija',
        'princip_pres': 'Princip Pres',
        'add_new_route_1': "Dodaj",
        'add_new_route_2': "početnu",
        'add_new_route_3': "destinaciju",
        'add_last_destination_1': 'Dodaj',
        'add_last_destination_2': 'krajnju',
        'add_last_destination_3': 'destinaciju',
        'add_additional_route_1': 'Dodaj',
        'add_additional_route_2': 'usputne',
        'add_additional_route_3': 'destinacije',
        'save_route': 'Sačuvaj rutu',
        'contact': 'Kontakt',
        'beograd': 'Beograd',
        'phone': 'Tel',
        'delete_route': 'Obriši rutu',
        'route_name': 'Ime rute',
        'description': 'Opis',
        'not_found': 'Nema rezultata',
        'copyright': 'Copyright © 2022 Princip Press i Mišo Vujović',
        'move_on': 'Pokreni se, istraži!',
        'about_us_body_one': 'Ideja projekta MeetSerbia je zamišljena kao interaktivna onlajn baza podataka koja objedinjuje sve turističke potencijale Srbije.',
        'about_us_body_two': 'Cilj portala meetserbia.rs je predstavljanje ukupne turističke ponude radi razvitka i popularizacije domaćeg turizma i turističkog proizvoda.',
        'about_us_body_three': 'Za 20 godina bićeš više razočaran stvarima koje nisi uradio nego onima koje jesi.',
        'about_us_body_four': 'Isplovi zato iz svoje sigurne luke, otkrivaj, sanjaj i istražuj.',
        'zoom_explore': 'Žao nam je!\n' +
            'Na ovom delu mape\n' +
            'nema mapiranih lokaliteta...',
        'password_mismatch': 'Pogrešna lozinka',
        'user_not_exist' :  'Korisnik ne postoji',
        'username_exist' : 'Korisničko ime postoji.',
        'email_exist': 'Email je već registrovan.',
        'password_must_match': 'Lozinke se ne podudaraju.',
        'contact_us': 'Mapirajte svoj objekat',
        'become_part_of_us': 'Postanite deo ponude',
        'send': 'Pošalji',
        'location': 'Lokacija',
        'feedback_contact': 'Hvala Vam na poverenju. Odgovorićemo Vam u najkraćem roku.',
        'email_is_required': 'Email je obavezan',
        'location_is_required': 'Lokacija je obavezna',
        'description_is_required': 'opis je obavezan',
        'accessible': 'Pristupačno',
        'password_minimum': 'Lozinka mora biti duža od 8 karaktera',
        'email_validation': 'Upiši ispravan email',
        'reset_password':  'Resetuj lozinku',
        'email_not_exist': 'Email ne postoji.',
        'user_not_verified': 'Korisnik nije verifikovan',
        'user_required': 'Korisničko ime ili email je obavezno polje',
        'forgot_password': 'Zaboravili ste lozinku?',
        'username_required': 'Korisničko ime je obavezno polje',
        'verified_email': 'Proveri email',
        'resend_verification': 'Ponovo pošalji verifikaciju',
        'lost_password_email': 'Unesi email izgubljene lozinke',
        'please_check': 'Proveri email',
        'please_check_email': 'kako bi resetovao lozinku.',
        'new_password_confirm': 'Potvrda nove lozinke',
        'enter_new_password': 'Upiši novu lozinku',
        'password_change_success': 'Lozinka je uspesno promenjena',
        'not_robot': 'Nisam robot',
        'logout_success': 'Uspešno ste se izlogovali',
        'ministarstvo_rad': 'Ministarstvo za rad, zapošljavalje, boračka i socijalna pitanja',
        'ministarstvo_trgovine': 'Ministarstvo trgovine, turizma i telekomunikacija',
        'opstina_ruma': 'Opština Ruma',
        'opstina_vrnjacka_banja': 'Opština Vrnjačka Banja',
        'route_distance': 'Dužina rute',
        'old_password_did_not_matched': 'Stara lozinka nije ispravna.',
        'minimum_username_character': 'Minimalan broj karaktera je 3',
        'password_march': 'Lozinke moraju da se podudaraju',
        'avatar_upload': 'Avatar je uspešno sačuvan',
        "maximum_number_filter": 'Maksimalan broj dodatih filtera je 10',
        'ministarstvo_kulture': 'Ministarstvo kulture i informacija',
        'successfully_changed_pass': 'Uspešno ste promenili lozinku. Uživajte u aplikaciji meetserbia.rs',
        'enter_valid_email': 'Unesite ispravan email',
        'portfolio_header': '„Princip Pres” – portfolio',
        'portfolio_body_1_bold': 'Кoncept: ',
        'portfolio_body_1': 'Beogradska izdavačka kuća „Princip Pres” je jedan od nesumnjivih\n' +
            'lidera srpskog izdavaštva u oblasti negovanja nacionalne kulturne i duhovne\n' +
            'baštine, predstavljanja turističke ponude, popravljanja imidža i rebrendiranja\n' +
            'Srbije. Svojom ukupnom izdavačkom produkcijom, na srpskom i više svetskih\n' +
            'jezika, vrhunski opremljeno i dizajnirano, „Princip Pres” dosledno predstavlja lepo\n' +
            'lice Srbije. Afirmiše ono što Srbe čini narodom među narodima u evropskoj\n' +
            'porodici. To su izdanja kojima se na kompetentan i upečatljiv način pomaže\n' +
            'dobronamernim strancima da na pravi način otkriju verodostojnu Srbiju, da je bolje\n' +
            'razumeju i prisnije dožive, da kroz nju putuju i iskoriste njene velike mogućnosti.\n' +
            'Istovremeno, to su naslovi koji srpske čitaoce podstiču da neprestano iznova\n' +
            'otkrivaju privlačnost sopstvene zemlje, njene možda zaboravljene čari i prednosti,\n' +
            'da obnove dar radoznalosti i sposobnost pozitivnih prepoznavanja, da se s\n' +
            'ponosom učvrste u sopstvenom identitetu.',
        'portfolio_body_2_bold': 'Izdanja: ',
        'portfolio_body_2': 'Takvu svoju bazičnu ideju i koncept „Princip Pres” realizuje u širokom\n' +
            'spektru oblika, od reprezentativnog časopisa „SRBIJA – Nacionalna revija” koji\n' +
            'izlazi u odvojenim izdanjima na srpskom i engleskom jeziku, zatim polučasovne\n' +
            'emisije „Od zlata jabuka”, aplikacije „Meet Serbia”, preko svojih veb-portala,\n' +
            'kompakt diskova, spotova i filmova, do izuzetnih knjiga kao što su „Srbija, od zlata\n' +
            'jabuka”, „Srbija pod kapom nebeskom”, „Srbija – drumovima, prugama, rekama”,\n' +
            '„Sto dobrih razloga da posetite Srbiju”, „Nečujna zvona – hrišćansko nasleđe\n' +
            'Кosova i Metohije”, „Boj iznad vekova-Кumanovska bitka“, „Čuvar svetih humki“,\n' +
            '„Guča – narodni karneval srpskog džeza”, „Beli anđeo – Turistička biblija Srbije”,\n' +
            '„Tlo Srbije – zavičaj rimskih careva”, „Evropski putevi kulture – Кoridor 10”...',
        'portfolio_body_3_bold': 'Citat: ',
        'portfolio_body_3': '„Srbija i njena kultura se u izdanjima ,Princip Presa’ otvaraju pred nama u\n' +
            'svoj svojoj veličini, lepoti i tananosti... Preporučujem da pogledate sva divotna lica,\n' +
            'svetlosti i bljeskove Srbije u monografiji ,Srbija, od zlata jabuka’ (na srpskom,\n' +
            'engleskom, ruskom i italijanskom jeziku). Zatim, veliki ilustrovani temat o istoriji i\n' +
            'kulturi Srbije u monografskom vodiču ,Srbija – drumovima, prugama, rekama’, toj\n' +
            'pravoj maloj enciklopediji srpske kulture datoj kroz ideju putovanja Srbijom.\n' +
            'Monografija ,Beli anđeo – turistička biblija Srbije’, zapravo jedna inteligentna\n' +
            'panorama srpske kulture i baštine, sva je prožeta Srbijom i njenom kulturom, a\n' +
            'nadahnuti tekst ,Breg za razmišljanje’ spada među najbolje ikada napisane o\n' +
            'srpskoj prestonici...” (Prof. dr Dragan Nedeljković, redovni član Evropske\n' +
            '\n' +
            'akademije nauka, umetnosti i književnosti, Pariz, Francuska)',
        'portfolio_body_4_bold': 'Nagrade: ',
        'portfolio_body_4': 'Pored velikog broja probirljivih čitalaca u zemlji i inostranstvu, o\n' +
            'vrednosti izdanja i projekata „Princip Presa” svedoče i brojne ugledne nagrade\n' +
            'kojima su ovenčana, od „Zlatnog Beočuga” za 2008. godinu koja se odnosi na\n' +
            'trajni doprinos kulturi Beograda, Nagrade „Moštanica” za Izdavački poduhvat\n' +
            'godine na Međunarodnom sajmu knjiga u Banjaluci 2010, „Turističkog cveta” za\n' +
            '2006, „Najbolje turističke publikacije” za 2007, nagrade „Stuplje” na\n' +
            'Međunarodnom sajmu knjiga u Banjaluci 2007. i 2008, do „Srebrnog pečata 2006”\n' +
            'na Sajmu grafike u Beogradu (za dizajn i originalno grafičko rešenje) i višestruke\n' +
            'nagrade „Gutemberg”.',
        'cloud_tutorial_1': 'Kreiraj rutu',
        'cloud_tutorial_2': 'Pretraži rutu po kategorijama',
        'cloud_tutorial_3': 'Zumiraj istraži',
        'driving': 'Vožnja',
        'walking': 'Hodanje',
        'cycling': 'Vožnja biciklom',
        'route_length': 'Dužina Rute',
        'avoid_tolls': 'Izbegnite putarinu',
        'impresum': 'Impresum',
        'impresum_header': 'INTERNET PORTAL “MEETSERBIA”',
        'impresum_header_1': 'Izdavač',
        'impresum_info_1': '„Princip Pres”',
        'impresum_info_2': 'Cetinjska 6, 11103 Beograd',
        'impresum_info_3': 'Tel. + 381 (11) 322 70 34',
        'impresum_info_4': 'princip.press@gmail.com',
        'impresum_header_2': 'Za izdavača',
        'impresum_info_5': 'Mišo Vujović,',
        'impresum_info_6': 'direktor i glavni urednik',
        'impresum_info_7': 'miso@nacionalnarevija.com',
        'impresum_header_3': 'Unos podataka',
        'impresum_info_8': 'Aleksandar Ćosić',
        'impresum_info_9': 'princip.dtp@gmail.com',
        'impresum_header_4': 'Urednik fotografije',
        'impresum_info_10': 'Dragan Bosnić',
        'impresum_header_5': 'Marketing',
        'impresum_info_11': 'Mirko Vujović',
        'impresum_info_12': 'mirko@nacionalnarevija.com',
        'impresum_header_6': 'Sekretarijat',
        'impresum_info_13': 'Milenko Vasilić',
        'impresum_info_14': 'Dragana Dimitrijević',
        'impresum_info_15': 'dragana.dimitrijevic@nacionalnarevija.com',
        'impresum_header_7': 'Registar medija Republike Srbije',
        'impresum_info_16': 'Registarski broj: IN001148',
        'register_agreement_1': 'Pritiskom na dugme registruj se  slažem se sa ',
        'register_agreement_2': 'Uslovima korišćenja ',
        'register_agreement_3': 'i ',
        'register_agreement_4': 'Politikom privatnosti ',
        'register_agreement_5': 'i da imam najmanje 18 godina.',
        'terms_of_use': 'Uslovi korišćenja',
        'privacy_policy': 'Politika privatnosti',
        'opstina_irig': 'Opština Irig'
    }
};