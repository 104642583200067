import React, {useEffect} from 'react';
import {connect} from "react-redux";
import InfoForRouter from "./InfoForRouter/InfoForRouter";
import RouterListItems from "./RouterListItems/RouterListItems";
import Search from "../../containers/Search/Search";
import {saveToStorage} from "../../utils/sessionStorage";
import * as actions from "../../store/actions";
import {updateObject} from "../../shared/utility";

const RouteTrip = props => {
    const {
        height,
        route,
        setCloudTutorial,
        cloudTutorial
    } = props;
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const LocationHeight = vh-393;
    useEffect(() => {
        if (cloudTutorial[2].isTriggered === false) {
            const tmpCloudTutorial = updateObject(cloudTutorial, {
                2: updateObject(cloudTutorial[2], {
                    visible: true,
                    isTriggered: true,
                    count: cloudTutorial[2].count + 1
                })
            });
            saveToStorage('cloudTutorial', tmpCloudTutorial);
            setCloudTutorial(tmpCloudTutorial);
        }
    }, []);
    useEffect(() => {
        if (route.length > 1) {
            if (cloudTutorial[3].isTriggered === false) {
                const tmpCloudTutorial = updateObject(cloudTutorial, {
                    3: updateObject(cloudTutorial[3], {
                        visible: true,
                        isTriggered: true,
                        count: cloudTutorial[3].count + 1
                    }),
                    1: updateObject(cloudTutorial[1], {
                        visible: false
                    })
                });
                saveToStorage('cloudTutorial', tmpCloudTutorial);
                setCloudTutorial(tmpCloudTutorial);
            }
        }
    }, [route]);

    return <div className="route-content" style={{height: height}}>
        <img src={require('../../assets/img/004.png')} alt='meetserbia' className="route-header-image" />
        <div className="route-wrapper">
            <Search whatSearch={'route'}/>
            <div className="route-overlay" style={{height: LocationHeight}}>
                {route.length === 0 ? <InfoForRouter route={route}/> : <RouterListItems/>}
            </div>
        </div>
    </div>;
};

const mapStateToProps = state => {
    return {
        route: state.router.route,
        cloudTutorial: state.reducer.cloudTutorial
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setCloudTutorial: cloudTutorial => dispatch(actions.setCloudTutorial(cloudTutorial))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteTrip);