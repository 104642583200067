import React from 'react';
import translate from "../../../i18n/translate";
import {getFromStorage, saveToStorage} from "../../../utils/sessionStorage";

const CloudTutorial = props => {
    const {
        setCloudTutorial,
        id
    } = props;
    let cloudWrapperElement;
    const closeTutorialHandler = () => {
        const cloudTutorial = getFromStorage('cloudTutorial');
        cloudTutorial[id].visible = false;
        saveToStorage('cloudTutorial', cloudTutorial);
        setCloudTutorial(cloudTutorial);
    };
    switch (id) {
        case 1:
            cloudWrapperElement = <div className='cloud-wrapper tutorial-1'>
                <img src={require('../../../assets/img/015.png')} alt='meetserbia'/>
                <div
                    className='sprite-bg cloud-tutorial-close'
                    onClick={closeTutorialHandler}
                />
                <span>{translate('cloud_tutorial_1')}</span>
            </div>;
            break;
        case 2:
            cloudWrapperElement = <div className='cloud-wrapper tutorial-2'>
                <img src={require('../../../assets/img/016.png')} alt='meetserbia'/>
                <div
                    className='sprite-bg cloud-tutorial-close'
                    onClick={closeTutorialHandler}
                />
                <span>{translate('cloud_tutorial_2')}</span>
            </div>;
            break;
        case 3:
            cloudWrapperElement = <div className='cloud-wrapper tutorial-3'>
                <img src={require('../../../assets/img/017.png')} alt='meetserbia'/>
                <div
                    className='sprite-bg cloud-tutorial-close'
                    onClick={closeTutorialHandler}
                />
                <span>{translate('cloud_tutorial_3')}</span>
            </div>;
            break;
        default:
            break;
    }

    return <>{cloudWrapperElement}</>;
};

export default CloudTutorial;