import * as actionTypes from './actionTypes';
import {getFromStorage} from "../../utils/sessionStorage";
import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;
const locale = getFromStorage('siteLangId');
let worker, worker2, worker4, worker5, worker6, worker7;
window.addEventListener('load', () => {
    worker = new Worker(`${process.env.PUBLIC_URL}/worker.js`);
    worker2 = new Worker(`${process.env.PUBLIC_URL}/worker2.js`);
    worker4 = new Worker(`${process.env.PUBLIC_URL}/worker4.js`);
    worker5 = new Worker(`${process.env.PUBLIC_URL}/worker5.js`);
    worker6 = new Worker(`${process.env.PUBLIC_URL}/worker6.js`);
    worker7 = new Worker(`${process.env.PUBLIC_URL}/worker7.js`);
});

const fetchCategoriesStart = () => {
    return {
        type: actionTypes.FETCH_CATEGORIES_START
    }
};

const setCategory = categories => {
    return {
        type: actionTypes.FETCH_CATEGORIES_SUCCESS,
        categories: categories
    }
};

export const fetchCategory = () => {
    return dispatch => {
        dispatch(fetchCategoriesStart());
        axios.get(`${apiURL}/v1/categories/${locale}`)
            .then(response => {
                dispatch(setCategory(response.data));
            });
    }
};

const fetchTagsStart = () => {
    return {
        type: actionTypes.FETCH_TAGS_START
    }
};

const setTags = tags => {
    return {
        type: actionTypes.FETCH_TAGS_SUCCESS,
        tags: tags
    }
};

export const fetchTags = () => {
    return dispatch => {
        dispatch(fetchTagsStart());
        axios.get(`${apiURL}/v1/tags/${locale}`)
            .then(response => {
                dispatch(setTags(response.data));
            });
    }
};

const getPinsStart = () => {
    return {
        type: actionTypes.GET_ALL_PINS_START
    }
};

const setPins = pins => {
    return {
        type: actionTypes.GET_ALL_PINS_SUCCESS,
        pins: pins
    }
};

export const fetchPins = () => {
    return dispatch => {
        dispatch(getPinsStart());
        axios.get(`${apiURL}/v1/pins/${locale}/30`)
            .then(response => {
                dispatch(setPins(response.data));
            });
    }
};

const createFilterState = (categoriesListTop, categoriesListBottom, activeCategories, listPinsLocation, filtredPinsForMap, addedUniq, categoryId, categoryName) => {
    return {
        type: actionTypes.ADD_CATEGORY_LIST,
        categoriesListTop: categoriesListTop,
        categoriesListBottom: categoriesListBottom,
        activeCategories: activeCategories,
        listPinsLocation: listPinsLocation,
        filtredPinsForMap: filtredPinsForMap,
        addedUniq: addedUniq,
        categoryId: categoryId,
        categoryName: categoryName
    }
};

export const addCategory = (categoryId, categoryName, categoriesListTop, categoriesListBottom, tags, activeCategories, initialPins, favorites) => {
    return dispatch => {
        worker.postMessage({
            categoryId: categoryId,
            categoryName: categoryName,
            categoriesListTop: JSON.parse(JSON.stringify(categoriesListTop)),
            categoriesListBottom: JSON.parse(JSON.stringify(categoriesListBottom)),
            tags: JSON.parse(JSON.stringify(tags)),
            activeCategories: JSON.parse(JSON.stringify(activeCategories)),
            initialPins: JSON.parse(JSON.stringify(initialPins)),
            favorites: JSON.parse(JSON.stringify(favorites)),
        });

        worker.onmessage = function (e) {
            const categoriesListTop = e.data.categoriesListTop;
            const categoriesListBottom = e.data.categoriesListBottom;
            const activeCategories = e.data.activeCategories;
            const listPinsLocation = e.data.listPinsLocation;
            const filtredPinsForMap = e.data.filtredPinsForMap;
            const addedUniq = e.data.addedUniq;
            dispatch(createFilterState(categoriesListTop, categoriesListBottom, activeCategories, listPinsLocation, filtredPinsForMap, addedUniq, categoryId, categoryName));
        }
    }
};

const createDeleteState = (categoryId, categoriesListTop, categoriesListBottom, activeCategories, deletedUniq, lastActiveCategory, listPinsLocation) => {
    return {
        type: actionTypes.DELETE_CATEGORY_LIST,
        categoryId: categoryId,
        categoriesListTop: categoriesListTop,
        categoriesListBottom: categoriesListBottom,
        activeCategories: activeCategories,
        deletedUniq: deletedUniq,
        lastActiveCategory: lastActiveCategory,
        listPinsLocation: listPinsLocation
    }
};

export const deleteCategory = (categoryId, categoriesListTop, categoriesListBottom, activeCategories, lastActiveCategory, initialPins) => {
    return dispatch => {
        worker2.postMessage({
            categoryId: categoryId,
            categoriesListTop: categoriesListTop,
            categoriesListBottom: categoriesListBottom,
            activeCategories: activeCategories,
            lastActiveCategory: lastActiveCategory,
            initialPins: initialPins
        });

        worker2.onmessage = function (e) {
            const categoriesListTop = e.data.categoriesListTop;
            const categoriesListBottom = e.data.categoriesListBottom;
            const activeCategories = e.data.activeCategories;
            const deletedUniq = e.data.deletedUniq;
            const lastActiveCategory = e.data.lastActiveCategory;
            const listPinsLocation =e.data.listPinsLocation;
            dispatch(createDeleteState(categoryId, categoriesListTop, categoriesListBottom, activeCategories, deletedUniq, lastActiveCategory, listPinsLocation))
        }
    }
};

const dispatchAddTag = (tagId, categoriesListTop, categoriesListBottom, filtredPinsForMap, activeCategories) => {
    return {
        type: actionTypes.ADD_TAG_LIST,
        tagId: tagId,
        categoriesListTop: categoriesListTop,
        categoriesListBottom: categoriesListBottom,
        filtredPinsForMap: filtredPinsForMap,
        activeCategories: activeCategories
    }
};

export const addTag = (tagId, categoryId, tags, activeCategories, categoriesListTop, categoriesListBottom, initialPins, favorites) => {
    return dispatch => {
        worker5.postMessage({
            tagId: tagId,
            categoryId: categoryId,
            tags: tags,
            activeCategories: activeCategories,
            categoriesListTop: categoriesListTop,
            categoriesListBottom: categoriesListBottom,
            initialPins: initialPins,
            favorites: favorites
        });

        worker5.onmessage = function (e) {
            const tagId = e.data.tagId;
            const categoriesListTop = e.data.categoriesListTop;
            const categoriesListBottom = e.data.categoriesListBottom;
            const filtredPinsForMap = e.data.filtredPinsForMap;
            const activeCategories = e.data.activeCategories;
            dispatch(dispatchAddTag(tagId, categoriesListTop, categoriesListBottom, filtredPinsForMap, activeCategories))
        }
    }
};

const dispatchDeleteTag = (activeCategories, filtredPinsForMap, categoriesListTop, categoriesListBottom) => {
    return {
        type: actionTypes.DELETE_TAG_LIST,
        activeCategories: activeCategories,
        filtredPinsForMap: filtredPinsForMap,
        categoriesListTop: categoriesListTop,
        categoriesListBottom: categoriesListBottom
    }
};

export const deleteTag = (tagId, categoryId, activeCategories, tags, initialPins, favorites, categoriesListTop, categoriesListBottom) => {
    return dispatch => {
        worker6.postMessage({
            tagId: tagId,
            categoryId: categoryId,
            activeCategories: activeCategories,
            tags: tags,
            initialPins: initialPins,
            favorites: favorites,
            categoriesListTop: categoriesListTop,
            categoriesListBottom: categoriesListBottom
        });

        worker6.onmessage = function (e) {
            const activeCategories = e.data.activeCategories;
            const filtredPinsForMap = e.data.filtredPinsForMap;
            const categoriesListTop = e.data.categoriesListTop;
            const categoriesListBottom = e.data.categoriesListBottom;
            dispatch(dispatchDeleteTag(activeCategories, filtredPinsForMap, categoriesListTop, categoriesListBottom))
        }
    }
};

const dispatchAllTags = (categoryId, activeCategories, filtredPinsForMap, categoriesListTop, categoriesListBottom) => {
    return {
        type: actionTypes.SET_ALL_TAGS,
        categoryId: categoryId,
        activeCategories: activeCategories,
        filtredPinsForMap: filtredPinsForMap,
        categoriesListTop: categoriesListTop,
        categoriesListBottom: categoriesListBottom
    }
};

export const setAllTags = (categoryId, tags, activeCategories, initialPins, favorites, categoriesListTop, categoriesListBottom) => {
    return dispatch => {
        worker4.postMessage({
            categoryId: categoryId,
            tags: tags,
            activeCategories: activeCategories,
            initialPins: initialPins,
            favorites: favorites,
            categoriesListTop: categoriesListTop,
            categoriesListBottom: categoriesListBottom
        });

        worker4.onmessage = function (e) {
            const categoryId = e.data.categoryId;
            const activeCategories = e.data.activeCategories;
            const filtredPinsForMap = e.data.filtredPinsForMap;
            const categoriesListTop = e.data.categoriesListTop;
            const categoriesListBottom = e.data.categoriesListBottom;
            dispatch(dispatchAllTags(categoryId, activeCategories, filtredPinsForMap, categoriesListTop, categoriesListBottom))
        }
    }
};

const dispatchSetActiveHeader = (categoryId, listPinsLocation) => {
    return {
        type: actionTypes.SET_ACTIVE_HEADER,
        categoryId: categoryId,
        listPinsLocation: listPinsLocation
    }
};

export const setActiveHeader = (categoryId, activeCategories, favorites) => {
    return dispatch => {
        worker7.postMessage({
            categoryId: categoryId,
            activeCategories: activeCategories,
            favorites: favorites
        });

        worker7.onmessage = function (e) {
            const listPinsLocation = e.data.listPinsLocation;
            dispatch(dispatchSetActiveHeader(categoryId, listPinsLocation))
        }
    }
};

export const setRouterRadius = radius => {
    return {
        type: actionTypes.SET_ROUTER_RADIUS,
        radius: radius
    }
};

export const changeRouteMode = routeModeId => {
    return {
        type: actionTypes.CHANGE_ROUTE_MODE,
        routeModeId: routeModeId
    };
};

export const setChosenRouteIndex = routeIndex => {
    return {
        type: actionTypes.SET_CHOSEN_ROUTE_INDEX,
        routeIndex: routeIndex
    };
};

export const setAllPossibleRoutes = allPossibleRoutes => {
    return {
        type: actionTypes.SET_ALL_POSSIBLE_ROUTES,
        allPossibleRoutes: allPossibleRoutes
    };
};

export const setInitialLoading = isInitialLoading => {
    return {
        type: actionTypes.SET_INITIAL_LOADING,
        isInitialLoading: isInitialLoading
    };
};

export const setIsInitialMtsVideoLoaded = isInitialMtsVideoLoaded => {
    return {
        type: actionTypes.SET_IS_INITIAL_MTS_VIDEO_LOADED,
        isInitialMtsVideoLoaded: isInitialMtsVideoLoaded
    };
};