import React, {useContext} from 'react';
import CategoryFilter from './CategoryFilter/CategoryFilter';
import {connect} from "react-redux";
import messages from "../../i18n/messages";
import {AppContext} from "../../context/index";

const Filter = props => {
    const {
        categoriesListTop,
        categoriesListBottom
    } = props;
    const {state} = useContext(AppContext);
    const categoryElementsBottom = categoriesListBottom.map(finalCategory => <CategoryFilter
        key={finalCategory.category_id}
        id={finalCategory.category_id}
        name={finalCategory.name}
        tagsList={finalCategory.tags}
        categoryActive={finalCategory.active}
        isAllTags={finalCategory.allTags}
    />);
    const categoryElementsTop = categoriesListTop.map(finalCategory => {
        let name = finalCategory.name;
        if (finalCategory.category_id === 16) {
            name = messages[state.siteLang]['favorites'];
        }
        return <CategoryFilter
            key={finalCategory.category_id}
            id={finalCategory.category_id}
            name={name}
            tagsList={finalCategory.tags}
            categoryActive={finalCategory.active}
            isAllTags={finalCategory.allTags}
        />;
    });

    return <>
        <div className="filter-wrapper">
            <ul className="category-list-wrapper">
                {categoryElementsBottom}
            </ul>
        </div>
        <div className="filter-wrapper bottom">
            <ul className="category-list-wrapper">
                {categoryElementsTop}
            </ul>
        </div>
    </>;
};

const mapStateToProps = state => {
    return {
        categoriesListTop: state.filter.categoriesListTop,
        categoriesListBottom: state.filter.categoriesListBottom,
        categories: state.filter.categories
    }
};

export default connect(mapStateToProps)(Filter);