import React from "react";
import InitialSpinner from "./InitialSpinner/InitialSpinner";

const InitialLoading = () => (
    <div className="initial-loading">
        <InitialSpinner/>
        <img src={'https://cdn.meetserbia.rs/images/static/logo_sticker_x2.png'} className='loading_logo' alt='meetserbia' />
        <img src={'https://cdn.meetserbia.rs/images/static/loading_vehicle_x2.png'} className='loading_vehicle' alt='meetserbia' />
    </div>
)

export default InitialLoading;