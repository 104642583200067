import React from "react";
import {connect} from "react-redux";

const PopUpHover = props => {
    const {
        pinHoverCoordinate,
        popUpHoverPin
    } = props;
    const img = popUpHoverPin.properties.img_url;
    const name = popUpHoverPin.properties.title;
    const category_id = popUpHoverPin.properties.category_id;
    const category_name = popUpHoverPin.properties.category_name;

    return <div
        className="marker-info-wrapper"
        style={{
            left: `${pinHoverCoordinate.x - 110}px`,
            top: `${pinHoverCoordinate.y + 16}px`,
        }}
    >
        <div className="marker-info">
            {img !== 'null' ? <img src={`https://cdn.meetserbia.rs/images/pins/thumb/${img}`} alt={name}/> : <img src={require('../../../assets/img/002.png')} alt='meetserbia'/>}
            <div>
                <h5>{name}</h5>
                <span className={`cat-${category_id}`}>{category_name}</span>
            </div>
        </div>
    </div>;
};

const mapStateToProps = state => {
    return {
        popUpHoverPin: state.map.popUpHoverPin
    }
};

export default connect(mapStateToProps)(PopUpHover);