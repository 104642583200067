import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import * as actions from "../../../store/actions";
import RadiusSlider from './RaduisSlider';
import {withRouter} from 'react-router-dom';
import SimpleBarReact from "simplebar-react";
import translate from "../../../i18n/translate";
import {FacebookShareButton} from "react-share";
import InfoForRouter from "../InfoForRouter/InfoForRouter";

const RouterListItems = props => {
    const {
        token,
        saveRoute,
        route,
        user,
        history,
        openLogInPopUp,
        removePinFromRouter,
        changePinOrderInRoute,
        name,
        routeForShare,
        setRouteForShare,
        activeNavigationMode,
        chosenRouteIndex,
        avoidTolls,
        allPossibleRoutes
    } = props;
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    let LocationHeight = vh-496-36-(allPossibleRoutes.length*32);
    if (activeNavigationMode === 'driving') {
        LocationHeight = LocationHeight - 30;
    }
    const [sharedRoute, setSharedRoute] = useState(null);
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        borderRadius: isDragging && 4,
        background: isDragging && '#e8e7e7',
        boxShadow: isDragging && '1px 1px 5px 0 rgba(0, 0, 0, 0.4)',
        ...draggableStyle
    });
    let tolls = 1;
    if (avoidTolls) {
        tolls = 0;
    }
    const saveRouteHandler = () => {
        if(token !== null) {
            saveRoute(token, route, user, history, activeNavigationMode, chosenRouteIndex, tolls);
        } else  {
            openLogInPopUp({
                tmpPinFavoritesId: null,
                isRouteForAdd: true
            });
        }
    };
    const shareRouteHandler = () => {
        if(token !== null) {
            saveRoute(token, route, user, false, activeNavigationMode, chosenRouteIndex, tolls);
        } else  {
            openLogInPopUp({
                tmpPinFavoritesId: null,
                isRouteForAdd: false
            });
        }
    };
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? '#e8e7e7': 'white'
    });
    const removePinHandler = (index) => {
        removePinFromRouter(index);
    };
    const onDragEnd = (result) => {
        changePinOrderInRoute(result);
    };
    let i = 0;
    const routeLength = route.length;
    const pinList = route.map((pin, index) => {
        i++;
        let routePositionInfo = null;
        if(index === 0) {
            routePositionInfo = <div className="sprite-bg start-icon"/>
        } else if(routeLength > 1 && index+1 === routeLength) {
            routePositionInfo = <div className="sprite-bg finish-icon"/>
        } else {
            routePositionInfo = <div className="start-finish-wrapper">{index}</div>
        }
        return <Draggable
            key={index}
            draggableId={i+''}
            index={index}>
            {(provided, snapshot) => (
                <div
                className='dragable-list'
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
            >
                <div className="img-info-wrapper">
                    <div className="start-finish-wrapper">
                        {routePositionInfo}
                    </div>
                    {pin.img_url === 'null' || pin.img_url === null || pin.img_url === 'location' ? <img src={require('../../../assets/img/002.png')} alt='meetserbia' /> : <img src={`https://cdn.meetserbia.rs/images/pins/thumb/${pin.img_url}`} alt={name}/>}
                </div>
                <span className="route-text">{pin.pinName}</span>
                <div
                    className="sprite-bg close-icon"
                    onClick={() => removePinHandler(index)}
                />
            </div>)}
        </Draggable>
    });
    let fbButtonElement;
    if (sharedRoute !== null) {
        fbButtonElement = <FacebookShareButton
            url={`https://test.meetserbia.rs/route/${sharedRoute.id}`}
            className="btn fb-button option-button-wrapper hidden"
            id='hidden_share_button'
            quote={sharedRoute.name}
        >
            <div className="sprite-bg fb-icon-small"/>
            <span>{translate('share')}</span>
        </FacebookShareButton>
        setTimeout(() => {
            const hiddenShareButton = document.getElementById('hidden_share_button');
            if (hiddenShareButton !== null) {
                hiddenShareButton.click();
            }
            setRouteForShare(null);
        },500);
    }
    useEffect(() => {
        setSharedRoute(routeForShare);
    }, [routeForShare]);
    let helpElement;
    if (route.length === 1 || route.length === 2) {
        helpElement = <InfoForRouter route={route}/>;
    }

    return <>
        <SimpleBarReact style={{height: LocationHeight}}>
            <div className="route-spots-wrapper">
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {pinList}
                                {provided.placeholder}
                            </div>
                            )}
                    </Droppable>
                </DragDropContext>
            </div>
            {helpElement}
        </SimpleBarReact>
        <div className="route-modal hidden"/>
        <RadiusSlider />
        <div className="button-wrapper">
            <button
                className="btn"
                disabled={routeLength === 1}
                onClick={saveRouteHandler}
            >
                {translate('save_route')}
            </button>
            <button
                className="btn fb-button"
                disabled={routeLength === 1}
                onClick={shareRouteHandler}
            >
                <div className="sprite-bg fb-icon"/>
                <span className="btn-text">{translate('share')}</span>
            </button>
            {fbButtonElement}
        </div>
    </>;
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        route: state.router.route,
        user: state.auth.user,
        routeForShare: state.router.routeForShare,
        activeNavigationMode: state.filter.activeNavigationMode,
        chosenRouteIndex: state.filter.chosenRouteIndex,
        avoidTolls: state.filter.avoidTolls,
        allPossibleRoutes : state.filter.allPossibleRoutes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removePinFromRouter: index  => dispatch(actions.removePinFromRouter(index)),
        changePinOrderInRoute: result => dispatch(actions.changePinOrderInRoute(result)),
        openLogInPopUp: forAddAfterLogin => dispatch(actions.openLogInPopUp(forAddAfterLogin)),
        saveRoute: (
            token,
            route,
            user,
            history,
            activeNavigationMode,
            chosenRouteIndex,
            tolls
        ) => dispatch(actions.saveRoute(
            token,
            route,
            user,
            history,
            activeNavigationMode,
            chosenRouteIndex,
            tolls
        )),
        setRouteForShare: route => dispatch(actions.setRouteForShare(route))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouterListItems));