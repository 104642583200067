import React from 'react';
import translate from "../../../i18n/translate";

const InfoForRouter = props => {
    const {route} = props;
    let text1 = translate('add_new_route_1');
    let text2 = translate('add_new_route_2');
    let text3 = translate('add_new_route_3');
    if (route.length === 1) {
        text1 = translate('add_last_destination_1');
        text2 = translate('add_last_destination_2');
        text3 = translate('add_last_destination_3');
    }
    if (route.length === 2) {
        text1 = translate('add_additional_route_1');
        text2 = translate('add_additional_route_2');
        text3 = translate('add_additional_route_3');
    }

    return <div className='empty-route-wrapper'>
        <div className='sprite-bg empty-route-icon'/>
        <p className='empty-route-text'>
            <span>{text1} </span>
            <span className='logo-bold'>{text2} </span>
            <span>{text3}</span>
        </p>
    </div>
}

export default InfoForRouter;