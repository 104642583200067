import * as actionTypes from '../actions/actionTypes';
import produce from "immer";
import {SET_INITIAL_LOADING, SET_IS_INITIAL_MTS_VIDEO_LOADED} from "../actions/actionTypes";

const initialState = {
    initialLoading: false,
    categoriesListTop: [
        {
            category_id: 1,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 2,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 3,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 4,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 5,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 6,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 20,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 16,
            name: null,
            active: false,
            allTags: true,
            tags: []
        }
    ],
    categoriesListBottom: [
        {
            category_id: 9,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 10,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 11,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 12,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 13,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 14,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 19,
            name: null,
            active: false,
            allTags: true,
            tags: []
        },
        {
            category_id: 7,
            name: null,
            active: false,
            allTags: true,
            tags: []
        }
    ],
    categories: [],
    tags: [],
    initialPins: [],
    activeCategories: [],
    isLocationPreview: false,
    filtredPinsForMap: [],
    geoJsonForMap: [],
    pinsOnMap: [],
    lastActiveCategory: null,
    listPinsLocation: [],
    isRouterOpen: false,
    routeRadius: 30,
    favorites: [],
    isZoomPriority: true,
    visiblePinsList: [],
    routeModes: {
        1: {
            id: 1,
            active: true,
            name: 'driving'
        },
        2: {
            id: 2,
            active: false,
            name: 'walking'
        },
        3: {
            id: 3,
            active: false,
            name: 'cycling'
        }
    },
    activeNavigationMode: 'driving',
    chosenRouteIndex: 0,
    allPossibleRoutes: [],
    avoidTolls: false,
    mtsVideoWatched: false
};

const createGeoJsonForMap = draft => {
    draft.geoJsonForMap = [];
    const createGeoJsonHandler = pins => {
        pins.map(pin => {
            const lng = Number(pin.lng);
            const lat = Number(pin.lat);
            const pin_name = pin.pin_name;
            const zoom = Number(pin.zoom);
            const image = pin.primary_icon_id;
            const img_url = pin.img_url;
            const pin_id = pin.pin_id;
            const category_name = pin.category_name;
            const is_popup = pin.is_popup;
            draft.geoJsonForMap.push({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lng, lat]
                    },
                    'properties': {
                        'title': pin_name,
                        'icon': 'image_' + image,
                        'zoom': zoom,
                        'img_url': img_url,
                        'pin_id': pin_id,
                        'category_id': image,
                        'category_name': category_name,
                        'priority': zoom,
                        'is_popup': is_popup
                    }
                }
            );
            return pin;
        })
    }
    let isRouteAddeded = false;
    draft.activeCategories.map(category => {
        if(category.category_id === 99) {
            isRouteAddeded = true;
        }
        return category;
    });
    if(draft.activeCategories.length === 0 || isRouteAddeded) {
        draft.isZoomPriority = true;
    }
    if(draft.activeCategories.length > 1) {
        draft.isZoomPriority = false;
    }
    if ((draft.activeCategories.length === 1 && draft.activeCategories[0].category_id !== 99) || draft.activeCategories.length > 1) {
        const pins = draft.filtredPinsForMap;
        draft.isZoomPriority = false;
        createGeoJsonHandler(pins);
    } else {
        const pins = draft.initialPins;
        createGeoJsonHandler(pins);
        draft.isZoomPriority = true;
    }
};

const reducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch(action.type) {
            case actionTypes.FETCH_CATEGORIES_SUCCESS:
                action.categories.map(categoryResponse => {
                    draft.categoriesListTop.map(category => {
                        if(categoryResponse.category_id === category.category_id) {
                            const objIndex = draft.categoriesListTop.findIndex((obj => obj.category_id === category.category_id));
                            draft.categoriesListTop[objIndex].name = categoryResponse.name
                        }
                        return category;
                    })
                    draft.categoriesListBottom.map(category => {
                        if(categoryResponse.category_id === category.category_id) {
                            const objIndex = draft.categoriesListBottom.findIndex((obj => obj.category_id === category.category_id));
                            draft.categoriesListBottom[objIndex].name = categoryResponse.name
                        }
                        return category;
                    })
                    return categoryResponse;
                })
                draft.categories =  action.categories;
                return;
            case actionTypes.FETCH_TAGS_SUCCESS:
                const initialTags = action.tags;
                initialTags.map(tag => {
                    tag.active = false;
                    return tag;
                })
                initialTags.map(tagResponse => {
                    draft.categoriesListTop.map(category => {
                        if(tagResponse.category_id === category.category_id) {
                            const objIndex = draft.categoriesListTop.findIndex((obj => obj.category_id === category.category_id));
                            draft.categoriesListTop[objIndex].tags.push(tagResponse);
                        }
                        return category;
                    })
                    draft.categoriesListBottom.map(category => {
                        if(tagResponse.category_id === category.category_id) {
                            const objIndex = draft.categoriesListBottom.findIndex((obj => obj.category_id === category.category_id));
                            draft.categoriesListBottom[objIndex].tags.push(tagResponse);
                        }
                        return category;
                    })
                    return tagResponse;
                });
                draft.tags = action.tags;
                return;
            case actionTypes.GET_ALL_PINS_START:
                return;
            case actionTypes.GET_ALL_PINS_SUCCESS:
                draft.initialPins = action.pins;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.MAP_IS_LOADED:
                draft.initialLoading = false;
                return;
            case actionTypes.ADD_CATEGORY_LIST:
                draft.categoriesListTop = action.categoriesListTop;
                draft.categoriesListBottom = action.categoriesListBottom;
                draft.activeCategories = action.activeCategories;
                draft.listPinsLocation = action.listPinsLocation;
                draft.filtredPinsForMap = action.addedUniq;
                draft.isLocationPreview = true;
                draft.lastActiveCategory = action.categoryId;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.DELETE_CATEGORY_LIST:
                draft.categoriesListTop = action.categoriesListTop;
                draft.categoriesListBottom = action.categoriesListBottom;
                draft.activeCategories = action.activeCategories;
                if(draft.activeCategories.length === 0) {
                    draft.isLocationPreview = false;
                }
                if(action.categoryId === 99) {
                    draft.isRouterOpen = false;
                }
                draft.listPinsLocation = action.listPinsLocation;
                draft.filtredPinsForMap = action.deletedUniq;
                draft.lastActiveCategory = action.lastActiveCategory;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.ADD_TAG_LIST:
                draft.categoriesListTop = action.categoriesListTop;
                draft.categoriesListBottom = action.categoriesListBottom;
                draft.activeCategories = action.activeCategories;
                draft.filtredPinsForMap = action.filtredPinsForMap;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.DELETE_TAG_LIST:
                draft.activeCategories = action.activeCategories;
                draft.filtredPinsForMap = action.filtredPinsForMap;
                draft.categoriesListTop = action.categoriesListTop;
                draft.categoriesListBottom = action.categoriesListBottom;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.SET_ALL_TAGS:
                draft.activeCategories = action.activeCategories;
                draft.filtredPinsForMap = action.filtredPinsForMap;
                draft.categoriesListTop = action.categoriesListTop;
                draft.categoriesListBottom = action.categoriesListBottom;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.SET_ACTIVE_HEADER:
                draft.lastActiveCategory = action.categoryId;
                draft.isRouterOpen = action.categoryId === 99;
                draft.listPinsLocation = action.listPinsLocation;
                return;
            case actionTypes.GET_PINS_ON_MAP:
                draft.pinsOnMap = action.pins;
                return;
            case actionTypes.ADD_ROUTER_TO_HEADER:
                const routeCategory = {category_id: 99, category_name: action.routeName, allTags: true, tags: []};
                draft.activeCategories.push(routeCategory);
                draft.isLocationPreview = true;
                draft.lastActiveCategory = 99;
                draft.isRouterOpen = true;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.SET_ROUTER_RADIUS:
                draft.routeRadius = action.radius;
                return;
            case actionTypes.GET_ROUTE_SUCCESS:
                if(draft.isRouterOpen !== true) {
                    const routeCategory2 = {category_id: 99, category_name: 'route', allTags: true, tags: []};
                    draft.activeCategories.push(routeCategory2);
                }
                draft.isLocationPreview = true;
                draft.lastActiveCategory = 99;
                draft.isRouterOpen = true;
                const navigationMode = action.route[0].navigation_mode;
                draft.activeNavigationMode = navigationMode;
                draft.chosenRouteIndex = action.route[0].chosen_route_index;
                Object.values(draft.routeModes).map(mode => {
                   mode.active = mode.name === navigationMode;
                    return mode;
                });
                return;
            case actionTypes.FETCH_FAVORITE_SUCCESS:
                draft.favorites = action.favorites;
                draft.favorites.map(favorite => {
                    draft.listPinsLocation.push(favorite.pin_id);
                    return favorite;
                });
                return;
            case actionTypes.ADD_TO_FAVORITE_SUCCESS:
                const addFavorite = {
                    id: action.favorite.id,
                    pin_id: action.favorite.pin_id
                }
                draft.favorites.unshift(addFavorite);
                draft.filtredPinsForMap = [];
                draft.favorites.map(favorite => {
                    const pins = draft.initialPins.find(x => x.pin_id === favorite.pin_id);
                    if(JSON.stringify(pins) !== undefined) {
                        draft.filtredPinsForMap.push(pins);
                    }
                    return favorite;
                });
                draft.listPinsLocation = [];
                draft.favorites.map(favorite => {
                    draft.listPinsLocation.push(favorite.pin_id);
                    return favorite;
                });
                const addedUniq2 = [...new Set(draft.filtredPinsForMap)];
                draft.filtredPinsForMap = addedUniq2;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.DELETE_FAVORITE_SUCCESS:
                draft.favorites = draft.favorites.filter(x => x.pin_id !== action.pinId);
                draft.filtredPinsForMap = [];
                draft.favorites.map(favorite => {
                    const pins = draft.initialPins.find(x => x.pin_id === favorite.pin_id);
                    if(JSON.stringify(pins) !== undefined) {
                        draft.filtredPinsForMap.push(pins);
                    }
                    return favorite;
                });
                draft.listPinsLocation = [];
                draft.favorites.map(favorite => {
                    draft.listPinsLocation.push(favorite.pin_id);
                    return favorite;
                });
                const addedUniq3 = [...new Set(draft.filtredPinsForMap)];
                draft.filtredPinsForMap = addedUniq3;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.REFRESH_FAVORITES:
                const filteredPinsForMap = [];
                if (action.isLogout) {
                    draft.favorites = [];
                }
                draft.activeCategories.map(category => {
                    category.tags.map(tag => {
                        tag.pins.map(pin_id => {
                            const pins = draft.initialPins.find(x => x.pin_id === pin_id);
                            if(pins !== undefined) {
                                filteredPinsForMap.push(pins);
                            }
                            return pin_id;
                        })
                        return tag;
                    });
                    if(category.category_id === 16) {
                        draft.favorites.map(favorite => {
                            const pins = draft.initialPins.find(x => x.pin_id === favorite.pin_id);
                            if(JSON.stringify(pins) !== undefined) {
                                filteredPinsForMap.push(pins);
                            }
                            return favorite;
                        });
                    }
                    return category;
                });
                if (draft.lastActiveCategory === 16) {
                    draft.listPinsLocation = [];
                    draft.favorites.map(favorite => {
                        draft.listPinsLocation.push(favorite.pin_id);
                        return favorite;
                    });
                }
                const tmpFilteredPinsForMap = [...new Set(filteredPinsForMap)];
                draft.filtredPinsForMap = tmpFilteredPinsForMap;
                createGeoJsonForMap(draft);
                return;
            case actionTypes.CHANGE_ROUTE_MODE:
                Object.values(draft.routeModes).map(routeMode => {
                    routeMode.active = false;
                    return routeMode;
                });
                draft.routeModes[action.routeModeId].active = true;
                switch (action.routeModeId) {
                    case 1:
                        draft.activeNavigationMode = 'driving';
                        break;
                    case 2:
                        draft.activeNavigationMode = 'walking';
                        break;
                    case 3:
                        draft.activeNavigationMode = 'cycling';
                        break;
                    default:
                        break;
                }
                return;
            case actionTypes.SET_CHOSEN_ROUTE_INDEX:
                draft.chosenRouteIndex = action.routeIndex;
                return;
            case actionTypes.SET_ALL_POSSIBLE_ROUTES:
                draft.allPossibleRoutes = action.allPossibleRoutes;
                return;
            case actionTypes.SET_AVOID_TOLLS:
                draft.avoidTolls = action.isAvoidTolls;
                return;
            case actionTypes.SET_INITIAL_LOADING:
                draft.initialLoading = action.isInitialLoading;
                return;
            case actionTypes.SET_IS_INITIAL_MTS_VIDEO_LOADED:
                draft.mtsVideoWatched = action.isInitialMtsVideoLoaded;
                return;
            default:
                return;
        }
    });
};

export default reducer;