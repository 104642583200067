import * as actionTypes from './actionTypes';
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

export const contactUsOpen = () => {
    return {
        type: actionTypes.CONTACT_US_OPEN
    }
}

const contactUsStart = () => {
    return {
        type: actionTypes.CONTACT_US_START
    }
}

const contactUsSuccess = () => {
    return {
        type: actionTypes.CONTACT_US_SUCCESS
    }
}

const contactUsFail = (error) => {
    return {
        type: actionTypes.CONTACT_US_FAIL,
        error: error
    }
}

export const contactUs = (email, location, description) => {
    return dispatch => {
        dispatch(contactUsStart());
        const authData = {
            email: email,
            location: location,
            description: description
        }
        let url = `${apiURL}/v1/contact-us`;
        axios.post(url, authData)
            .then(response => {
                dispatch(contactUsSuccess());
            }).catch(error => {
            dispatch(contactUsFail(error.response.data));
        });
    };
};