import React, {useContext, useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import translate from "../../i18n/translate";
import * as actions from "../../store/actions";
import axios from "axios";
import {getFromStorage} from "../../utils/sessionStorage";
import messages from "../../i18n/messages";
import {AppContext} from "../../context";

const Search = props => {
    const {
        initialPins,
        onPinSelect,
        whatSearch,
        onPinRouterAdd
    } = props;
    const {state} = useContext(AppContext);
    const [search, setSearch] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [localResults, setLocalResults] = useState([]);
    const [apiResults, setApiResults] = useState([]);
    const listElement = useRef(null);
    const searchInputElement = useRef(null);
    const siteLangId = getFromStorage('siteLangId');
    let localListStyle = isSearchOpen ? {display: 'block'} : {};
    const inputChangeHandler = e => {
        let input = e.target.value;
        switch (siteLangId) {
            case 1:
                for (let i = 0; i < input.length; i++) {
                    switch (input.charAt(i)) {
                        case ';':
                            input = input.replace(';', 'č');
                            break;
                        case ':':
                            input = input.replace(':', 'Č');
                            break;
                        case '\'':
                            input = input.replace('\'', 'ć');
                            break;
                        case '"':
                            input = input.replace('"', 'Ć');
                            break;
                        case ']':
                            input = input.replace(']', 'đ');
                            break;
                        case '}':
                            input = input.replace('}', 'Đ');
                            break;
                        case '[':
                            input = input.replace('[', 'š');
                            break;
                        case '{':
                            input = input.replace('{', 'Š');
                            break;
                        case '\\':
                            input = input.replace('\\', 'ž');
                            break;
                        case '|':
                            input = input.replace('|', 'Ž');
                            break;



                        case 'а':
                            input = input.replace('а', 'a');
                            break;
                        case 'А':
                            input = input.replace('А', 'A');
                            break;
                        case 'б':
                            input = input.replace('б', 'b');
                            break;
                        case 'Б':
                            input = input.replace('Б', 'B');
                            break;
                        case 'ц':
                            input = input.replace('ц', 'c');
                            break;
                        case 'Ц':
                            input = input.replace('Ц', 'C');
                            break;
                        case 'д':
                            input = input.replace('д', 'd');
                            break;
                        case 'Д':
                            input = input.replace('Д', 'D');
                            break;
                        case 'е':
                            input = input.replace('е', 'e');
                            break;
                        case 'Е':
                            input = input.replace('Е', 'E');
                            break;
                        case 'ф':
                            input = input.replace('ф', 'f');
                            break;
                        case 'Ф':
                            input = input.replace('Ф', 'F');
                            break;
                        case 'г':
                            input = input.replace('г', 'g');
                            break;
                        case 'Г':
                            input = input.replace('Г', 'G');
                            break;
                        case 'х':
                            input = input.replace('х', 'h');
                            break;
                        case 'Х':
                            input = input.replace('Х', 'H');
                            break;
                        case 'и':
                            input = input.replace('и', 'i');
                            break;
                        case 'И':
                            input = input.replace('И', 'I');
                            break;
                        case 'ј':
                            input = input.replace('ј', 'j');
                            break;
                        case 'Ј':
                            input = input.replace('Ј', 'J');
                            break;
                        case 'к':
                            input = input.replace('к', 'k');
                            break;
                        case 'К':
                            input = input.replace('К', 'K');
                            break;
                        case 'л':
                            input = input.replace('л', 'l');
                            break;
                        case 'Л':
                            input = input.replace('Л', 'L');
                            break;
                        case 'м':
                            input = input.replace('м', 'm');
                            break;
                        case 'М':
                            input = input.replace('М', 'M');
                            break;
                        case 'н':
                            input = input.replace('н', 'n');
                            break;
                        case 'Н':
                            input = input.replace('Н', 'N');
                            break;
                        case 'о':
                            input = input.replace('о', 'o');
                            break;
                        case 'О':
                            input = input.replace('О', 'O');
                            break;
                        case 'п':
                            input = input.replace('п', 'p');
                            break;
                        case 'П':
                            input = input.replace('П', 'P');
                            break;
                        case 'љ':
                            input = input.replace('љ', 'q');
                            break;
                        case 'Љ':
                            input = input.replace('Љ', 'Q');
                            break;
                        case 'р':
                            input = input.replace('р', 'r');
                            break;
                        case 'Р':
                            input = input.replace('Р', 'R');
                            break;
                        case 'с':
                            input = input.replace('с', 's');
                            break;
                        case 'С':
                            input = input.replace('С', 'c');
                            break;
                        case 'т':
                            input = input.replace('т', 't');
                            break;
                        case 'Т':
                            input = input.replace('Т', 't');
                            break;
                        case 'у':
                            input = input.replace('у', 'u');
                            break;
                        case 'У':
                            input = input.replace('У', 'U');
                            break;
                        case 'в':
                            input = input.replace('в', 'v');
                            break;
                        case 'В':
                            input = input.replace('В', 'V');
                            break;
                        case 'њ':
                            input = input.replace('њ', 'w');
                            break;
                        case 'Њ':
                            input = input.replace('Њ', 'W');
                            break;
                        case 'џ':
                            input = input.replace('џ', 'x');
                            break;
                        case 'Џ':
                            input = input.replace('Џ', 'X');
                            break;
                        case 'з':
                            input = input.replace('з', 'y');
                            break;
                        case 'З':
                            input = input.replace('З', 'y');
                            break;
                        // case 'ж':
                        //     input = input.replace('ж', 'z');
                        //     break;
                        // case 'Ж':
                        //     input = input.replace('Ж', 'Z');
                        //     break;



                        case 'ч':
                            input = input.replace('ч', 'č');
                            break;
                        case 'Ч':
                            input = input.replace('Ч', 'Č');
                            break;
                        case 'ћ':
                            input = input.replace('ћ', 'ć');
                            break;
                        case 'Ћ':
                            input = input.replace('Ћ', 'Ć');
                            break;
                        case 'ђ':
                            input = input.replace('ђ', 'đ');
                            break;
                        case 'Ђ':
                            input = input.replace('Ђ', 'Đ');
                            break;
                        case 'ш':
                            input = input.replace('ш', 'š');
                            break;
                        case 'Ш':
                            input = input.replace('Ш', 'Š');
                            break;
                        case 'ж':
                            input = input.replace('ж', 'ž');
                            break;
                        case 'Ж':
                            input = input.replace('Ж', 'Ž');
                            break;
                        default:
                            break;
                    }
                }
                break;
            case 2:
                for (let i = 0; i < input.length; i++) {
                    switch (input.charAt(i)) {
                        case 'a':
                            input = input.replace('a', 'а');
                            break;
                        case 'A':
                            input = input.replace('A', 'А');
                            break;
                        case 'b':
                            input = input.replace('b', 'б');
                            break;
                        case 'B':
                            input = input.replace('B', 'Б');
                            break;
                        case 'v':
                            input = input.replace('v', 'в');
                            break;
                        case 'V':
                            input = input.replace('V', 'В');
                            break;
                        case 'g':
                            input = input.replace('g', 'г');
                            break;
                        case 'G':
                            input = input.replace('G', 'Г');
                            break;
                        case 'd':
                            input = input.replace('d', 'д');
                            break;
                        case 'D':
                            input = input.replace('D', 'Д');
                            break;
                        case ']':
                            input = input.replace(']', 'ђ');
                            break;
                        case '}':
                            input = input.replace('}', 'Ђ');
                            break;
                        case 'e':
                            input = input.replace('e', 'е');
                            break;
                        case 'E':
                            input = input.replace('E', 'Е');
                            break;
                        case '\\':
                            input = input.replace('\\', 'ж');
                            break;
                        case '|':
                            input = input.replace('|', 'Ж');
                            break;
                        case 'z':
                            input = input.replace('z', 'з');
                            break;
                        case 'Z':
                            input = input.replace('Z', 'З');
                            break;
                        case 'i':
                            input = input.replace('i', 'и');
                            break;
                        case 'I':
                            input = input.replace('I', 'И');
                            break;
                        case 'j':
                            input = input.replace('j', 'ј');
                            break;
                        case 'J':
                            input = input.replace('J', 'Ј');
                            break;
                        case 'k':
                            input = input.replace('k', 'к');
                            break;
                        case 'K':
                            input = input.replace('K', 'К');
                            break;
                        case 'l':
                            input = input.replace('l', 'л');
                            break;
                        case 'L':
                            input = input.replace('L', 'Л');
                            break;
                        case 'q':
                            input = input.replace('q', 'љ');
                            break;
                        case 'Q':
                            input = input.replace('Q', 'Љ');
                            break;
                        case 'm':
                            input = input.replace('m', 'м');
                            break;
                        case 'M':
                            input = input.replace('M', 'М');
                            break;
                        case 'n':
                            input = input.replace('n', 'н');
                            break;
                        case 'N':
                            input = input.replace('N', 'Н');
                            break;
                        case 'w':
                            input = input.replace('w', 'њ');
                            break;
                        case 'W':
                            input = input.replace('W', 'Њ');
                            break;
                        case 'o':
                            input = input.replace('o', 'о');
                            break;
                        case 'O':
                            input = input.replace('O', 'О');
                            break;
                        case 'p':
                            input = input.replace('p', 'п');
                            break;
                        case 'P':
                            input = input.replace('P', 'П');
                            break;
                        case 'r':
                            input = input.replace('r', 'р');
                            break;
                        case 'R':
                            input = input.replace('R', 'Р');
                            break;
                        case 's':
                            input = input.replace('s', 'с');
                            break;
                        case 'S':
                            input = input.replace('S', 'С');
                            break;
                        case 't':
                            input = input.replace('t', 'т');
                            break;
                        case 'T':
                            input = input.replace('T', 'Т');
                            break;
                        case '\'':
                            input = input.replace('\'', 'ћ');
                            break;
                        case '"':
                            input = input.replace('"', 'Ћ');
                            break;
                        case 'u':
                            input = input.replace('u', 'у');
                            break;
                        case 'U':
                            input = input.replace('U', 'У');
                            break;
                        case 'f':
                            input = input.replace('f', 'ф');
                            break;
                        case 'F':
                            input = input.replace('F', 'Ф');
                            break;
                        case 'h':
                            input = input.replace('h', 'х');
                            break;
                        case 'H':
                            input = input.replace('H', 'Х');
                            break;
                        case 'c':
                            input = input.replace('c', 'ц');
                            break;
                        case 'C':
                            input = input.replace('C', 'Ц');
                            break;
                        case ';':
                            input = input.replace(';', 'ч');
                            break;
                        case ':':
                            input = input.replace(':', 'Ч');
                            break;
                        case 'x':
                            input = input.replace('x', 'џ');
                            break;
                        case 'X':
                            input = input.replace('X', 'Џ');
                            break;
                        case '[':
                            input = input.replace('[', 'ш');
                            break;
                        case '{':
                            input = input.replace('{', 'Ш');
                            break;



                        case 'č':
                            input = input.replace('č', 'ч');
                            break;
                        case 'Č':
                            input = input.replace('Č', 'Ч');
                            break;
                        case 'ć':
                            input = input.replace('ć', 'ћ');
                            break;
                        case 'Ć':
                            input = input.replace('Ć', 'Ћ');
                            break;
                        case 'đ':
                            input = input.replace('đ', 'ђ');
                            break;
                        case 'Đ':
                            input = input.replace('Đ', 'Ђ');
                            break;
                        case 'š':
                            input = input.replace('š', 'ш');
                            break;
                        case 'Š':
                            input = input.replace('Š', 'Ш');
                            break;
                        case 'ž':
                            input = input.replace('ž', 'ж');
                            break;
                        case 'Ž':
                            input = input.replace('Ž', 'Ж');
                            break;
                        default:
                            break;
                    }
                }
                break;
            case 3:
                for (let i = 0; i < input.length; i++) {
                    switch (input.charAt(i)) {
                        case 'а':
                            input = input.replace('а', 'a');
                            break;
                        case 'А':
                            input = input.replace('А', 'A');
                            break;
                        case 'б':
                            input = input.replace('б', 'b');
                            break;
                        case 'Б':
                            input = input.replace('Б', 'B');
                            break;
                        case 'ц':
                            input = input.replace('ц', 'c');
                            break;
                        case 'Ц':
                            input = input.replace('Ц', 'C');
                            break;
                        case 'д':
                            input = input.replace('д', 'd');
                            break;
                        case 'Д':
                            input = input.replace('Д', 'D');
                            break;
                        case 'е':
                            input = input.replace('е', 'e');
                            break;
                        case 'Е':
                            input = input.replace('Е', 'E');
                            break;
                        case 'ф':
                            input = input.replace('ф', 'f');
                            break;
                        case 'Ф':
                            input = input.replace('Ф', 'F');
                            break;
                        case 'г':
                            input = input.replace('г', 'g');
                            break;
                        case 'Г':
                            input = input.replace('Г', 'G');
                            break;
                        case 'х':
                            input = input.replace('х', 'h');
                            break;
                        case 'Х':
                            input = input.replace('Х', 'H');
                            break;
                        case 'и':
                            input = input.replace('и', 'i');
                            break;
                        case 'И':
                            input = input.replace('И', 'I');
                            break;
                        case 'ј':
                            input = input.replace('ј', 'j');
                            break;
                        case 'Ј':
                            input = input.replace('Ј', 'J');
                            break;
                        case 'к':
                            input = input.replace('к', 'k');
                            break;
                        case 'К':
                            input = input.replace('К', 'K');
                            break;
                        case 'л':
                            input = input.replace('л', 'l');
                            break;
                        case 'Л':
                            input = input.replace('Л', 'L');
                            break;
                        case 'м':
                            input = input.replace('м', 'm');
                            break;
                        case 'М':
                            input = input.replace('М', 'M');
                            break;
                        case 'н':
                            input = input.replace('н', 'n');
                            break;
                        case 'Н':
                            input = input.replace('Н', 'N');
                            break;
                        case 'о':
                            input = input.replace('о', 'o');
                            break;
                        case 'О':
                            input = input.replace('О', 'O');
                            break;
                        case 'п':
                            input = input.replace('п', 'p');
                            break;
                        case 'П':
                            input = input.replace('П', 'P');
                            break;
                        case 'љ':
                            input = input.replace('љ', 'q');
                            break;
                        case 'Љ':
                            input = input.replace('Љ', 'Q');
                            break;
                        case 'р':
                            input = input.replace('р', 'r');
                            break;
                        case 'Р':
                            input = input.replace('Р', 'R');
                            break;
                        case 'с':
                            input = input.replace('с', 's');
                            break;
                        case 'С':
                            input = input.replace('С', 'c');
                            break;
                        case 'т':
                            input = input.replace('т', 't');
                            break;
                        case 'Т':
                            input = input.replace('Т', 't');
                            break;
                        case 'у':
                            input = input.replace('у', 'u');
                            break;
                        case 'У':
                            input = input.replace('У', 'U');
                            break;
                        case 'в':
                            input = input.replace('в', 'v');
                            break;
                        case 'В':
                            input = input.replace('В', 'V');
                            break;
                        case 'њ':
                            input = input.replace('њ', 'w');
                            break;
                        case 'Њ':
                            input = input.replace('Њ', 'W');
                            break;
                        case 'џ':
                            input = input.replace('џ', 'x');
                            break;
                        case 'Џ':
                            input = input.replace('Џ', 'X');
                            break;
                        case 'з':
                            input = input.replace('з', 'y');
                            break;
                        case 'З':
                            input = input.replace('З', 'y');
                            break;
                        case 'ж':
                            input = input.replace('ж', 'z');
                            break;
                        case 'Ж':
                            input = input.replace('Ж', 'Z');
                            break;
                        default:
                            break;
                    }
                }
                break;
            default:
                break;
        }
        input = input.replace('\\', '');
        input = input.replace('[', '');
        input = input.replace(']', '');
        setSearch(input);
        if(e.target.value.length > 2) {
            setIsSearchOpen(true);
        } else {
            setIsSearchOpen(false);
        }
    };
    useEffect(() => {
        let tmpPinsFull = [];
        const tmpPinsPriority = [];
        setLocalResults([]);
        let firstSearch = search;
        const letters = {
            "z": "[zž]",
            "c": "[cčć]",
            "d": "[dđ]",
            "s": "[sš]",
        };
        if(firstSearch.match('dj')) {
            firstSearch = search.replace('dj', '[đ]');
        }
        const finalSearch = firstSearch.replace(/[zcds\\]/g, ch => letters[ch] || ch);
        const finalRegex = new RegExp("(?:" + finalSearch + ")", "gi");
        firstSearch = firstSearch.replace(/[zcds\\]/g, ch => letters[ch] || ch).split(/[ ,]+/).filter(Boolean);
        const regex = new RegExp("(?:" + firstSearch.join("|") + ")", "gi");
        initialPins.map(pin => {
            if(pin.pin_name.match(regex)) {
                tmpPinsFull.push(pin);
            }
            return pin;
        });
        tmpPinsFull.map(pin => {
            if(pin.pin_name.match(finalRegex)) {
                if(tmpPinsPriority.length === 10) {
                    return false;
                }
                tmpPinsPriority.push(pin);
            }
            return pin;
        });
        if (tmpPinsPriority.length < 10) {
            tmpPinsFull.map(pinFull => {
                if(tmpPinsPriority.length === 10) {
                    return false;
                }
                let isExistInside = false;
                tmpPinsPriority.map(pinPriority => {
                    if(pinFull === pinPriority) {
                        isExistInside = true;
                    }
                    return pinPriority;
                });
                if (!isExistInside) {
                    tmpPinsPriority.push(pinFull);
                }
                return pinFull;
            });
        }
        setLocalResults(tmpPinsPriority);
    }, [search, setIsSearchOpen, initialPins, setLocalResults]);
    useEffect(() => {
        const timer = setTimeout(() => {
            const fetchPinSearch = async () => {
                setApiResults([]);
                if(search.length > 2) {
                    await axios.get(`https://search.meetserbia.rs/search.php?city=${search}&format=jsonv2&limit=5&namedetails=1`)
                    .then(response => {
                        setApiResults(response.data);
                    });
                }
            };
            fetchPinSearch();
        }, 100);
        return () => clearTimeout(timer);
    }, [search]);
    useEffect(() => {
        let array = [ ...listElement.current.childNodes];
        if (array.length > 0) {
            array[0].classList.add('active');
        }
    }, [localResults, apiResults]);
    const selectLocalSearchHandler = (selectedPin => {
        if(whatSearch === 'route') {
            onPinRouterAdd(selectedPin.name, selectedPin.pinId, selectedPin.lng, selectedPin.lat, selectedPin.imgUrl);
        } else {
            onPinSelect(selectedPin);
        }
        setIsSearchOpen(false);
        setSearch('');
    });
    let localList = <div className='not-found'>{translate('not_found')}</div>;
    let imgUrlSearch = <img src={require('../../assets/img/002.png')} alt='meetserbia'/>;
    const resultsLocal = localResults.map(pin => {
        if (pin.img_url !== null) {
            imgUrlSearch = <img src={`https://cdn.meetserbia.rs/images/pins/thumb/${pin.img_url}`} alt={pin.pin_name}/>
        }
        const selectedPin = {
            name: pin.pin_name,
            lng: pin.lng,
            lat: pin.lat,
            pinId: pin.pin_id,
            imgUrl: pin.img_url
        };
        return <li
            onClick={() => selectLocalSearchHandler(selectedPin)}
            key={pin.pin_id}
            data-name={pin.pin_name}
            data-lng={pin.lng}
            data-lat={pin.lat}
            data-img={pin.img_url}
            data-id={pin.pin_id}
        >
            <div className="geocoder-dropdown-item">
                {imgUrlSearch}
                <span className="geocoder-dropdown-text"> {pin.pin_name}</span>
            </div>
        </li>;
    });
    const resultsApi = apiResults.map(pin => {
        let name = pin.namedetails.name;
        let type = pin.type;
        let pinList = null;
        const locale = getFromStorage('siteLangId');
        if(name === "Батајница" || name === "Младеновац (варош)" || name === "Каменица" || name === "Јошева" || name === "Лешница" || name === "Ferizaj") {
            if(locale === 1) {
                name = pin.namedetails["name:sr-Latn"];
                if(name === undefined) {
                    name = pin.namedetails["name:en"];
                }
                if (type === "administrative") {
                    type = "Predgrađe"
                }
            } else if(locale === 2) {
                name = pin.namedetails["name:sr"];
                if (type === "administrative") {
                    type = "Предграђе"
                }
            } else if(locale === 3) {
                name = pin.namedetails["name:en"];
                if(name === undefined) {
                    name = pin.namedetails["name:sr-Latn"];
                }
                if (type === "administrative") {
                    type = "Suburb"
                }
            }
            const selectedPin = {
                name: name,
                lng: pin.lon,
                lat: pin.lat,
                pinId: null,
                imgUrl: null
            };
            pinList = <li
                onClick={() => selectLocalSearchHandler(selectedPin)}
                key={pin.osm_id}
                data-name={name}
                data-lng={pin.lon}
                data-lat={pin.lat}
                data-img={'null'}
                data-id={'null'}
            >
                <div className="geocoder-dropdown-item">
                    <img src={require('../../assets/img/location_x2.png')} alt='meetserbia'/>
                    <span className="geocoder-dropdown-text">{name} - {type}</span>
                </div>
            </li>;
        }
        if (locale === 1) {
            name = pin.namedetails["name:sr-Latn"];
            if(name === undefined) {
                name = pin.namedetails["name:en"];
            }
            if(type === "city") {
                type = "Grad"
            } else if (type === "town") {
                type = "Gradsko naselje"
            } else if (type === "village") {
                type = "Selo"
            } else if (type === "hamlet") {
                type = "Zaselak"
            } else if (type === "locality") {
                type = "Gradsko naselje"
            } else if (type === "neighbourhood") {
                type = "Okrug"
            } else if (type === "suburb") {
                type = "Predgrađe"
            }
        } else if (locale === 2) {
            name = pin.namedetails["name:sr"];
            if(type === "city") {
                type = "Град"
            } else if (type === "town") {
                type = "Градско насеље"
            } else if (type === "village") {
                type = "Село"
            } else if (type === "hamlet") {
                type = "Заселак"
            } else if (type === "locality") {
                type = "Градско насеље"
            } else if (type === "neighbourhood") {
                type = "Округ"
            } else if (type === "suburb") {
                type = "Предграђе"
            }
        } else if (locale === 3) {
            name = pin.namedetails["name:en"];
            if(name === undefined) {
                name = pin.namedetails["name:sr-Latn"];
            }
            if(type === "city") {
                type = "City"
            } else if (type === "town") {
                type = "Town"
            } else if (type === "village") {
                type = "Village"
            }  else if (type === "hamlet") {
                type = "Hamlet"
            } else if (type === "locality") {
                type = "Grad"
            } else if (type === "neighbourhood") {
                type = "Neighbourhood"
            } else if (type === "suburb") {
                type = "Suburb"
            }
        }
        if(pin.type !== 'administrative') {
            if(name !== undefined) {
                const selectedPin = {
                    name: name,
                    lng: pin.lon,
                    lat: pin.lat,
                    pinId: null,
                    imgUrl: null
                };
                pinList = <li
                    onClick={() => selectLocalSearchHandler(selectedPin)}
                    key={pin.osm_id}
                    data-name={name}
                    data-lng={pin.lon}
                    data-lat={pin.lat}
                    data-img={'null'}
                    data-id={'null'}
                >
                    <div className="geocoder-dropdown-item">
                        <img src={require('../../assets/img/location_x2.png')} alt='meetserbia'/>
                        <span className="geocoder-dropdown-text">{name} - {type}</span>
                    </div>
                </li>;
            }
        }
        return pinList;
    });
    const onKeyDownHandler = e => {
        let array = [ ...listElement.current.childNodes];
        switch (e.key) {
            case 'Enter':
                array.map(element => {
                    if (!element.classList.contains('not-found')) {
                        if (element.classList.contains('active')) {
                            const selectedPin = {
                                name: element.dataset.name,
                                lng: element.dataset.lng,
                                lat: element.dataset.lat,
                                pinId: element.dataset.id,
                                imgUrl: element.dataset.img
                            };
                            selectLocalSearchHandler(selectedPin);
                        }
                    }
                    return element;
                });
                return;
            case 'ArrowDown':
                e.preventDefault();
                let activeIndex;
                listElement.current.focus();
                array.map((element, index) => {
                    if (element.classList.contains('active')) {
                        activeIndex = index+1;
                        if (activeIndex < array.length) {
                            element.classList.remove('active');
                        }
                    }
                    return element;
                });
                if (activeIndex < array.length) {
                    array[activeIndex].classList.add('active');
                }
                return;
            case 'Escape':
                setIsSearchOpen(false);
                searchInputElement.current.blur();
                return;
            default:
                return;
        }
    };
    const listFocusHandler = e => {
        e.preventDefault();
        let activeIndex;
        let array = [ ...listElement.current.childNodes];
        switch (e.key) {
            case 'ArrowUp':
                array.map((element, index) => {
                    if (element.classList.contains('active')) {
                        activeIndex = index-1;
                        if (activeIndex > -1) {
                            element.classList.remove('active');
                        }
                    }
                    return element;
                });
                if (activeIndex > -1) {
                    array[activeIndex].classList.add('active');
                } else {
                    searchInputElement.current.focus();
                }
                return;
            case 'ArrowDown':
                array.map((element, index) => {
                    if (element.classList.contains('active')) {
                        activeIndex = index+1;
                        if (activeIndex < array.length) {
                            element.classList.remove('active');
                        }
                    }
                    return element;
                });
                if (activeIndex < array.length) {
                    array[activeIndex].classList.add('active');
                }
                return;
            case 'Enter':
                array.map(element => {
                    if (!element.classList.contains('not-found')) {
                        if (element.classList.contains('active')) {
                            const selectedPin = {
                                name: element.dataset.name,
                                lng: element.dataset.lng,
                                lat: element.dataset.lat,
                                pinId: element.dataset.id,
                                imgUrl: element.dataset.img
                            };
                            selectLocalSearchHandler(selectedPin);
                        }
                    }
                    return element;
                });
                return;
            case 'Escape':
                setIsSearchOpen(false);
                return;
            default:
                return;
        }
    };

    return <div className="search-wrapper">
        <div className='sprite-bg search-icon'/>
            <input
                className={search.length > 2 ? 'open-dd' : 'close-dd'}
                type="text"
                placeholder={messages[state.siteLang]['search']}
                id='geocoder'
                value={search}
                onChange={inputChangeHandler}
                onFocus={inputChangeHandler}
                autoComplete="off"
                onKeyDown={onKeyDownHandler}
                ref={searchInputElement}
            />
        <ul
            className='async-search-wrapper'
            style={localListStyle}
            tabIndex={0}
            onKeyDown={listFocusHandler}
            ref={listElement}
        >
                {localResults.length === 0 && apiResults.length === 0 && localList}
                {resultsLocal}
                {resultsApi}
            </ul>
    </div>;
};

const mapStateToProps = state => {
    return {
        initialPins: state.filter.initialPins
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPinSelect: (pin)  => dispatch(actions.setSelectedLocation(pin)),
        onPinRouterAdd: (pinName, pinId, lng, lat, img_url, category_id, category_name) => dispatch(actions.addPinToRouter(pinName, pinId, lng, lat, img_url, category_id, category_name))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);