import * as actionTypes from "./actionTypes";
import axios from "axios";
import {getFromStorage} from "../../utils/sessionStorage";

const apiURL = process.env.REACT_APP_API_URL;
const locale = getFromStorage('siteLangId');

const getGuidesStart = () => {
    return {
        type: actionTypes.START_GUIDES
    }
}

const setGuides = (guides) => {
    return {
        type: actionTypes.SUCCES_GUIDES,
        guides: guides
    }
}

export const fetchGuides = () => {
    return dispatch => {
        dispatch(getGuidesStart());
        axios.get(`${apiURL}/v1/guides/${locale}`)
            .then(response => {
                dispatch(setGuides(response.data));
            });
    }
}