import React from "react";
import translate from "../../../../../i18n/translate";
import {Link} from "react-router-dom";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";

const SettingsDropDown = props => {
    const {
        logout,
        selectProfile,
        setAlertMessage
    } = props;
    const logOutHandler = () => {
        setAlertMessage('logout_success', 'alert_success');
        logout();
    };
    const authPopUpHandler = (authIndex) => {
        selectProfile(authIndex);
    };

    return <ul>
        <li>
            <Link
                to='/profile'
                onClick={() => authPopUpHandler(0)}
            >
                <div className="sprite-bg saving-icon-line"/>
                <span className="settings-nav-text">{translate('saving_routes')}</span>
            </Link>
        </li>
        <li>
            <Link
                to='/profile'
                onClick={() => authPopUpHandler(1)}
            >
                <div className="sprite-bg favorite-icon-line"/>
                <span className="settings-nav-text">{translate('favorite_locations')}</span>
            </Link>

        </li>
        <li>
            <Link
                to='/profile'
                className="settings-nav-text"
                onClick={() => authPopUpHandler(2)}
            >
                {translate('settings')}
            </Link>
        </li>
        <li>
            <Link
                to='/'
                className="settings-nav-text"
                onClick={logOutHandler}
            >
                {translate('log_out')}</Link>
        </li>
    </ul>
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
        selectProfile: authIndex => dispatch(actions.selectProfile(authIndex)),
        setAlertMessage: (alertMessage, alertType) => dispatch(actions.setAlertMessage(alertMessage, alertType))
    }
};

export default connect(null, mapDispatchToProps)(SettingsDropDown);