import React from 'react';
import PopupContainer from "../PopupContainer/PopupContainer";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actionTypes from "../../../store/actions/actionTypes";
import translate from "../../../i18n/translate";

const RouteConfirmPopUp = props => {
    const {
        token,
        routeForDelete,
        history,
        onCancelClicked,
        onRouteDelete
    } = props;
    const deleteRouteHandler = () => {
        onCancelClicked();
        onRouteDelete(token, routeForDelete, history);
    }
    const cancelDeleteHandler = () => {
        onCancelClicked();
    }

    return <PopupContainer class='delete-modal'>
        <div
            className="sprite-bg close-icon-big"
            onClick={cancelDeleteHandler}
        />
        <h3>{translate('delete_route_text')}</h3>
        <div className='pop-up-divider' />
        <div className='button-wrapper'>
            <button
                className='btn accept'
                onClick={deleteRouteHandler}
            >
                <div className='sprite-bg accept-icon'/>
                <span>{translate('yes')}</span>
            </button>
            <button
                className='btn dalete'
                onClick={cancelDeleteHandler}
            >
                <div className='sprite-bg cancel-icon'/>
                <span>{translate('no')}</span>
            </button>
        </div>
    </PopupContainer>;
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isRouteConfirm: state.reducer.isRouteConfirm,
        routeForDelete: state.reducer.routeForDelete
    }
};

const mapDipatchToProps = dispatch => {
    return {
        onCancelClicked: () => dispatch({type: actionTypes.CLOSE_POPUPS_TWO}),
        onRouteDelete: (token, id, history)  => dispatch(actions.deleteRoute(token, id, history))
    }
};

export default connect(mapStateToProps, mapDipatchToProps)(withRouter(RouteConfirmPopUp));