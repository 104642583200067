import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import * as actionTypes from "../../../store/actions/actionTypes";
import translate from "../../../i18n/translate";
import Seprator from "../../UI/Separator/Separator";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const LogInRequired = props => {
    const {
        onModalClicked,
        onAuthSelected,
        onFacebookSuccess
    } = props;
    const authPopUpHandler = (authIndex) => {
        onModalClicked();
        onAuthSelected(authIndex);
    };
    const responseFacebook = (response) => {
        onFacebookSuccess(response);
    };

    return <div className='required-login-wrapper'>
        <h3>{translate('sign_up_text')}</h3>
        <div className='button-wrapper'>
            <Link
                className='btn'
                to='/auth'
                onClick={() => authPopUpHandler(0)}
            >
                {translate('log_in')}
            </Link>
            <Link
                className='btn'
                to='/auth'
                onClick={() => authPopUpHandler(1)}
            >
                {translate('sign_up')}
            </Link>
            <Seprator />
            <FacebookLogin
                appId="803594016840989"
                autoLoad={false}
                callback={responseFacebook}
                fields="name,email,picture"
                render={renderProps => (
                    <button
                        className="btn fb-button"
                        onClick={renderProps.onClick}
                    >
                        <div className="sprite-bg fb-icon"/>
                        <span className="btn-text">{translate('connect_with_facebook')}</span>
                    </button>
                )}
            />
        </div>
    </div>;
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthSelected: (authIndex) => dispatch(actions.selectAuth(authIndex)),
        onModalClicked: () => dispatch({type: actionTypes.CLOSE_POPUPS_TWO}),
        onFacebookSuccess: (facebookInfo) => dispatch(actions.facebookCheck(facebookInfo))
    }
};

export default connect(null, mapDispatchToProps)(LogInRequired);