import * as actionTypes from './actionTypes';
import {SET_POP_UP_HOVER_PIN} from "./actionTypes";

export const getZoomLevel = zoomLevel => {
    return {
        type: actionTypes.GET_MAP_ZOOM_LEVEL,
        zoomLevel: zoomLevel
    }
};

export const getPinsOnMap = pins => {
    return {
        type: actionTypes.GET_PINS_ON_MAP,
        pins: pins
    }
};

export const mapIsLoaded = () => {
    return {
        type: actionTypes.MAP_IS_LOADED
    }
};

export const setPulsingDot = (lat, lng, category_id) => {
    return {
        type: actionTypes.SET_PULSING_DOT,
        lat: lat,
        lng: lng,
        category_id: category_id
    }
};

export const removePulsingDot = () => {
    return {
        type: actionTypes.REMOVE_PULSING_DOT
    }
};

export const setSelectedLocation = pin => {
    return {
        type: actionTypes.SET_SELECTED_LOCATION,
        pin: pin
    }
};

export const setPopUpPin = pinFeatures => {
    return {
        type: actionTypes.SET_POP_UP_PIN,
        pinFeatures: pinFeatures
    }
};

export const setPopUpHoverPin = pinFeatures => {
    return {
        type: actionTypes.SET_POP_UP_HOVER_PIN,
        pinFeatures: pinFeatures
    }
};