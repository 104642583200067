import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";

const InitialMtsVideo = props => {
    const {
        setInitialLoading,
        setIsInitialMtsVideoLoaded
    } = props;
    const videoRef = useRef(null);
    useEffect(() => {
        const startInterval = setInterval(() => {
            if(videoRef.current.readyState > 3){
                clearInterval(startInterval);
                setTimeout(() => {
                    setIsInitialMtsVideoLoaded(true);
                    setInitialLoading(true);
                    setTimeout(() => {
                        setInitialLoading(false);
                    }, 2000)
                }, 6000);
            }
        },500);
    }, []);

    return <div className="initial-mts-video">
        <video
            ref={videoRef}
            width={1280}
            height={720}
            autoPlay
            muted
        >
            <source src="https://cdn.meetserbia.rs/videos/mts_video_h.webm" type="video/webm"/>
            <source src="https://cdn.meetserbia.rs/videos/mts_video_h.mp4" type="video/mp4"/>
        </video>
    </div>;
};

const mapDispatchToProps = dispatch => {
    return {
        setInitialLoading: isInitialLoading => dispatch(actions.setInitialLoading(isInitialLoading)),
        setIsInitialMtsVideoLoaded: isInitialMtsVideoLoaded => dispatch(actions.setIsInitialMtsVideoLoaded(isInitialMtsVideoLoaded))
    }
};


export default connect(null, mapDispatchToProps)(InitialMtsVideo);