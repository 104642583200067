import React from "react";

const button = props => {
    const {
        clicked,
        isRouterOpen,
        children
    } = props
    return <button
        className="btn"
        onClick={clicked}
        disabled={isRouterOpen}
    >
        {children}
    </button>
};

export default button;