import React from 'react';
import PopupContainer from '../UI/PopupContainer/PopupContainer';
import ClosePopUp from "../UI/ClosePopUp/ClosePopUp";
import translate from "../../i18n/translate";

const Tutorial = () => {
    return <PopupContainer class='tutorial-wrapper'>
        <ClosePopUp />
        <h2>{translate('tutorial_header')}</h2>
        <p>{translate('tutorial_body_top')}</p>
        <p>{translate('tutorial_body_bottom')}</p>
        <img src={require('../../assets/img/010.gif')} alt='meetserbia'/>
    </PopupContainer>;
};

export default Tutorial;