import React from "react";
import {withRouter} from "react-router-dom";
import * as actionTypes from "../../../store/actions/actionTypes";
import {connect} from "react-redux";

const ClosePopUp = props => {
    const closeHandler = () => {
        props.history.push({
            pathname: '/'
        })
        props.onModalClicked();
    }

    return  <div
        className="sprite-bg close-icon-big"
        onClick={closeHandler}
    />;
}

const mapDipatchToProps = dispatch => {
    return {
        onModalClicked: () => dispatch({type: actionTypes.CLOSE_POPUPS})
    }
}

export default connect(null, mapDipatchToProps)(withRouter(ClosePopUp));