import * as actionsTypes from '../actions/actionTypes';
import produce from "immer";
import * as actionTypes from "../actions/actionTypes";
import {saveToStorage, getFromStorage} from "../../utils/sessionStorage";

const initialState = {
    isLoading: false,
    isModalOpen: false,
    isModalTwoOpen: false,
    isBurgerMenuOpen: false,
    isPinOpen: false,
    isLocationPreview: false,
    isRecommendationsOpen: false,
    isRecommendationsFetched: false,
    recommendations: [],
    isGuidesOpen: false,
    isGuidesFetched: false,
    guides: [],
    tabSelected: 0,
    isRouteConfirm: false,
    routeForDelete: null,
    favoriteForDelete: null,
    isTutorial: true,
    isContactSuccess: false,
    cloudTutorial: {
        1: {
            id: 1,
            count: 0,
            visible: true,
            isTriggered: true
        },
        2: {
            id: 1,
            count: 0,
            visible: false,
            isTriggered: false
        },
        3: {
            id: 1,
            count: 0,
            visible: false,
            isTriggered: false
        }
    },
    fullGallery: {
        open: false,
        pictures: {}
    }
};

const reducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case actionsTypes.LOADER_STOP:
                draft.isLoading = false;
                return;
            case actionsTypes.CLOSE_POPUPS:
                draft.isModalOpen = false;
                draft.isBurgerMenuOpen = false;
                draft.isPinOpen = false;
                draft.isRecommendationsOpen = false;
                draft.isTutorial = false;
                draft.isModalTwoOpen = false;
                draft.isContactSuccess = false;
                saveToStorage('tutorial', false);
                return;
            case actionsTypes.OPEN_BURGER_MENU:
                draft.isModalOpen = true;
                draft.isBurgerMenuOpen = true;
                return;
            case actionsTypes.CLOSE_BURGER_MENU:
                draft.isBurgerMenuOpen = false;
                return;
            case actionsTypes.START_POPUP_PIN:
                draft.isLoading = true;
                return;
            case actionsTypes.SUCCESS_POPUP_PIN:
                draft.isModalOpen = true;
                draft.isPinOpen = true;
                draft.isLoading = false;
                return;
            case actionsTypes.START_RECOMMENDATIONS:
                draft.isLoading = true;
                return;
            case actionsTypes.SUCCES_RECOMMENDATIONS:
                draft.recommendations = action.recommendations;
                draft.isLoading = false;
                draft.isRecommendationsFetched = true;
                return;
            case actionsTypes.OPEN_RECOMMENDATIONS:
                draft.isRecommendationsOpen = true;
                draft.isModalOpen = true;
                return;
            case actionsTypes.START_GUIDES:
                draft.isLoading = true;
                return;
            case actionsTypes.SUCCES_GUIDES:
                draft.guides = action.guides;
                draft.isLoading = false;
                draft.isGuidesFetched = true;
                return;
            case actionsTypes.OPEN_GUIDES:
                draft.isGuidesOpen = true;
                draft.isModalOpen = true;
                return;
            case actionsTypes.OPEN_AUTH_POP_UP:
                draft.tabSelected = action.authIndex;
                draft.isModalOpen = true;
                draft.isBurgerMenuOpen = false;
                return;
            case actionTypes.AUTH_START:
                draft.isLoading = true;
                return;
            case actionTypes.AUTH_SUCCESS:
                draft.isLoading = false;
                draft.isModalOpen = false;
                return;
            case actionTypes.AUTH_FAIL:
                draft.isLoading = false;
                return;
            case actionTypes.OPEN_PROFILE:
                draft.tabSelected = action.authIndex;
                draft.isModalOpen = true;
                return;
            case actionTypes.GET_ROUTES_START:
                draft.isLoading = true;
                return;
            case actionTypes.GET_ROUTES_SUCCESS:
                draft.isLoading = false;
                return;
            case actionsTypes.UPDATE_ROUTE_INFO_START:
                draft.isLoading = true;
                return;
            case actionsTypes.UPDATE_ROUTE_INFO_SUCCESS:
                draft.isLoading = false;
                return;
            case actionsTypes.DELETE_ROUTE_START:
                draft.isLoading = true;
                return;
            case actionsTypes.DELETE_ROUTE_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.GET_ROUTE_START:
                draft.isLoading = true;
                return;
            case actionTypes.GET_ROUTE_SUCCESS:
                draft.isLoading = false;
                draft.isModalOpen = false;
                return;
            case actionsTypes.CLOSE_POPUPS_TWO:
                draft.isModalTwoOpen = false;
                draft.isRouteConfirm = false;
                draft.isFavoriteConfirm = false;
                saveToStorage('tutorial', false);
                draft.isTutorial = false;
                return;
            case actionsTypes.OPEN_CONFIRM_POP_UP:
                draft.isModalTwoOpen = true;
                draft.isRouteConfirm = true;
                draft.routeForDelete = action.routeForDelete;
                return;
            case actionTypes.OPEN_LOG_IN_POP_UP:
                draft.isModalTwoOpen = true;
                return;
            case actionTypes.ADD_TO_FAVORITE_START:
                draft.isLoading = true;
                return;
            case actionTypes.ADD_TO_FAVORITE_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.FETCH_FAVORITE_START:
                draft.isLoading = true;
                return;
            case actionTypes.FETCH_FAVORITE_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.DELTE_FAVORITE_START:
                draft.isLoading = true;
                return;
            case actionTypes.DELETE_FAVORITE_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.OPEN_CONFIRM_FAVORITE_POP_UP:
                draft.isModalTwoOpen = true;
                draft.isFavoriteConfirm = true;
                draft.favoriteForDelete = action.favoriteForDelete;
                return;
            case actionTypes.CHANGE_USERNAME_START:
                draft.isLoading = true;
                return;
            case actionTypes.CHANGE_USERNAME_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.CHANGE_PASSWORD_START:
                draft.isLoading = true;
                return;
            case actionTypes.CHANGE_PASSWORD_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.CHANGE_AVATAR_START:
                draft.isLoading = true;
                return;
            case actionTypes.CHANGE_AVATAR_SUCCESS:
                draft.isLoading = false;
                return;
            case actionsTypes.FETCH_CATEGORIES_START:
                const tutorial = getFromStorage('tutorial');
                if(tutorial === false) {
                    draft.isTutorial = false;
                } else {
                    draft.isModalTwoOpen = true;
                }
                return;
            case actionTypes.OPEN_TUTORIAL:
                draft.isTutorial = true;
                draft.isModalOpen = true;
                draft.isBurgerMenuOpen = false;
                return;
            case actionTypes.FACEBOOK_CHECK_START:
                draft.isLoading = true;
                return;
            case actionTypes.FACEBOOK_CHECK_SUCCESS:
                draft.isLoading = false;
                draft.isModalOpen = false;
                return;
            case actionTypes.AUTH_LOGOUT:
                draft.isModalOpen = false;
                draft.isBurgerMenuOpen = false;
                return;
            case actionsTypes.OPEN_ABOUT_US:
                draft.isModalOpen = true;
                return;
            case actionsTypes.CONTACT_US_OPEN:
                draft.isModalOpen = true;
                return;
            case actionsTypes.CONTACT_US_START:
                draft.isLoading = true;
                return;
            case actionsTypes.CONTACT_US_SUCCESS:
                draft.isContactSuccess = true;
                draft.isLoading = false;
                return;
            case actionsTypes.CONTACT_US_FAIL:
                draft.isLoading = false;
                return;
            case actionTypes.SET_ACTIVE_HEADER:
                draft.isLoading = false;
                return;
            case actionTypes.RESET_EMAIL_START:
                draft.isLoading = true;
                return;
            case actionTypes.RESET_EMAIL_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.RESET_PASSWORD_START:
                draft.isLoading = true;
                return;
            case actionTypes.RESET_PASSWORD_SUCCESS:
                draft.isLoading = false;
                draft.isModalOpen = true;
                return;
            case actionTypes.REGISTER_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.RESEND_EMAIL_START:
                draft.isLoading = true;
                return;
            case actionTypes.RESEND_EMAIL_SUCCESS:
                draft.isLoading = false;
                return;
            case actionTypes.RESEND_EMAIL_FAIL:
                draft.isLoading = false;
                return;
            case actionTypes.VERIFY_EMAIL_START:
                draft.isLoading = true;
                draft.isModalOpen = true;
                return;
            case actionTypes.VERIFY_EMAIL_SUCCESS:
                draft.isLoading = false;
                draft.isModalOpen = false;
                return;
            case actionTypes.VERIFY_EMAIL_FAIL:
                draft.isLoading = false;
                return;
            case actionTypes.SET_CLOUD_TUTORIAL:
                draft.cloudTutorial = action.cloudTutorial;
                return;
            case actionTypes.IS_FULL_GALLERY_OPEN:
                draft.fullGallery.open = action.isOpen;
                return;
            case actionTypes.SET_FULL_GALLERY_PICTURES:
                draft.fullGallery.pictures = action.fullGalleryPictures;
                return;
            default:
                return;
        }
    });
};

export default reducer;