import React from 'react';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {connect} from "react-redux";
import LocationPrewievHeader from "./LocationPrewievHeader";
import RouteTrip from "../../RouteTrip/RouteTrip";
import LogInRequired from "../../Auth/LogInRequired/LogInRequired";
import PinsList from "./PinsList/PinsList";
import * as actionTypes from "../../../store/actions/actionTypes";

const LocationPreview = props => {
    const {
        token,
        lastActiveCategory,
        isLocationPreview,
        loaderEnd
    } = props;
    let popUpContent;
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const LocationHeight = vh-208;
    if(lastActiveCategory === 99) {
        loaderEnd();
        popUpContent = <RouteTrip height={LocationHeight}/>;
    } else if(lastActiveCategory === 16) {
        if(token !== null) {
            popUpContent = <SimpleBarReact
                className="pin-filter-wrapper"
                style={{height: LocationHeight, position: 'absolute'}}
            >
                <PinsList/>
            </SimpleBarReact>
        } else {
            popUpContent = <SimpleBarReact
                className="pin-filter-wrapper"
                style={{
                    height: LocationHeight,
                    position: 'absolute'
                }}
            >
                <LogInRequired/>
            </SimpleBarReact>
        }
    } else {
        popUpContent = <SimpleBarReact
            className="pin-filter-wrapper"
            style={{height: LocationHeight, position: 'absolute'}}
        >
            <PinsList/>
        </SimpleBarReact>
    }
    const locationPreview = <div className='locaion-wrapper'>
        <LocationPrewievHeader/>
        {popUpContent}
    </div>;

    return isLocationPreview && locationPreview;
};

const mapsStateToProps = state => {
    return {
        isLocationPreview: state.filter.isLocationPreview,
        lastActiveCategory: state.filter.lastActiveCategory,
        token: state.auth.token,
        pinsOnMap: state.filter.pinsOnMap,
        listPinsLocation: state.filter.listPinsLocation
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loaderStart: () => dispatch({type: actionTypes.START_POPUP_PIN}),
        loaderEnd: () => dispatch({type: actionTypes.LOADER_STOP})
    }
};

export default connect(mapsStateToProps, mapDispatchToProps)(LocationPreview);