import * as actionsTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    zoomLevel: 30,
    pulsingDot: [],
    searchedLocation: null,
    popUpPin: null,
    // popUpPinCoordinate: null,
    popUpHoverPin: null
    // popUpHoverPinCoordinate: null,
}

const reducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case actionsTypes.GET_MAP_ZOOM_LEVEL:
                draft.zoomLevel = action.zoomLevel;
                return;
            case actionsTypes.SET_PULSING_DOT:
                draft.pulsingDot = [action.lat, action.lng, action.category_id];
                return;
            case actionsTypes.REMOVE_PULSING_DOT:
                draft.pulsingDot = [];
                return;
            case actionsTypes.SET_SELECTED_LOCATION:
                draft.searchedLocation = action.pin;
                return;
            case actionsTypes.SET_POP_UP_PIN:
                draft.popUpPin = action.pinFeatures;
                return;
            case actionsTypes.SET_POP_UP_HOVER_PIN:
                draft.popUpHoverPin = action.pinFeatures;
                return;
            default:
                return;
        }
    })
}

export default reducer;