import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {AppContextProvider} from './context';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import reducerReducer from "./store/reducers/reducer";
import mapReducer from './store/reducers/map';
import filterReducer from './store/reducers/filter';
import routerReducer from './store/reducers/router';
import authReducer from './store/reducers/auth';
import {BrowserRouter} from "react-router-dom";
import thunk from 'redux-thunk';
import HelmetMetaData from "./components/UI/HelmetMetaData/HelmetMetaData";
import {BrowserView, MobileView} from "react-device-detect";
import Aux from './hoc/Auxiliary';
import {isMobile} from 'react-device-detect';

if (isMobile) import('../src/assets/scss/mobile.scss').then();
document.cookie = 'same-site-cookie=https://meetserbia.rs/; SameSite=Lax';
document.cookie = 'cross-site-cookie=https://meetserbia.rs/; SameSite=None; Secure';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    reducer: reducerReducer,
    map: mapReducer,
    filter: filterReducer,
    router: routerReducer,
    auth: authReducer
});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Aux>
        <BrowserView>
            <Provider store={store}>
                <HelmetMetaData title={'mts Meet Serbia'} meta={'meta'} link={'https://test.meetserbia.rs/'}/>
                <AppContextProvider>
                    <BrowserRouter>
                        <App device={'desktop'}/>
                    </BrowserRouter>
                </AppContextProvider>
            </Provider>
        </BrowserView>
        <MobileView>
            <Provider store={store}>
                <HelmetMetaData title={'Meet Serbia'} meta={'meta'} link={'https://test.meetserbia.rs/'}/>
                <AppContextProvider>
                    <BrowserRouter>
                        <App device={'mobile'} />
                    </BrowserRouter>
                </AppContextProvider>
            </Provider>
        </MobileView>
    </Aux>,
  document.getElementById('root')
);