import React, {useContext, useEffect} from 'react';
import TagsFilter from './TagsFilter/TagsFilter';
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import ReactTooltip from "react-tooltip";
import * as actionTypes from "../../../store/actions/actionTypes";
import {AppContext} from "../../../context/index";
import messages from "../../../i18n/messages";

const CategoryFilter = props => {
    const {
        categoriesListTop,
        categoriesListBottom,
        tags,
        activeCategories,
        initialPins,
        favorites,
        loaderStart,
        lastActiveCategory,
        pinsOnMap,
        loaderEnd,
        categoryActive,
        onCategoryAdd,
        onCategoryDeleted,
        id,
        name,
        tagsList,
        isAllTags,
        setAlertMessage
    } = props;
    const {state} = useContext(AppContext);
    const setCategoryNavHandler = (categoryId, categoryName) => {
        loaderStart();
        if(!categoryActive) {
            if (activeCategories.length === 10) {
                setAlertMessage('maximum_number_filter', 'alert_info');
                loaderEnd();
            } else {
                let categoryName2 = categoryName;
                if (categoryId === 16) {
                    categoryName2 = messages[state.siteLang]['favorites'];
                    loaderEnd();
                }
                onCategoryAdd(categoryId, categoryName2, categoriesListTop, categoriesListBottom, tags, activeCategories, initialPins, favorites, pinsOnMap);
            }
        } else {
            onCategoryDeleted(categoryId, categoriesListTop, categoriesListBottom, activeCategories, lastActiveCategory, initialPins);
        }
    }
    useEffect(() => {
        if (activeCategories.length === 0 || lastActiveCategory === 16) {
            loaderEnd();
        }
    }, [activeCategories, loaderEnd]);
    let dropDown = null;
    let tooltip = null;
    if(!categoryActive) {
        tooltip = <ReactTooltip
            id={`test-${id}`}
            className={`cat-${id}`}
            place="bottom"
            type="light"
            effect="float"
        >
            {name}
        </ReactTooltip>
    }
    if(categoryActive) {
        dropDown = <TagsFilter
            id={id}
            tagsList={tagsList}
            categoryActive={categoryActive}
            isAllTags={isAllTags}
        />;
    }

    return <>
        <li
            data-tip
            data-for={`test-${id}`}
            className={`category-list cat-${id}-wrapper ${categoryActive ? "active" : ""}`}
            onClick={() => setCategoryNavHandler(id, name)}
        >
            <div className={`sprite-bg cat-${id}-icon-big`}/>
            {dropDown}
        </li>
        {tooltip}
    </>;
};

const mapStateToProps = state => {
    return {
        categoriesListTop: state.filter.categoriesListTop,
        categoriesListBottom: state.filter.categoriesListBottom,
        tags: state.filter.tags,
        activeCategories: state.filter.activeCategories,
        initialPins: state.filter.initialPins,
        favorites: state.filter.favorites,
        lastActiveCategory: state.filter.lastActiveCategory,
        pinsOnMap: state.filter.pinsOnMap
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCategoryAdd: (
            categoryId,
            categoryName,
            categoriesListTop,
            categoriesListBottom,
            tags,
            activeCategories,
            initialPins,
            favorites
        ) => dispatch(actions.addCategory(
            categoryId,
            categoryName,
            categoriesListTop,
            categoriesListBottom,
            tags,
            activeCategories,
            initialPins,
            favorites
        )),
        onCategoryDeleted: (
            categoryId,
            categoriesListTop,
            categoriesListBottom,
            activeCategories,
            lastActiveCategory,
            initialPins
        ) => dispatch(actions.deleteCategory(
            categoryId,
            categoriesListTop,
            categoriesListBottom,
            activeCategories,
            lastActiveCategory,
            initialPins
        )),
        loaderStart: () => dispatch({type: actionTypes.START_POPUP_PIN}),
        loaderEnd: () => dispatch({type: actionTypes.LOADER_STOP}),
        setAlertMessage: (alertMessage, alertType) => dispatch(actions.setAlertMessage(alertMessage, alertType))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilter);