import React, {useContext, Suspense, useEffect} from 'react';
import Layout from './components/Layout/Layout';
import {I18nProvider} from './i18n';
import {AppContext} from './context';
import {Route, Switch, Redirect} from 'react-router-dom';
import * as actions from "./store/actions";
import {connect} from "react-redux";
import {getFromStorage} from "./utils/sessionStorage";
const Pin = React.lazy(() => import(("./components/Pin/Pin")));
const Recommendations = React.lazy(() => import(("./containers/Recommendations/Recommendations")));
const Auth = React.lazy(() => import("./components/Auth/Auth"));
const Profile = React.lazy(() => import("./components/Profile/Profile"));
const EditRoute = React.lazy(() => import("./components/Profile/SavingRoutes/EditRoute/EditRoute"));
const SingleRoute = React.lazy(() => import("./containers/Map/Map/SingleRoute"));
const AboutUs = React.lazy(() => import("./components/AboutUs/AboutUs"));
const ContactUs = React.lazy(() => import("./containers/ContactUs/ContactUs"));
const ForgotPassword = React.lazy(() => import("./components/Auth/ForgotPassword/ForgotPassword"));
const NewPassword = React.lazy(() => import("./components/Auth/NewPassword/NewPassword"));
const Verification = React.lazy(() => import("./components/Auth/Verification/Verification"));
const DownloadApp = React.lazy(() => import("../src/containers/DownloadApp/DownloadApp"));
const Impresum = React.lazy(() => import("../src/components/Impresum/Impresum"));
const TermsSerbianCyrillic = React.lazy(() => import("./components/Terms/TermsSerbianCyrillic"));
const TermsSerbianLatin = React.lazy(() => import("./components/Terms/TermsSerbianLatin"));
const TermsEnglish = React.lazy(() => import("../src/components/Terms/TermsEnglish"));
const PrivacyPolicySerbianCyrillic = React.lazy(() => import("./components/PrivacyPolicy/PrivacyPolicySerbianCyrillic"));
const PrivacyPolicySerbianLatin = React.lazy(() => import("./components/PrivacyPolicy/PrivacyPolicySerbianLatin"));
const PrivacyPolicyEnglish = React.lazy(() => import("../src/components/PrivacyPolicy/PrivacyPolicyEnglish"));

const App = props => {
    const {state} = useContext(AppContext);
    const {onTryAutoSignUp, device, isAuthenticated, fetchFavorite, token, user, forAddAfterLogin} = props;
    let routes;
    useEffect(() => {
        onTryAutoSignUp();
    }, [onTryAutoSignUp]);
    const languageId = getFromStorage('siteLangId');
    let termsElement, privacyElement;
    switch (languageId) {
        case 1:
            termsElement = <TermsSerbianLatin/>;
            privacyElement = <PrivacyPolicySerbianLatin/>;
            break;
        case 2:
            termsElement = <TermsSerbianCyrillic/>;
            privacyElement = <PrivacyPolicySerbianCyrillic/>;
            break;
        case 3:
            termsElement = <TermsEnglish/>;
            privacyElement = <PrivacyPolicyEnglish/>;
            break;
        default:
            break;
    }
    if (device === 'desktop') {
        routes = <Layout>
            <Suspense fallback={<p>...loading</p>}>
                <Switch>
                    <Route path='/pin/:id' component={() => (<Pin/>)}/>
                    <Route path='/recommendations' render={() => (<Recommendations/>)}/>
                    <Route path='/route/:id' component={() => (<SingleRoute/>)}/>
                    <Route path='/auth/forgot-password/:id' render={() => (<ForgotPassword/>)}/>
                    <Route path='/auth/forgot-password' render={() => (<ForgotPassword/>)}/>
                    <Route path='/auth' exact render={() => (<Auth/>)}/>
                    <Route path='/verification/:token' exact render={() => (<Verification/>)}/>
                    <Route path='/reset-password/:token/:email' render={() => (<NewPassword/>)}/>
                    <Route path='/about-us' render={() => (<AboutUs/>)}/>
                    <Route path='/contact-us' render={() => (<ContactUs/>)}/>
                    <Route path='/impresum' render={() => (<Impresum/>)}/>
                    <Route path='/privacy-policy' render={() => (privacyElement)}/>
                    <Route path='/terms' render={() => (termsElement)}/>
                    <Redirect to="/" />
                </Switch>
            </Suspense>
        </Layout>;
        if (isAuthenticated) {
            fetchFavorite(token, user.userId, forAddAfterLogin.tmpPinFavoritesId);
            routes = <Layout>
                <Suspense fallback={<p>...loading</p>}>
                    <Switch>
                        <Route path='/pin/:id' render={() => (<Pin/>)}/>
                        <Route path='/recommendations' render={() => (<Recommendations/>)}/>
                        <Route path="/profile/route/:id" render={() => (<EditRoute/>)}/>
                        <Route path="/profile" render={() => (<Profile/>)}/>
                        <Route path='/route/:id' component={() => (<SingleRoute/>)}/>
                        <Route path='/auth/forgot-password/:id' render={() => (<ForgotPassword/>)}/>
                        <Route path='/auth/forgot-password' render={() => (<ForgotPassword/>)}/>
                        <Route path='/reset-password/:token/:email' render={() => (<NewPassword/>)}/>
                        <Route path='/about-us' render={() => (<AboutUs/>)}/>
                        <Route path='/contact-us' render={() => (<ContactUs/>)}/>
                        <Route path='/impresum' render={() => (<Impresum/>)}/>
                        <Route path='/privacy-policy' render={() => (privacyElement)}/>
                        <Route path='/terms' render={() => (termsElement)}/>
                        <Redirect to="/"/>
                    </Switch>
                </Suspense>
            </Layout>;
        }
    } else {
        routes = <Suspense fallback={<p>...loading</p>}>
            <Switch>
                <Route path='a:id' render={() => (<ForgotPassword/>)}/>
                <Route path='/auth/forgot-password/:id' render={() => (<ForgotPassword/>)}/>
                <Route path='/auth/forgot-password' render={() => (<ForgotPassword/>)}/>
                <Route path='/reset-password/:token/:email' render={() => (<NewPassword/>)}/>
                <Route path='/privacy-policy' render={() => (privacyElement)}/>
                <Route path='/terms' render={() => (termsElement)}/>
                <Route path='/' strict render={() => (<DownloadApp/>)}/>
            </Switch>
        </Suspense>;
    }

    return <I18nProvider locale={state.siteLang}>
        {routes}
    </I18nProvider>;
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
        user: state.auth.user,
        forAddAfterLogin: state.auth.forAddAfterLogin
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignUp: () => dispatch(actions.authCheckState()),
        fetchFavorite: (token, userId, tmpPinFavoritesId) => dispatch(actions.fetchFavorite(token, userId, tmpPinFavoritesId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);