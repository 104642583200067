import React from "react";

const InitialSpinner = () => {
    return (
        <div className="spinners__box">
            <div className="dot-loader"/>
            <div className="dot-loader"/>
            <div className="dot-loader"/>
        </div>
    );
}

export default InitialSpinner;