import React from "react";
import {connect} from 'react-redux';
import AuthDropDown from "./AuthDropDown/AutDropDown";
import SettingsDropDown from "./SettingsDropDown/SettingsDropDown";

const AccountNavigation = props => {
    const {token} = props;
    return <div className="account-wrapper">
        <div className="sprite-bg account-icon"/>
        <div className="header-dd-wrapper">
            <div className="triangle-wrapper">
                <div className="triangle-with-shadow"/>
            </div>
            {token === null ? <AuthDropDown/> : <SettingsDropDown/>}
        </div>
    </div>
};

const mapStateToProps = state => {
    return {
        token: state.auth.token
    }
};

export default connect(mapStateToProps)(AccountNavigation);