import React from 'react';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import Slider from "rc-slider/es";
import '../../../../node_modules/rc-slider/assets/index.css'
import translate from "../../../i18n/translate";

const RadiusSlider = props => {
    const {
        routeRadius,
        onRadiusChange,
        route,
        routeModes,
        changeRouteMode,
        allPossibleRoutes,
        chosenRouteIndex,
        setChosenRouteIndex,
        routeInfo,
        activeNavigationMode,
        avoidTolls,
        setAvoidTolls
    } = props;
    const routeModesElement = [];
    let routeInfoElement, toolsCheckboxElement;
    const onRadiusChangeHandler = value => {
        onRadiusChange(value);
    };
    const changeRoteModeHandler = routeModeId => {
        changeRouteMode(routeModeId);
    };
    Object.values(routeModes).map(routeMode => {
        let modeClassName = 'route-mode';
        if (routeMode.active) {
            modeClassName = 'route-mode active';
        }
        let name = '';
        switch (routeMode.id) {
            case 1:
                name = translate('driving');
                break;
            case 2:
                name = translate('walking');
                break;
            case 3:
                name = translate('cycling');
                break;
            default:
                break;
        }
        routeModesElement.push(<div
            className={modeClassName}
            key={routeMode.id}
            onClick={() => changeRoteModeHandler(routeMode.id)}
        >
            <span>{name}</span>
        </div>);
        return routeMode;
    });
    function convertHMS(value) {
        const sec = parseInt(value, 10);
        let hours   = Math.floor(sec / 3600);
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        if (hours   < 10) {hours = +hours;}
        if (minutes < 10) {minutes = +minutes;}
        return `${hours} h ${minutes} m`;
    }
    if (routeInfo === null) {
        routeInfoElement = <div className='all-routes-wrapper'>
            <ul>
                {allPossibleRoutes.map((route, index) => {
                    let routeClassName = 'route-info-wrapper';
                    if (+index === +chosenRouteIndex) {
                        routeClassName = 'route-info-wrapper active';
                    }
                    const distance = route.distance;
                    return <li
                        key={index}
                        className={routeClassName}
                        onClick={() => setChosenRouteIndex(index)}
                    >
                        <div>
                            <span>{translate('route')} {`${index+1}`}</span>
                            <span>{convertHMS(route.duration)}</span>
                        </div>
                        <div>
                            <span>{translate('route_length')}</span>
                            <span>{`${(distance / 1000).toFixed(2)} KM`}</span>
                        </div>
                    </li>;
                })}
            </ul>
        </div>
    } else {
        if (allPossibleRoutes.length > 0) {
            routeInfoElement = <div className='all-routes-wrapper'>
                <ul>
                    <li
                        key={routeInfo[0].name}
                        className='route-info-wrapper'
                    >
                        <div>
                            <span>{routeInfo[0].name}</span>
                            <span>{convertHMS(allPossibleRoutes[chosenRouteIndex].duration)}</span>
                        </div>
                        <div>
                            <span>{translate('route_length')}</span>
                            <span>{`${(allPossibleRoutes[chosenRouteIndex].distance / 1000).toFixed(2)} KM`}</span>
                        </div>
                    </li>
                </ul>
            </div>;
        }
    }
    if ((activeNavigationMode === 'driving') && (route.length > 1)) {
        toolsCheckboxElement = <label className="checkbox small">
            <input
                id="checkbox_1"
                type="checkbox"
                checked={avoidTolls}
                onChange={() => setAvoidTolls(!avoidTolls)}
            />
            <span className="check"/>
            <span className="label">{translate('avoid_tolls')}</span>
        </label>;
    }

    return <div className='router-radius-wrapper'>
        {routeInfoElement}
        {toolsCheckboxElement}
        <div className='route-mode-wrapper'>
            {routeModesElement}
        </div>
        <div className='router-radius-info-wrapper'>
            <h6 className='radius-info-header'>{translate('route_radius')}</h6>
            <span className='radius-info-text'>{routeRadius === 35 ? translate('infinitely') : `${routeRadius} KM` }</span>
        </div>
        <Slider min={5} max={35} step={5} defaultValue={routeRadius} onAfterChange={onRadiusChangeHandler} />
    </div>;
};

const mapStateToProps = state => {
    return {
        routeRadius: state.filter.routeRadius,
        route: state.router.route,
        routeModes: state.filter.routeModes,
        allPossibleRoutes : state.filter.allPossibleRoutes,
        chosenRouteIndex: state.filter.chosenRouteIndex,
        routeInfo: state.router.routeInfo,
        activeNavigationMode: state.filter.activeNavigationMode,
        avoidTolls: state.filter.avoidTolls
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRadiusChange: value  => dispatch(actions.setRouterRadius(value)),
        changeRouteMode: value  => dispatch(actions.changeRouteMode(value)),
        setChosenRouteIndex: routeIndex => dispatch(actions.setChosenRouteIndex(routeIndex)),
        setAvoidTolls: isAvoidTolls => dispatch(actions.setAvoidTolls(isAvoidTolls))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RadiusSlider);