import React, {useEffect, useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import PinList from "./PinList/PinList";
import * as actionTypes from "../../../../store/actions/actionTypes";
import translate from "../../../../i18n/translate";

const PinsList = props => {
    const {
        pinsOnMap,
        listPinsLocation,
        onPinHover,
        loaderEnd
    } = props;
    let pinsElements;
    const [isEmpty, setIsEmpty] = useState(true);
    const onMouseHoverItemHandler = (lat, lng, category_id) => {
        onPinHover(lat, lng, category_id)
    };
    useEffect(() => {
        let isExist = false;
        pinsOnMap.map(finalCategory => {
            listPinsLocation.map(pin => {
                if(finalCategory.properties.pin_id === pin) {
                    isExist = true;
                }
                return pin;
            })
            return finalCategory;
        });
        if(isExist === true) {
            setIsEmpty(false);
        } else {
            setIsEmpty(true);
        }
    }, [pinsOnMap, listPinsLocation, setIsEmpty]);
    useEffect(() => {
        loaderEnd();
    }, [loaderEnd, pinsOnMap]);
    if(isEmpty) {
        pinsElements = <div className='empty-filter'>
            <img src={require('../../../../assets/img/011.png')} alt='meetserbia'/>
            <h3>{translate('zoom_explore')}</h3>
        </div>;
    } else {
        const filteredPins = [];
        const uniqPinsOnMap = [...new Set(pinsOnMap)];
        const uniqListPinsLocation = [...new Set(listPinsLocation)];
        uniqPinsOnMap.map(finalCategory => {
            uniqListPinsLocation.map(pin => {
                if(finalCategory.properties.pin_id === pin) {
                    filteredPins.push(finalCategory);
                }
                return pin;
            });
            return finalCategory;
        });
        filteredPins.sort(function(a, b) {
            return a.properties.zoom - b.properties.zoom;
        });
        pinsElements = filteredPins.map(pin => {
            return <PinList
                key={pin.properties.pin_id}
                onMouseHoverItemHandler={onMouseHoverItemHandler}
                pin={pin}
            />;
        });
    }

    return pinsElements;
};

const mapStateToProps = state => {
    return {
        pinsOnMap: state.filter.pinsOnMap,
        listPinsLocation: state.filter.listPinsLocation
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPinHover: (lat, lng, category_id) => dispatch(actions.setPulsingDot(lat, lng, category_id)),
        loaderEnd: () => dispatch({type: actionTypes.LOADER_STOP})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PinsList);