import React from 'react';
import {connect} from "react-redux";
import * as actions from "../../../../../store/actions";
import * as actionTypes from "../../../../../store/actions/actionTypes";

const TagsFilter = props => {
    const {
        tag,
        onTagAdded,
        onTagDeleted,
        loaderStart,
        tags,
        activeCategories,
        categoriesListTop,
        categoriesListBottom,
        initialPins,
        favorites
    } = props;
    const tagFilterHandler = (tagId, categoryId) => {
        loaderStart();
        if(!props.tag.active){
            onTagAdded(tagId, categoryId, tags, activeCategories, categoriesListTop, categoriesListBottom, initialPins, favorites);
        } else {
            onTagDeleted(tagId, categoryId, activeCategories, tags, initialPins, favorites, categoriesListTop, categoriesListBottom);
        }
    }

    return <li
        className={`cat-dd-${tag.category_id}-wrapper ${tag.active ? "active" : ""}`}
        onClick={() => tagFilterHandler(tag.tag_id, tag.category_id)}
    >
        {tag.name}
    </li>;
}

const mapsStateToProps = state => {
    return {
        tags: state.filter.tags,
        activeCategories: state.filter.activeCategories,
        categoriesListTop: state.filter.categoriesListTop,
        categoriesListBottom: state.filter.categoriesListBottom,
        initialPins: state.filter.initialPins,
        favorites: state.filter.favorites
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTagAdded: (
            tagId,
            categoryId,
            tags,
            activeCategories,
            categoriesListTop,
            categoriesListBottom,
            initialPins,
            favorites
        )  => dispatch(actions.addTag(
            tagId,
            categoryId,
            tags,
            activeCategories,
            categoriesListTop,
            categoriesListBottom,
            initialPins,
            favorites
        )),
        onTagDeleted: (
            tagId,
            categoryId,
            activeCategories,
            tags,
            initialPins,
            favorites,
            categoriesListTop,
            categoriesListBottom
        )  => dispatch(actions.deleteTag(
            tagId,
            categoryId,
            activeCategories,
            tags,
            initialPins,
            favorites,
            categoriesListTop,
            categoriesListBottom
        )),
        loaderStart: () => dispatch({type: actionTypes.START_POPUP_PIN})
    }
}

export default connect(mapsStateToProps, mapDispatchToProps)(TagsFilter);