import React from "react";
import {connect} from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";

const BackdropTwo = props => {
    const closeModalHandler = () => {
        props.onModalClicked();
    }

    return <div className="backdrop backdrop-two" onClick={closeModalHandler}></div>
};

const mapDipatchToProps = dispatch => {
    return {
        onModalClicked: () => dispatch({type: actionTypes.CLOSE_POPUPS_TWO})
    }
}

export default connect(null, mapDipatchToProps)(BackdropTwo);