import React from "react";
import {connect} from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import {withRouter} from 'react-router-dom';

const Backdrop = props => {
    const closeModalHandler = () => {
        props.history.push({
            pathname: '/'
        })
        props.onModalClicked();
    }

    return <div className="backdrop" onClick={closeModalHandler}/>
};

const mapDipatchToProps = dispatch => {
    return {
        onModalClicked: () => dispatch({type: actionTypes.CLOSE_POPUPS})
    }
};

export default connect(null, mapDipatchToProps)(withRouter(Backdrop));