import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const FullGallery = props => {
    const {
        isFullGalleryOpen,
        pictures
    } = props;
    const closeFullGalleryHandler = () => {
        isFullGalleryOpen(false);
    };
    let picturesElement;
    if (Object.values(pictures).length > 0) {
        picturesElement = Object.values(pictures).map(picture => {
            return <div
                key={picture.id}
                data-src={`${process.env.REACT_APP_CDN_URL}/images/full_galleries/${picture.img_url}`}
            />;
        });
    }

    return <>
        <div
            className="sprite-bg close-icon-big gallery-close"
            onClick={closeFullGalleryHandler}
        />
        <AutoplaySlider
            organicArrows={true}
            bullets={false}
            play
            cancelOnInteraction={false}
            interval={5000}
            transitionDelay={100}
            className='full-gallery-wrapper'
        >
            {picturesElement}
        </AutoplaySlider>
        </>;
};

const mapStateToProps = state => {
    return {
        pictures: state.reducer.fullGallery.pictures
    }
};

const mapDispatchToProps = dispatch => {
    return {
        isFullGalleryOpen: (isOpen) => dispatch(actions.isFullGalleryOpen(isOpen))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FullGallery);