import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import translate from "../../../i18n/translate";

const Alert = props => {
    const {
        alertInfo,
        deleteAlertMassage
    } = props;
    let alertInfoMessage;
    const [isFadingOut, setIsFadingOut] = useState(false);
    const fadeOut = (cb) => {
        setIsFadingOut(true);
        cb();
    };
    const handleRemoveItem = () => {
        setIsFadingOut(false);
        deleteAlertMassage();
    };
    const cb = () => {
        setTimeout(() => handleRemoveItem(),1500);
    };
    useEffect(() => {
        setTimeout(() => {
            fadeOut(cb);
        }, 5000);
    }, [alertInfo]);
    alertInfoMessage =  translate(alertInfo.message);

    return <div
        className={`alert_wrapper ${alertInfo.type} ${isFadingOut ? 'fadeout' : ''}`}
    >
        <div className='sprite-bg exclamation-circle'/>
        <span
            className="alert_text"
        >{alertInfoMessage}</span>
        <div
            className='sprite-bg alert-close'
            onClick={()=>fadeOut(cb)}
        />
    </div>;
}

const mapStateToProps = state => {
    return {
        alertInfo: state.auth.alertInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteAlertMassage: () => dispatch(actions.deleteAlertMassage())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);