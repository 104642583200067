import React from "react";
import {Link} from "react-router-dom";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";

const PinList = props => {
    const {
        onLocationClicked,
        onMouseHoverItemHandler,
        onPinLeave,
        pin
    } = props;
    const onMouseLeave = () => {
        onPinLeave();
    };
    let link = '/';
    if (pin.properties.is_popup === 1) {
        link = `/pin/${pin.properties.pin_id}`
    }
    let img = <img src={require('../../../../../assets/img/002.png')} alt='meetserbia'/>;
    if (pin.properties.img_url !== 'null') {
        img = <img src={`https://cdn.meetserbia.rs/images/pins/normal/${pin.properties.img_url}`} alt={pin.properties.title}/>;
    }

    return <Link
        to={link}
        className="pin-filter"
        onClick={onLocationClicked}
        onMouseEnter={() => onMouseHoverItemHandler(pin.geometry.coordinates[0], pin.geometry.coordinates[1], pin.properties.category_id)}
        onMouseLeave={onMouseLeave}
    >
        {img}
        <h5 className="pin-header">{pin.properties.title}</h5>
    </Link>;
};

const mapDispatchToProps = dispatch => {
    return {
        onPinLeave: () => dispatch(actions.removePulsingDot())
    }
};

export default connect(null, mapDispatchToProps)(PinList);